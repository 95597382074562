import ShopHeader from "../Shop/ShopModules/ShopHeader/ShopHeader";
import MyOrdersBody from "./MyOrdersModules/MyOrdersBody";
import Footer from "../HomePage/Footer/Footer";

function MyOrders() {
  return (
    <div>
      <ShopHeader />
      <MyOrdersBody />
      <Footer />
    </div>
  );
}

export default MyOrders;
