import React from "react";
import { Container } from "@material-ui/core";

function RefundBodyPage() {
  return (
    <Container>
      <h1 className="oswald headingTerms">
        <span className="blue">CANCELLATION REFUNDS</span> AND RETENTION POLICY
      </h1>
      <h2 className="oswald headingTerms">
        <span className="blue">OVERVIEW</span>
      </h2>
      <p className="roboto paraNormal">
        Online booking of our services is complete only when the payments for the services, including applicable tax are
        paid in full.
      </p>
      <p className="roboto paraNormal">
        Every booking through our website can be cancelled any time prior to the date and time of check in through the
        same portal and could not be cancelled through any other mode, even personally. All such cancellation are
        governed by the following conditions
      </p>
      <p className="roboto paraTerms">
        <ul>
          <li>No refund and no transfer for no show customers.</li>
          <li>90% refund for customers cancelling the bookings 48 hours before service schedule.</li>
          <li>50% refund for customers cancelling the bookings 24 hours before service schedule.</li>
          <li>No refund for customers cancelling the bookings within 24 hours before service schedule.</li>
          <li>
            No refund will be approved if the cancellation is done by us on account of you breaching any of our terms
            and conditions or that the booking has been made illegally or unlawfully.
          </li>
          <li>
            If approved, then your refund will be processed, and a credit will automatically be applied to your credit
            card or original method of payment, within a certain amount of days.  
          </li>
        </ul>
      </p>
      <h2 className="oswald headingTerms">
        <span className="blue">LATE OR MISSING</span> REFUNDS (IF APPLICABLE)
      </h2>
      <p className="roboto paraTerms">
        <ul>
          <li>If you haven’t received a refund yet, first check your bank account again.</li>
          <li>Then contact your credit card company, it may take some time before your refund is officially posted.</li>
          <li>Next contact your bank. There is often some processing time before a refund is posted.</li>
          <li>
            If you’ve done all of this and you still have not received your refund yet, please call 9962562562
            (available on Monday to Saturday between 10:00 AM to 05:00 PM) or send a mail to: hibroservices@gmail.com at
            anytime.
          </li>
        </ul>
      </p>
    </Container>
  );
}

export default RefundBodyPage;
