import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Divider, Chip } from "@material-ui/core";
import axios from "axios";
import { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}

const rows = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9),
];

export default function AvailabilityDialog(props) {
  const classes = useStyles();

  console.log(props.district);

  const [available, setAvailable] = useState([]);
  const [availableCategoryList, setAvailableCategoryList] = useState([]);
  const [availabilityList, setAvailabilityList] = useState([]);

  useEffect(() => {
    axios.get(`https://admin.hibroservices.com/api/masters/getAvailability`).then((res) => {
      console.log(res);
      if (res.status === 200) {
        console.log(res.data.result);
        setAvailable(res.data.result);
        setAvailableCategoryList(res.data.result.servicesList.serviceCategoryId);
        setAvailabilityList(res.data.result.availabilityList.filter((item) => item.district === props.district));
        console.log(res.data.result.servicesList);
      } else if (res.status === 401) {
        console.log("error");
      }
    });
  }, [props.district]);

  return (
    <div>
      {available && (
        <Dialog open={props.openAvailDialog}>
          <DialogTitle id="alert-dialog-title" sx={{ mb: 2 }}>
            <div style={{ textAlign: "center" }}>Service Centers Availability</div>
          </DialogTitle>
          <Divider style={{ marginBottom: "30px" }} />
          <DialogContent sx={{ pb: 0, width: "1200px" }}>
            <TableContainer component={Paper}>
              <Table
                className={classes.table}
                size="small"
                // aria-label="a dense table"
                stickyHeader
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell>Constituency</TableCell>
                    {available.servicesList &&
                      available.servicesList.map((avail, index) => (
                        <TableCell key={index} align="center">
                          {avail.serviceCategoryName}
                        </TableCell>
                      ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {availabilityList.map((avail) => {
                    return (
                      <TableRow key={avail.id}>
                        <TableCell component="th" scope="row">
                          {avail.constituencyName}
                        </TableCell>
                        {available.servicesList &&
                          available.servicesList.map((ava) => (
                            <TableCell align="center">
                              <Chip
                                size="small"
                                label={avail.services.includes(ava.serviceCategoryId) ? "Avaialble" : "Not Available"}
                                style={{
                                  backgroundColor: avail.services.includes(ava.serviceCategoryId) ? "#00FF00" : "red",
                                  color: "#000",
                                }}
                              />
                            </TableCell>
                          ))}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={props.onCloseAvailClick}
              variant="contained"
              style={{ backgroundColor: "#0066ff", color: "#fff", margin: "10px 22px 20px" }}
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}
