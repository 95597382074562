import React from "react";
import { Container } from "@material-ui/core";

function PrivacyBodyPage() {
  return (
    <Container>
      <h1 className="oswald headingTerms">
        <span className="blue">PRICING</span> POLICY
      </h1>
      <h2 className="oswald headingTerms">
        <span className="blue">PRICE</span> RANGE:
      </h2>
      <p className="roboto paraTerms">
        At www.hibroservices.com we have customised pricing according to the services rendered by us. The details are
        provided to you beforehand according to the effort, efficiency and the output of the service. Typically the
        range of transactions on our website varies from INR 19.00 to INR 9999.00 depending on the locations and the
        services provided therein.
      </p>
      <h2 className="oswald headingTerms">
        <span className="blue">DISCOUNTS</span>
      </h2>
      <p className="roboto paraTerms">
        Some of our services can be utilised for discounted rates or complimented with warranty, if any, as may be fixed
        by us upon certain conditions and eligibility criteria, as may be published by us every now and then and the
        utilisation of the same shall be subject to compliance of such conditions and eligibility criteria and you
        hereby agree not to claim any discount as a matter of right.
      </p>
      <h2 className="oswald headingTerms">
        <span className="blue">PRICE</span> MATCHING
      </h2>
      <p className="roboto paraNormal">
        At www.hibroservices.com we are committed to offering you the best possible rates. We will be glad to meet our
        competitor's pricing if you ever find an item that we offer, in the same quality and size, available from a
        similar service provider.
      </p>
      <p className="roboto paraTerms">
        We are unable to match prices from street smart service providers and outlet stores or websites, as well as
        other service provider’s discount promotions, special offers and gift card offers.
      </p>
      <h2 className="oswald headingTerms">
        <span className="blue">PRICING</span> ERRORS
      </h2>
      <p className="roboto paraNormal">
        We work hard to ensure the accuracy of charges for our services. Despite our efforts, pricing errors may still
        occur. If charge for any of our services is higher than the price displayed, your valuable feed back will
        improve us consistently.
      </p>
      <p className="roboto paraNormal">
        Our service is offered for sale at www.hibroservices.com for your personal enjoyment and not for proxy or
        resale. Therefore, we reserve the right to refuse to offer to any person, any of our services, whom we believe
        may be purchasing as a proxy or subrogate or for resale.
      </p>
      <p className="roboto paraTerms">
        Our Customer Service Specialists are ready to assist you. Please call 9962562562 (available on Monday to
        Saturday between 10:00 AM to 05:00 PM) or write to hibroservices@gmail.com any time.
      </p>
    </Container>
  );
}

export default PrivacyBodyPage;
