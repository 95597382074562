import React from "react";
import {
  Container,
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  Box,
} from "@material-ui/core";
import aboutUsImageOne from "../../../Images/aboutUsCardOne.png";
import aboutUsImageTwo from "../../../Images/aboutUsCardTwo.png";

function AboutUsContent() {
  return (
    <div>
      <Container>
        <Grid container spacing={10}>
          <Grid item md={5}>
            <Typography
              variant="body1"
              component="h2"
              color="textSecondary"
              gutterBottom
            >
              EXPLORE THE WIDE RANGE OF OPTIONS
            </Typography>
            <Typography variant="h4" component="h2" gutterBottom>
              ABOUT US
            </Typography>
            <Typography
              variant="body1"
              component="h2"
              color="textSecondary"
              gutterBottom
              style={{ lineHeight: 2 }}
            >
              But I must explain to you how all this mistaken idea of denouncing
              pleasure and praising pain was born and hibro will give you a complete
              home services and expound the actual teachings of the
              great. We, The HIBRO-Home Services gladly comes up with abundant
              number of quality and sterile services to make your life
              uncomplicated. Count in us for all essential services that you
              require and ensure your day-to-day life is hassle/mess free.
            </Typography>
          </Grid>
          <Grid item md={7}>
            <Grid container spacing={4}>
              <Grid item md={6}>
                <Card style={{ maxWidth: 315, marginBottom: "50px" }}>
                  <CardMedia>
                    <img src={aboutUsImageOne} alt="pixel" />
                  </CardMedia>
                  <Box mt={2}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        VISION
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ lineHeight: 2 }}
                      >
                        We, The HIBRO-Home Services gladly comes up with
                        abundant number of quality and sterile services to make
                        your life uncomplicated. Count in us for all essential
                        services that you require and ensure your day-to-day
                        life is hassle/mess free.
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
              <Grid item md={6}>
                <Card style={{ maxWidth: 315 , marginBottom: "50px"}}>
                  <CardMedia>
                    <img src={aboutUsImageTwo} alt="pixel" />
                  </CardMedia>
                  <Box mt={2}>
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="h2">
                        MISSION
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{ lineHeight: 2 }}
                      >
                        We, The HIBRO-Home Services gladly comes up with
                        abundant number of quality and sterile services to make
                        your life uncomplicated. Count in us for all essential
                        services that you require and ensure your day-to-day
                        life is hassle/mess free.
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default AboutUsContent;
