import React from "react";
import Navbar from "../Navbar";

function Careers() {
  return (
    <div>
      <Navbar />
      <h1>Careers Page</h1>
    </div>
  );
}

export default Careers;
