import React from "react";
import RefundHeaderPage from "./RefundHeader/RefundHeaderPage";
import RefundBodyPage from "./RefundBody/RefundBodyPage";
import Footer from "../HomePage/Footer/Footer";


function RefundAndCancellationPolicy() {
  
  
  return (
    <div>
      <RefundHeaderPage />
      <RefundBodyPage />
      <Footer />
    </div>
  );
}

export default RefundAndCancellationPolicy;
