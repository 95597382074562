import classes from "./MyOrdersByIdBody.module.css";
import { Box, Card, CardContent, Container, Typography, Divider } from "@material-ui/core";
import Tables from "./MyOrdersByIDTables";
import Tables2 from "./MyOrdersByIDTables2";
import Tables3 from "./MyOrdersByIDTables3";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";

function MyOrderByIdBody() {
  let { orderId } = useParams();

  let headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  const [orderInfoFromDB, setOrderInfoFromDB] = useState();

  useEffect(() => {
    if (headers) {
      axios.get(`https://admin.hibroservices.com/api/orders/getOrderById/${orderId}`, { headers }).then((response) => {
        if (response.data.success) {
          setOrderInfoFromDB(response.data.result);
          console.log(response.data.result);
        } else {
          alert(response.data.message);
        }
      });
    } else {
      alert("Signin and Get Orders");
    }
  }, []);
  return (
    <div>
      {orderInfoFromDB && (
        <Container>
          <div>
            <Card className={classes.MyOrdersByIdDivContainer}>
              <CardContent>
                <Typography variant="h5" className={classes.orderDetailHeadingStyle}>
                  Order Details
                </Typography>
                <Box display="flex" alignItems="center">
                  <Box display="flex" flexGrow="1">
                    <div>
                      <Typography variant="h5" className={classes.MyOrdersByIDNameStyle}>
                        {orderInfoFromDB.customerDetails.name}
                      </Typography>
                      <Typography variant="h6" className={classes.MyOrdersByIDServiceIDtyle}>
                        Order ID: {orderInfoFromDB.orderDetails.orderId}
                      </Typography>
                    </div>
                  </Box>
                  <Typography variant="body1" className={classes.MyOrdersByIDServiceIDtyle}>
                    Order Status: <span style={{ fontWeight: 600 }}>{orderInfoFromDB.orderDetails.overallStatus}</span>
                  </Typography>
                </Box>
                <Typography variant="body1" className={classes.MyOrdersByIdsectionTitle}>
                  Delivered to
                </Typography>
                <Divider className={classes.MyOrderByIdBodyDivider} />
                <Tables
                  customerName={orderInfoFromDB.customerDetails.name}
                  mobileNumber={orderInfoFromDB.customerDetails.phoneNumber}
                  email={orderInfoFromDB.customerDetails.email ? orderInfoFromDB.customerDetails.email : "-"}
                  address={
                    orderInfoFromDB.addressDetails.addressLine1 +
                    ", " +
                    orderInfoFromDB.addressDetails.addressLine2 +
                    ", " +
                    orderInfoFromDB.addressDetails.city +
                    ", " +
                    orderInfoFromDB.addressDetails.pincode
                  }
                />
                <Typography variant="body1" className={classes.MyOrdersByIdsectionTitle}>
                  Basic Details
                </Typography>
                <Divider className={classes.MyOrderByIdBodyDivider} />
                <Tables3
                  orderId={orderInfoFromDB.orderDetails.orderId}
                  orderedDate={orderInfoFromDB.orderDetails.orderedDate}
                  totalPrice={orderInfoFromDB.orderDetails.totalPrice}
                  overallStatus={orderInfoFromDB.orderDetails.overallStatus}
                />
                <Typography variant="body1" className={classes.MyOrdersByIdsectionTitle}>
                  Ordered Products
                </Typography>
                <Divider className={classes.MyOrderByIdBodyDivider} />
                <Tables2 orderItems={orderInfoFromDB.orderItems} />
              </CardContent>
            </Card>
          </div>
        </Container>
      )}
    </div>
  );
}

export default MyOrderByIdBody;
