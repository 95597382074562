import React, { useState, useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Box, TextField, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import image from "../../../../Images/servicevendor.png";
import Container from "@material-ui/core/Container";
import Rules from "./VendorRules";
import HibroButton, { HibroYellowButton } from "../../../../UI/Button/Button";
import AvailabilityDialog from "./AvailabilityDialog";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(0),
    minWidth: 400,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2),

    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      minwidth: "80%",
      width: "400px",
    },
    "& .MuiButtonBase-root": {
      margin: theme.spacing(2),
    },
  },
  clearButton: {
    backgroundColor: "#fff212",
    color: "#000",
    "&:hover": {
      backgroundColor: "#fff212",
      color: "#000",
    },
  },
  submitButton: {
    backgroundColor: "#0066ff",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#0066ff",
      color: "#fff",
    },
  },
  img: {
    width: "100%",
    height: "auto",
    marginTop: "50px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
}));

const rulesList = [
  "a. Are you capable of providing customer friendly service in a professional way?",
  "b. Interested to provide service under the 12 categories of HiBro services?",
  "c. Can you furnish services that is location specific?",
  "d. Will you or your team be able to handle multiple service requests at the same time?",
  "Register Now! and start providing your services.",
];

// const categoryList = [
//   {
//     id: "1",
//     name: "Electrical",
//     value: "23",
//   },
//   {
//     id: "2",
//     name: "Plumbing",
//     value: "24",
//   },
//   {
//     id: "3",
//     name: "AC",
//     value: "25",
//   },
//   {
//     id: "4",
//     name: "Home Appliances",
//     value: "26",
//   },
//   {
//     id: "5",
//     name: "Civil Works",
//     value: "27",
//   },
//   {
//     id: "6",
//     name: "Carpentry",
//     value: "28",
//   },
//   {
//     id: "7",
//     name: "Renovation",
//     value: "29",
//   },
//   {
//     id: "8",
//     name: "Cleaning Services",
//     value: "30",
//   },
//   {
//     id: "9",
//     name: "Pest Control",
//     value: "31",
//   },
//   {
//     id: "10",
//     name: "Gardening",
//     value: "32",
//   },
//   {
//     id: "11",
//     name: "Painting",
//     value: "33",
//   },
//   {
//     id: "12",
//     name: "AMC Services",
//     value: "3:",
//   },
// ];

export default function Form() {
  const { register, handleSubmit } = useForm();
  const classes = useStyles();
  const [category, setCategory] = React.useState("");
  const [ThanksMessage, setThanksMessage] = useState("");
  const [nameField, setnameField] = useState("");
  const [mobileField, setMobileField] = useState("");
  const [emailField, setEmailField] = useState("");
  const [district, setDistrict] = useState("");
  const [location, setLocation] = useState("");

  const handleChange = (event) => {
    setCategory(event.target.value);
    setThanksMessage("");
  };
  const handleChange1 = (event) => {
    setnameField(event.target.value);
    setThanksMessage("");
  };
  const handleChange2 = (event) => {
    setMobileField(event.target.value);
    setThanksMessage("");
  };
  const handleChange3 = (event) => {
    setEmailField(event.target.value);
    setThanksMessage("");
  };

  const handleChange4 = (event) => {
    setLocation(event.target.value);
    setThanksMessage("");
  };

  const handleChange5 = (event) => {
    setDistrict(event.target.value);
    setThanksMessage("");
  };

  const [availOpen, setAvailOpen] = useState(false);

  const [categoryList, setCategoryList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [locationList, setLocationList] = useState([]);

  useEffect(() => {
    axios.get(`https://admin.hibroservices.com/api/masters/getAvailability`).then((res) => {
      console.log(res);
      if (res.status === 200) {
        console.log(res.data.result);
        setCategoryList(
          location &&
            res.data.result.servicesList.filter((ser) =>
              res.data.result.availabilityList
                .filter((avail) => avail.constituencyName === location)[0]
                .services.includes(ser.serviceCategoryId)
            )
        );
        setDistrictList([...new Set(res.data.result.availabilityList.map((dist) => dist.district))]);
        setLocationList(district && res.data.result.availabilityList.filter((item) => item.district === district));
        console.log(res.data.result.availabilityList.filter((item) => item.district === district));
      } else if (res.status === 401) {
        console.log("error");
      }
    });
  }, [district, location]);

  return (
    <Container>
      <div>
        <Grid container spacing={3}>
          <Grid item lg={6} md={12} sm={12}>
            <Box display="flex" alignItems="center" style={{ height: "100%" }}>
              <div style={{ width: "100%" }}>
                <form
                  className={classes.root}
                  onSubmit={handleSubmit((data) => {
                    axios
                      .post("https://admin.hibroservices.com/api/serviceVendors/addVendorForm", {
                        name: data.fname,
                        mobileNumber: data.phonenumber,
                        email: data.email,
                        status: "new",
                        category: category,
                        location: location,
                      })
                      .then((res) => {
                        console.log(res);
                        if (res.data.success) {
                          setThanksMessage("Thanks for registering with us!... Our Executive will call you shortly.");
                          setCategory("");
                          setnameField("");
                          setMobileField("");
                          setEmailField("");
                          setDistrict("");
                          setLocation("");
                        } else {
                          setThanksMessage("Something went wrong!");
                        }
                      });
                  })}
                >
                  <Typography
                    variant="h5"
                    component="h2"
                    style={{
                      color: "#0066ff",
                      marginTop: "50px",
                      marginBottom: "20px",
                      fontFamily: "Oswald, sans-serif",
                      fontWeight: "bold",
                      fontSize: "2em",
                    }}
                  >
                    REGISTER AS A <span style={{ color: "#000" }}>SERVICE VENDOR</span>
                  </Typography>
                  <TextField
                    id="outlined-basic"
                    label="Name"
                    variant="filled"
                    required
                    type="text"
                    name="fname"
                    inputRef={register}
                    value={nameField}
                    onChange={handleChange1}
                  />
                  <br />
                  <TextField
                    style={{ display: nameField.length > 3 ? "block" : "none" }}
                    fullWidth
                    id="outlined-basic"
                    label="Mobile Number"
                    variant="filled"
                    required
                    type="text"
                    name="phonenumber"
                    inputRef={register}
                    value={mobileField}
                    onChange={handleChange2}
                  />
                  <br />
                  <TextField
                    style={{ display: mobileField.length === 10 ? "block" : "none" }}
                    fullWidth
                    id="outlined-basic"
                    label="Email id"
                    variant="filled"
                    required
                    type="text"
                    name="email"
                    inputRef={register}
                    value={emailField}
                    onChange={handleChange3}
                  />
                  <br />
                  <FormControl variant="filled" required className={classes.formControl}>
                    <InputLabel
                      style={{ display: emailField !== "" ? "block" : "none" }}
                      id="demo-simple-select-outlined-label"
                    >
                      Select District
                    </InputLabel>
                    <Select
                      style={{ display: emailField !== "" ? "block" : "none" }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={district}
                      onChange={handleChange5}
                      label="Select District"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {districtList.map((district, index) => (
                        <MenuItem key={index} value={district}>
                          {district}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <br />
                  <FormControl variant="filled" required className={classes.formControl}>
                    <InputLabel
                      style={{ display: district !== "" ? "block" : "none" }}
                      id="demo-simple-select-outlined-label"
                    >
                      Select Location
                    </InputLabel>
                    <Select
                      style={{ display: district !== "" ? "block" : "none" }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={location}
                      onChange={handleChange4}
                      label="Select Location"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {locationList &&
                        locationList.map((location, index) => (
                          <MenuItem key={index} value={location.constituencyName}>
                            {location.constituencyName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <br />
                  <FormControl variant="filled" required className={classes.formControl}>
                    <InputLabel
                      style={{ display: location !== "" ? "block" : "none" }}
                      id="demo-simple-select-outlined-label"
                    >
                      {" "}
                      Select Category
                    </InputLabel>
                    <Select
                      style={{ display: location !== "" ? "block" : "none" }}
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={category}
                      onChange={handleChange}
                      label="Select Category"
                    >
                      <MenuItem value="">
                        <em>None</em>
                      </MenuItem>
                      {categoryList &&
                        categoryList.map((category) => (
                          <MenuItem key={category.serviceCategoryId} value={category.serviceCategoryId}>
                            {category.serviceCategoryName}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  <br />
                  <Typography variant="h6" style={{ marginTop: "20px" }}>
                    {ThanksMessage}
                  </Typography>
                  <Box display="flex" style={{ marginTop: "20px", display: location !== "" ? "flex" : "none" }}>
                    <Box>
                      <HibroYellowButton
                        btnName="CLEAR"
                        type="reset"
                        onClick={() => {
                          setCategory("");
                          setThanksMessage("");
                          setnameField("");
                          setMobileField("");
                          setEmailField("");
                        }}
                      />
                    </Box>
                    <Box>
                      <HibroYellowButton
                        type="button"
                        btnName="Check Availability"
                        onClick={() => setAvailOpen(true)}
                      />
                    </Box>
                    <Box>
                      <HibroButton btnName="Submit" type="submit" />
                    </Box>
                  </Box>
                </form>
              </div>
            </Box>
          </Grid>
          <Grid item lg={6} md={12} sm={12}>
            <Box display="flex" alignItems="center" style={{ height: "100%" }}>
              <div style={{ width: "100%" }}>
                <img className={classes.img} alt="servicevendorlogin" src={image} />
              </div>
            </Box>
          </Grid>
        </Grid>
        <Container>
          <Rules heading="WHO CAN BE " heading1="A SERVICE VENDOR" rulesList={rulesList} />
        </Container>
      </div>
      <AvailabilityDialog
        openAvailDialog={availOpen}
        onCloseAvailClick={() => setAvailOpen(false)}
        district={district}
      />
    </Container>
  );
}
