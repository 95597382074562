import React from "react";
import FollowUs from "./Followus";
import FooterLinks from "./FooterLinks";
import FootBottom from "./FootBottom";

function Footer() {
  return (
    <div className="footer">
      <FollowUs />
      <FooterLinks />
      <FootBottom />
    </div>
  );
}

export default Footer;
