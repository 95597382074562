import React from "react";
import PrivacyHeaderPage from "./PrivacyHeader/PrivacyHeaderPage";
import PrivacyBodyPage from "./PrivacyBody/PrivacyBodyPage";
import Footer from "../HomePage/Footer/Footer";

function PrivacyPolicy() {
    return (
        <div>
            <PrivacyHeaderPage />
            <PrivacyBodyPage />
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;