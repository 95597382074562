import classes from "./UI.module.css";

export default function HibroButton(props) {
  return (
    <button type={props.type} className={classes.btnStyle}>
      {props.btnName}
    </button>
  );
}

export function HibroYellowButton(props) {
  return (
    <button type={props.type} className={classes.btnYellowStyle} onClick={props.onClick}>
      {props.btnName}
    </button>
  );
}
