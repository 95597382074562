import React from "react";
import PricingHeaderPage from "./PricingHeader/PricingHeaderPage";
import PricingBodyPage from "./PricingBody/PricingBodyPage";
import Footer from "../HomePage/Footer/Footer";

function PrivacyPolicy() {
    return (
        <div>
            <PricingHeaderPage />
            <PricingBodyPage />
            <Footer />
        </div>
    )
}

export default PrivacyPolicy;