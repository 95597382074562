import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import FacebookIcon from "@material-ui/icons/Facebook";
import { Box } from "@material-ui/core";

function FollowUs() {
  return (
    <div className="bg-blk py-3">
      <Container>
        <Grid container spacing={1}>
          <Grid item md={6}>
            <Box display="flex" style={{height: "100%"}} alignItems="center">
              <h1 className="oswald followHeading">FOLLOW US</h1>
            </Box>
          </Grid>
          <Grid className="groupIcon" item md={6}>
            <div className="iconBg">
              <ul className="list-inline">
                <li>
                  <Box display="flex" style={{height: "100%"}} alignItems="center">
                    <a
                      className="bg"
                      href="https://www.facebook.com/hibrohomeservices"
                      target="_blank"
                      rel="noreferrer"
                      style={{ textDecoration: "none" }}
                    >
                      {/* <i className="flaticon-facebook"></i> */}

                      <FacebookIcon />
                    </a>
                  </Box>
                </li>
                <li>
                  {" "}
                  <a
                    className="bg"
                    href="https://twitter.com/HibroOnine"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="flaticon-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="bg"
                    href="https://www.youtube.com/channel/UCe2i1Tia0D6AlKkXOxrSomg"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    <i className="flaticon-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    className="bg"
                    href="https://www.instagram.com/hi_bro_home_services/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                  >
                    {" "}
                    <i className="flaticon-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default FollowUs;
