import React from "react";
import Logo from "../../../HomePage/Header/Logo";
import { Container, Box } from "@material-ui/core";

import Navbar from "../../../NavigationBarPages/NavbarForVendorPage";

function Header() {
  return (
    <div className="bannerForVendorHeader">
      <Container>
        <Box display="flex">
          <Box flexGrow={1}>
            <Logo />
          </Box>
          <Box display="flex" alignItems="center">
            <Navbar />
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default Header;
