// import classes from "./MyOrdersByIDTables2.module.css";
import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

// function createData(sNo, productName, orderStatus, rate, quantity, gst, totalPrice) {
//   return { sNo, productName, orderStatus, rate, quantity, gst, totalPrice };
// }

// const rows = [
//   createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
//   createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
//   createData("Eclair", 262, 16.0, 24, 6.0),
//   createData("Cupcake", 305, 3.7, 67, 4.3),
//   createData("Gingerbread", 356, 16.0, 49, 3.9),
// ];

export default function MyOrdersByIDTables2(props) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell style={{ fontWeight: 600, fontSize: "15px" }} align="center">
              S. No.
            </TableCell>
            <TableCell style={{ fontWeight: 600, fontSize: "15px" }} align="center">
              Product Name
            </TableCell>
            <TableCell style={{ fontWeight: 600, fontSize: "15px" }} align="center">
              Rate
            </TableCell>
            <TableCell style={{ fontWeight: 600, fontSize: "15px" }} align="center">
              Quantity
            </TableCell>
            <TableCell style={{ fontWeight: 600, fontSize: "15px" }} align="center">
              GST
            </TableCell>
            <TableCell style={{ fontWeight: 600, fontSize: "15px" }} align="center">
              Total Price
            </TableCell>
            <TableCell style={{ fontWeight: 600, fontSize: "15px" }} align="center">
              Order Status
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.orderItems.map((row, index) => (
            <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell style={{ fontWeight: 500, fontSize: "15px" }} align="center">
                {index + 1}
              </TableCell>
              <TableCell style={{ fontWeight: 500, fontSize: "15px" }} align="center">
                {row.productName}
              </TableCell>
              <TableCell style={{ fontWeight: 500, fontSize: "15px" }} align="center">
                {row.rate}
              </TableCell>
              <TableCell style={{ fontWeight: 500, fontSize: "15px" }} align="center">
                {row.quantity}
              </TableCell>
              <TableCell style={{ fontWeight: 500, fontSize: "15px" }} align="center">
                {row.gst}
              </TableCell>
              <TableCell style={{ fontWeight: 500, fontSize: "15px" }} align="center">
                {row.totalPrice}
              </TableCell>
              <TableCell style={{ fontWeight: 600, fontSize: "15px" }} align="center">
                {row.orderStatus}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
