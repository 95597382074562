import React from "react";
import Header from "./Header/Header";
import BlogCard from "./BlogCard";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import CardBlog from "./CardBlog";
import BlogLeft from "./BlogLeft";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
function Blog() {
  return (
    <div>
      <Header/>
 
    
      <Container className="py-4">
      <h1 className="contHeading text-center py-4">
        <span  style={{ color: "#0066ff" }}>BLOG </span>
      </h1>
      <Grid container spacing={4}>
        <Grid  item md={9} xs={12} >
     
      <BlogCard
      
        img={CardBlog[0].imgURL}
        date={CardBlog[0].date}
        name={CardBlog[0].name}
        content={CardBlog[0].content}
        
        
      />
      <BlogCard
       
         img={CardBlog[0].imgURL}
         date={CardBlog[0].date}
         name={CardBlog[0].name}
         content={CardBlog[0].content}
        
      />
      <BlogCard
       
         img={CardBlog[0].imgURL}
         date={CardBlog[0].date}
         name={CardBlog[0].name}
         content={CardBlog[0].content}
         
      />
       </Grid>
       <Grid  item md={3} xs={12} >
       <input
        type="text"
        name="search"
        placeholder="Search here"
        className="inputName"
/>

<BlogLeft/>
<div className="">
<h5 className="roboto">
            <LocationOnIcon className="blue" />
            &nbsp; P.No.18, 1st
            Avenue, Pallava Garden, Old Pallavaram, Chennai - 600 117.
          </h5>
          <h5 className="roboto">
            <PhoneIcon className="blue" />
            &nbsp;9962562562
          </h5>
          <h5 className="roboto">
            <EmailIcon className="blue" />
            &nbsp; hibroservices.com
          </h5>
          <h5>
            <span className="blue">Opened: </span>9.00 am to 6.00pm
            (Monday to Saturday)
          </h5>
          <h5>
            <span className="blue">Closed: </span>Sunday and
            National Holidays
          </h5>
          <h5>
            <span className="blue">
              Bookings and Orders Accepted: 24/7
            </span>
          </h5>
</div>
         </Grid>
    </Grid>
    </Container>
    </div>
    
  );
}

export default Blog;
