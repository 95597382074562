import React from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";

function WorksCard(props) {
  // const classes = useStyles();
  return (
    <Card className="happyCustomerCard">
      <CardContent>
        <Typography variant="h5" component="h5" gutterBottom>
          {props.customerName}
        </Typography>
        <Typography variant="h6" component="h5" gutterBottom>
          {props.tag}
        </Typography>
        <Typography
          variant="body2"
          component="p"
          color="textSecondary"
          gutterBottom
        >
          {props.review}
        </Typography>
        <Typography variant="h6" component="h5" gutterBottom>
          Ratings and Reviews
        </Typography>
        <StarIcon style={{ color: "#fff212" }} />
        <StarIcon style={{ color: "#fff212" }} />
        <StarIcon style={{ color: "#fff212" }} />
        <StarIcon style={{ color: "#fff212" }} />
        <StarIcon style={{ color: "#fff212" }} />
      </CardContent>
    </Card>
  );
}

function HappyCustomers() {
  return (
    <div className="happyBox" style={{ marginBottom: "50px" }}>
      <h1 className="happyText oswald">
        <span style={{ color: "#fff" }}>OUR</span> HAPPY
        <span style={{ color: "#fff" }}> CUSTOMERS</span>
      </h1>
      <Container className="py-4">
        <Grid container spacing={4}>
          <Grid item md={3} xs={12}>
            <WorksCard
              customerName="Akash"
              tag="Value for Money"
              review="I Will Definitely Recommend HiBro Services To My Friends And Family"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <WorksCard
              customerName="Samuelraj"
              tag="Very Hygiene"
              review="Safety Measures taken by the servicemen was need during this time."
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <WorksCard
              customerName="Prabha"
              tag="Good Job"
              review="The Service Was More Promising... Thank You Hibro Team!!!"
            />
          </Grid>
          <Grid item md={3} xs={12}>
            <WorksCard
              customerName="GandhiMathy"
              tag="On Time"
              review="The Work Was Completed On Time. A Great Service For All Home Services."
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}

export default HappyCustomers;
