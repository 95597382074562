import React, { useState, useEffect } from "react";
import { Card, Link } from "@material-ui/core";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Chip from "@material-ui/core/Chip";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import moment from "moment";

let headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

let token = localStorage.getItem("token");

export default function SingleBookingCard(props) {
  const [upcomingBooking, setUpcomingBooking] = useState([]);

  useEffect(() => {
    if (token) {
      axios.get("https://admin.hibroservices.com/api/serviceRequest/getMyBookings/history", { headers }).then(
        (response) => {
          let resu = response.data.result;
          console.log(resu);
          for (let i = 0; i < resu.length; i++) {
            let sum = 19;
            console.log(resu[i].serviceOptin);
            for (let j = 0; j < resu[i].serviceOptin.length; j++) {
              sum += resu[i].serviceOptin[j]["serviceId"]["serviceChargeMin"];
            }
            resu[i]["totalAmount"] = sum;
            sum = 19;
          }
          setUpcomingBooking(resu);
          console.log(resu);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, []);

  // useEffect(() => {
  //   const total = () => {
  //     let totalVal = 0;
  //     for (let i = 0; i < upcomingBooking.serviceOptin.length; i++) {
  //       totalVal += upcomingBooking.serviceOptin[i].serviceId.serviceChargeMin;
  //     }
  //     setOrderValue(totalVal + 19);
  //   };
  //   total();
  // });

  return (
    <div>
      <Grid container spacing={2} justify="center">
        {upcomingBooking.map((booking, i) => (
          <Grid item md={4} sm={6}>
            <Link
              color="inherit"
              underline="none"
              href={"/navbar/options/my-bookings/history/" + booking.requestOrderNumber}
            >
              <Card key={booking.requestOrderNumber} id={booking.requestOrderNumber} style={{ minWidth: "325px" }}>
                <CardHeader
                  style={{ backgroundColor: "#0066FF", color: "#fff" }}
                  title={
                    <Box display="flex">
                      <Box>
                        <Typography gutterBottom variant="h5" component="h2" noWrap>
                          {booking.requestOrderNumber}
                        </Typography>
                      </Box>
                      <Box display="flex" justifyContent="flex-end" alignItems="center" width={1}>
                        <Typography gutterBottom variant="subtitle1" component="h2" noWrap>
                          {booking.ticketStatus}
                        </Typography>
                      </Box>
                    </Box>
                  }
                />
                <CardContent>
                  <Box display="flex">
                    <Box mt={1} width={1}>
                      <Typography gutterBottom variant="h6" component="h2">
                        {booking.serviceOptin[0]?.serviceId.serviceName}
                      </Typography>
                      <Typography gutterBottom variant="subtitle1" component="h2">
                        {moment(booking.serviceDateTime).format("lll")}
                      </Typography>
                      <Typography variant="body2" color="textSecondary" component="p">
                        {booking.address}
                      </Typography>
                    </Box>
                    <Box display="flex" justifyContent="flex-end" mt={2}>
                      <Chip className="blueButton" label={"₹" + booking.totalAmount} />
                    </Box>
                  </Box>
                </CardContent>
              </Card>
            </Link>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
