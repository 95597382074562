import React from "react";



function BlogCard(props) {
  // const classes = useStyles();
  return (
    <div className="card  py-3">
    <div className="top-card roboto ">
    <p className="pos-top">{props.date}</p>
      <img className="circle-img" src={props.img} alt="avatar_img" />
     
      <h2 className="name pt-2">{props.name}</h2>
      <p className="info">{props.content}</p>
     
    </div>
   
  </div>
  );
}



export default BlogCard;
