import React from "react";
import { Container } from "@material-ui/core";

function TermsBodyPage() {
  return (
    <div className="termsBodyPageStyle">
      <Container>
        <h1 className="oswald headingTerms">
          <span className="blue">TERMS AND</span> CONDITIONS
        </h1>
        <h2 className="oswald headingTerms">
          <span className="blue">CONTRACTUAL</span> RELATIONSHIP:
        </h2>
        <p className="roboto paraTerms">
          The use of this website and the services of this website provided by Prajha Buildings Private Limited
          (hereinafter referred to as “Owner”) are subject to the following terms and conditions (hereinafter referred
          to as “terms of service”), all parts and subparts of which are specifically incorporated by reference here
          together with the privacy policy. Following are the Terms of Service governing your use of
          www.hibroservices.com (the “Website”) all pages on the website and the services provided by on behalf of the
          website (“services”).
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">PLEASE READ</span> THE TERMS OF SERVICE CAREFULLY BEFORE ASSESSING OR USING THE WEBSITE
        </h2>
        <p className="roboto paraTerms">
          By assessing either directly or through a hyperlink, the Website, and/or purchasing something from us, you
          engage in our “service” and agree to be bound by the Terms of Service including those additional terms and
          conditions and policies referenced herein and/or available by hyperlink. These Terms of Service apply to all
          users of the site, including without limitation vendors, buyers, customers, merchants, browsers and/or
          contributors of content. You acknowledge and agree that the website may use your personal information in the
          manner described in our privacy policy which sets forth how information collected about you is collected, used
          and stored.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 1:</span> DEFINITIONS
        </h2>
        <p className="roboto paraTerms">
          The parties referred to in these Terms of Service shall be defined as follows:
          <ol style={{ listStyleType: "lower-alpha" }}>
            <li>
              Owner, us, We: the Owner, as the creator, operator, and the publisher of the website, makes the website,
              and certain services on it, available to users. Prajha Buildings Private Limited, Owner, as, we, our, ours
              and other first person pronouns will refer to the Owner, as well as all employees and affiliates of the
              Owner.
            </li>
            <li>
              You, the user, the Client: You as the user of the website, will be referred to throughout these terms of
              service with second person pronouns such as You, Your, Yours, or as User or Client. For the purpose of
              these terms of service, the term user or within “You” shall mean any natural or legal person whose person
              is accessing the website. The term You are shall be construed accordingly.
            </li>
            <li>
              parties: collectively, the parties to these terms of service (the Owner and You) will be referred to as
              parties.
            </li>
          </ol>
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 2:</span> ASSENT AND ACCEPTANCE
        </h2>
        <p className="roboto paraTerms">
          By using the Website, You warrant that You have read and reviewed these Terms of Service and that You agree to
          be bound by it. If You do not agree to be bound by these terms of service, please leave the Website
          immediately. The Owner only agrees to providers this Website and services to You if You are sent to these
          terms of service. Further, based on the services obtained by a user, additional terms and conditions in
          respect of the specific services may apply, which shall be deemed an agreement between the users and the
          Owner.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 3:</span> AGE RESTRICTION
        </h2>
        <p className="roboto paraTerms">
          You must be at least 18 years of age to use this website or any service contained herein. By using this
          website, You represent and warrant that You are at least 18 years of age and may legally agree to these terms
          of service. The Owner assumes no responsibility or liability for any misrepresentation of Your age.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 4:</span> ABOUT THE SITE
        </h2>
        <p className="roboto paraTerms">
          The Website is a technology platform to assess and use Prajha Buildings Private Limited (name of Owner) mobile
          applications or website hibro services which carries out sale for the following: Buildings Construction,
          Repair, Maintenance, Renovations ,etc., service. We reserve the right to refuse service, or refuse to sell the
          products on the website and are sole discretion to anyone for any reason at any time.The Website does not
          screen or censor the users who register on and access the website. You assume all risks associated with
          dealing with other users with whom You come in contact through the website. You agree to use the Website only
          for lawful purposes without infringing the rights are restricting the use of this Website by any third party.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 5:</span> LICENCE TO USE WEBSITE
        </h2>
        <p className="roboto paraTerms">
          The Owner may provide You with certain information as a result of your use of the Website or services will
          stop such information may include, but is not limited to, documentation, data, or information developed by the
          Owner, and other materials which may assist in your use of the website or services (“Owner materials”).
          Subject to these Terms of Service, the Owner grants You a non-exclusive, limited, non-transferable and
          revocable license to use the Owner materials solely in connection with your use of the Website and services.
          The Owner materials may not be used for any other purpose and this license terminates upon your succession of
          use of the Website or services or the termination of these Terms of Service. You agree not to collect contact
          information of other users from the website or download or copy any information by means of unsolicited access
          so as to communicate directly with them or for any reason whatsoever. Any unauthorised use by You shall
          terminate the permission or license granted to You by the website and You agree that You shall not bypass any
          measures used by the Owner to prevent touristic access to the website.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 6:</span> INTELLECTUAL PROPERTY
        </h2>
        <p className="roboto paraTerms">
          The Owner may provide You with certain information as a result of your use of the Website or services will
          stop such information may include, but is not limited to, documentation, data, or information developed by the
          Owner, and other materials which may assist in your use of the website or services (“Owner materials”).
          Subject to these Terms of Service, the Owner grants You a non-exclusive, limited, non-transferable and
          revocable license to use the Owner materials solely in connection with your use of the Website and services.
          The Owner materials may not be used for any other purpose and this license terminates upon your succession of
          use of the Website or services or the termination of these Terms of Service. You agree not to collect contact
          information of other users from the website or download or copy any information by means of unsolicited access
          so as to communicate directly with them or for any reason whatsoever. Any unauthorised use by You shall
          terminate the permission or license granted to You by the website and You agree that You shall not bypass any
          measures used by the Owner to prevent touristic access to the website.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 7:</span> USER OBLIGATIONS
        </h2>
        <p className="roboto paraTerms">
          As a user of the website or services, You may be asked to register with us. When You do so, You will choose a
          user identifier, which may be your email address or another term, as well as a password. You may also provide
          personal information, including but not limited to, Your name. You are responsible for ensuring the accuracy
          of this information. This identifying information will enable You to use the website and services. You must
          not share such identifying information with any third party and if You discover that your identifying
          information has been compromised, You agree to notify us immediately in writing. Email notice will suffice.
          You are responsible for maintaining the safety and security of your identifying information as well as keeping
          us apprised of any changes to your identifying information. The billing information You provide us, including
          credit card, billing address and other payment information, subject to the same confidentiality and accuracy
          requirements as the rest of your identifying information. Providing false or inaccurate information, using the
          website or services to further fraud or unlawful activity is grounds for immediate termination of these terms
          of service. The Owner reserves the right to refuse service terminate accounts, remove or edit content in its
          sole secretion.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 8:</span> TEXT MESSAGING
        </h2>
        <p className="roboto paraTerms">
          By creating an account you agree that the services may send you text messages as part of the normal business
          operations of your use of the services. You consent to receiving such text messages and acknowledge that any
          steps taken by you to not receive such text messages will affect your use of the services.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 9:</span> PAYMENT AND FEES
        </h2>
        <p className="roboto paraTerms">
          Should You register for any of the paid services on this website or purchase any product or service on this
          website, You agree to pay us the specific monetary amounts required for that product or those services. These
          monetary amounts (fees) will be described to You during your account registration and/or confirmation process.
          The final amount required for payment will be shown to You immediately prior to purchase of the services.
          Payment for any ongoing services is billed automatically until You notify us that You would like to terminate
          your success to the services. You retain the right to receive lower amount as final payment towards delivery
          of services to you. Accordingly the invoice will be suitably modified and the final amount will be invoiced.
          You reserve the right to bill higher amount as final payment, if the service type has been wrongly selected or
          You would require a different service at the time of performance of the service. The Owner does not designate
          any portion of the fees as tip or gratuity for the service personnel. You are free to provide additional
          amount as gratuity and it is purely voluntary and is not required to be paid as part of the Terms of Service
          and You are under no obligation to do so. The invoice shall include the taxes as per applicable tax laws and
          as per applicable tax rates. The Owner being a Service Provider and provision of service would attract tax
          under local and state tax laws. If You cancel a request for your services after acceptance of the said request
          by you and payment of fees, will not be refunded to you. The fees may be refunded to you only in cases where
          our services are not available at the location or at the time for, which the services has been requested. If
          You cancel a request after accepting such a request, Prajha Buildings Private Limited (Owner) shall be at
          liberty to determine whether or not to refund the fees, to you. You agree that in case, Prajha Buildings
          Private Limited (Owner) determines that the fees is not required to be refunded to you due to your
          cancellation of the accepted request by You, You will relinquish your right to make any claims for refund of
          the fees. We reserve the right to refuse service or refuse to sell the products on the website at our sole
          discretion to anyone for any reason at any time.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 10:</span> ACCEPTABLE USE
        </h2>
        <p className="roboto paraTerms">
          You agree not use the website or services for any unlawful purpose or any purpose bribed under this clause.
          You agree not to use the website or services in any way that could damage the website, services or general
          business of the Owner. a) You further agree not to use the website or services: i) to harass, abuse, or
          threaten others or otherwise violate any persons legal rights; ii) to violate any intellectual property rights
          of the Owner or any third party; iii) to upload or otherwise disseminate any computer viruses or other
          software that may damage the property of another; iv) to perpetrate any fraud; v) to engage in or create any
          unlawful gambling, sweepstakes, or pyramid scheme; vi) to publish or distribute any obscene or death imagery
          material; vii) to publish or distribute any material that insights while and is, hate or discrimination words
          any group; viii) to unlawfully gather information about others You are prohibited from using the site or its
          contents (a) for any unlawful purpose; (b) to solicit others to perform or participate in any unlawful acts;
          (c) to infringe on any third parties intellectual property or proprietary rights, rights of publicity or
          privacy, whether knowingly or unknowingly; (d) to violate any local, federal or international law, statute,
          ordinance or regulation; (e) to harass, abuse, insult, harm, defame, slender, disparage, intimidate, or
          discriminate based on gender, sexual orientation, religion, ethnicity, race, age, national origin, or
          disability; (f) to submit false or misleading information or any content which is defamatory, libellous,
          threatening, unlawful harassing, indecent, abusive, obscene or lewd and lascivious or pornographic, or exploit
          minors in anyway or assist in human trafficking or content that would violate rights of publicity and/or
          privacy or that would violate any law; (g) to upload or transmit viruses or any other type of malicious code
          that we are may be used in any way that will affect the functionality or operation of the service or any
          related website, other websites or the Internet; (h) to collect or tag the personal information of others; (i)
          to damage, disable, overburden, or impair the website or any other parties use of the website; (j) to spam,
          fish, from, text, spider, crawl, or scrape; for any obscene or immoral purpose or; (k) to interfere with or
          circumvent the security features of the service or any related website, or other websites, or the Internet;(l)
          to personally threatened or has the effect of personally threatening other users we reserve the right to
          terminate your use of the service or any related website for violating any of the prohibited uses. The company
          has the full authority to review all content posted by the user on the website. You acknowledge that the
          website is not responsible or liable and does not control the content of any information that may be posted to
          the website by You or other user of the website and You are solely responsible for the same. You agree that
          You shall not upload, post, or transmit any content that You do not have a right to make available (such as,
          the intellectual property of another party). You agree to comply with all applicable laws, statutes and
          regulations concerning your use of the website and further agree that You will not transmit any information,
          data, text, files, links, software, chats, communication or other materials that is abusive, invasive of
          another’s privacy, harassing, definitely, vulgar, obscene, unlawful, falls, misleading, harmful, threatening,
          hateful or raised nearly or otherwise objectionable, including without limitation material of any kind or
          nature that encourages conduct that could constitute a criminal offence give rise to civil liability or
          otherwise violate any applicable local, State, Provincial, National or International law or regulation, or
          encourage the use of controlled substances. We may, but have no obligation to, monitor, edit or remove content
          that we determine in our sole discretion are unlawful, offensive, threatening, labour less, deformity,
          pornographic, obscene or otherwise objectionable or violates any parties intellectual property or these terms
          of service. You may not use our products for any illegal or unauthorised purpose now may You, in the use of
          service violate any laws in your jurisdiction (including but not limited to copyright laws).
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 11:</span> PRIVACY INFORMATION
        </h2>
        <p className="roboto paraTerms">
          Through your use of the website and services, You may provide us with certain information. By using the
          website or the services, You authorise the Owner to use your information in India and any other country where
          we may operate. a) Information we may collect or receive: when You register for an account, You provide us
          with a valid email address and a Reuters with additional information, such as your name or billing
          information. Depending on how You use of the website or services we may also receive information from external
          applications You use to access our website or we may receive information through various web technologies,
          such as cookies, log files, clear GIFs, web beacons or others. b) How we use information: we use the
          information gathered from You to ensure Your continued good experience on our website, including through email
          communication. We may also track certain of the passive information received to improve our marketing and
          analytics, and for this we may work with third-party providers. c) How You can protect your information: if
          You would like to disable our access to any passive information we receive from the use of various
          technologies, You may choose to disable cookies in your web browser. Please be aware that the Owner will still
          receive information about You that You have provided such as your email address if You choose to terminate
          Your account the Owner will store information about You follow a number of days 3 after that time it will be
          deleted.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 12:</span> SALE OF GOODS/SERVICES
        </h2>
        <p className="roboto paraTerms">
          The Owner may sell goods or services allow third parties to sell goods or services on the website. The Owner
          undertakes to be as accurate and possible with all information regarding the goods and services, including
          product descriptions and images. However the Owner does not guarantee the accuracy or reliability of any
          product information and You acknowledge and agree that You purchase such products at Your own risk. If you’re
          unhappy with the service being sold on our website You may request a refund.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 13:</span> REVERSE ENGINEERING AND SECURITY
        </h2>
        <p className="roboto paraTerms">
          You agree not to undertake any of the following actions: a) reverse engineer, or attempt to reverse engineer
          or dissemble any code or software from or on the website or services; b) violate the security of the website
          or services through any unauthorised access, circumvention of encryption or other security tools, data mining
          or interface to any host, user or network.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 14:</span> DATA LOSS
        </h2>
        <p className="roboto paraTerms">
          The Owner does not accept responsibility for the security of your account or content. You agree that your use
          of the website or services is at your own risk.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 15:</span> INDEMNIFICATION
        </h2>
        <p className="roboto paraTerms">
          You agree to defend and indemnify the Owner and any of its affiliates (if applicable) and hold us harmless
          against any and all legal claims and demands, including reasonable attorney’s fees which may arise from or
          related to use or misuse of the website or services. Your breach of these terms of service or your conduct or
          actions, You agree that the Owner shall be able to select its own legal counsel and may participate in its own
          defence, if the Owner wishes.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 16:</span> SPAM POLICY
        </h2>
        <p className="roboto paraTerms">
          You are strictly prohibited from using the website or any of the Owners of services for illegal spam
          activities, including gathering email addresses and personal information from others or sending any mass
          commercial emails.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 17:</span> THIRD PARTY LINKS AND CONTENT
        </h2>
        <p className="roboto paraTerms">
          The Owner may occasionally post links to third-party websites or other services You agree that the Owner is
          not responsible or liable for any loss or damage caused as a result of your use of any third party services
          linked to from our website.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 18:</span> MODIFICATION AND VARIATION
        </h2>
        <p className="roboto paraTerms">
          The Owner may from time to time and at any time without notice to You, modify these terms of service. You
          agree that the Owner has the right to modify these terms of service or revise anything contained herein. You
          agree further that all modifications to these terms of service are in full force and effect immediately upon
          posting on the website and that modifications are variations will replace any prior version of these terms of
          service, unless prior versions are specifically referred to are incorporated into the latest modification or
          variation of these terms of service. a) To the extent any part or support of these terms of service is held in
          effective or invalid by any code of law, You agree that the prior, effective version of these terms of service
          shall be considered enforceable and valid to the full effect. b) You agree to routinely monitor these terms of
          service and referred to the effective date posted at the top of these terms of service to note modifications
          are variations. You further agree to clear your catch a well doing so to avoid accessing a prior version of
          these terms of service. You agree that you were continued use of the website after any modification to these
          terms of service is a manifestation of your continued ascent to these terms of service. c) In the event that
          you fail to monitor any modifications to our variations of these terms of service, you agree that such failure
          shall be considered an affirmative waiver of your right to review the modified agreement.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 19:</span> ENTIRE AGREEMENT
        </h2>
        <p className="roboto paraTerms">
          This agreement constitutes the entire understanding between the parties with respect to any and all use of
          this website. This agreement supersedes and replaces all prior or contemporaneous agreements or understandings
          with an unruly regarding the use of this website.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 20:</span> SERVICE INTERRUPTIONS
        </h2>
        <p className="roboto paraTerms">
          The Owner may need to interrupt your access to the website to perform maintenance or emergency services on a
          scheduled run scheduled basis. You agree that your access to the website may be affected by an anticipated a
          run scheduled downtime, for any reason, but that the Owner shall have no liability for any damage or loss
          caused as a result of such downtime.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 21:</span> TERM, TERMINATION AND SUSPENSION
        </h2>
        <p className="roboto paraTerms">
          The Owner may terminate these terms of service with you at any time for any reason with or without cost. The
          Owner specifically reserves the right to terminate these terms of service if you while any of the terms
          outlined herein, including, but not limited to, piloting the intellectual property rights of the Owner or a
          third party, failing to comply with applicable laws or other legal obligations, and/or publishing or
          distributing illegal material if you have registered for an account with us, you may also terminate these
          terms of services at any time by contacting us and requesting termination. Please keep in mind that any
          outstanding fees will still be due and after termination of your account. At the termination of these terms of
          service, any provision that would be expected to survive termination by their nature shall remain in full
          force and effect.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 22:</span> NO WARRANTIES
        </h2>
        <p className="roboto paraTerms">
          You agree that your use of the website and services is at your sole and exclusive risk and that any services
          provided by us or on a “as is” basis. The Owner hereby expressly disclaims any and all express or implied
          warranties of any kind including, but not limited to the implied warranty of fitness for a particular purpose
          and the implied warranty of merchantability. The Owner makes no warranties that the website or services will
          meet your needs or that the website or the services will be uninterrupted, there are free, or secure. The
          Owner also makes no warranties as to the reliability of accuracy of any information on the website or obtained
          through the services. You agree that any damage that may occur to you, through your computer system, or as a
          result of loss of your data from your use of the website or services is your sole responsibility and the Owner
          is not liable for any such damage or loss. All the information, software, products, services and related
          graphics are provided on the site as “as is” and “as available” basis without warranty of any kind, extras are
          employed. The website disclaims all warranties, express or implied including, without limitation, all implied
          warranties of merchantability, fitness for a particular purpose, title and non-infringement or arising from a
          course of dealing, usage or trade practice. Company makes no representation about the suitability of the
          information, software, products, and services contained on this website for any purpose, and inclusion are
          offering of any products or services on this website does not constitute any endorsement or recommendation of
          such products or services. The website makes no warranty that the use of the website will be uninterrupted
          timely secure without defect or error free. You expressly agree that use of the site is at your own risk the
          website shall not be responsible for any content found on the website. Your use of any information or
          materials on the site or otherwise obtained through use of this website is entirely at your own risk, for
          which we shall not be liable, it shall be your own responsibility to ensure that any products services or
          information available through this website media specific requirements. The website assumes no responsibility
          for the accuracy currency, completeness or usefulness of information, views, openings or advice in any
          material contained on the website. Any information of third parties or advice would you made available without
          doing any changes and so the website cannot guarantee accuracy and is not liable to any inconsistencies
          arising thereof. All postings messages advertise advertisers photos sounds, images, text, files video or other
          material posted in our transmitter through or linked to from the website, are solely the responsibility of the
          person from whom such content originated and the website does not control, and is responsible for content
          available on the website. There may be instances where incorrect information is published inadvertently on our
          website or in the service such as typographical errors, inaccuracies, or omission that may relate to product
          descriptions, pricing, promotions, offers, products shipping charges, also time and availability. Any errors,
          inaccuracies, or omissions, may be corrected at our discretion at any time and we may change or update
          information or cancel orders if any information in the service or on any related website is inaccurate at any
          time without prior notice (including after you have submitted your order). We undertake no obligation to
          update, amend or clarify information on the service or on any related website, including without limitation,
          pricing information, except as required by law. No specified update or reference date applied in the service
          or on any related website, should be taken to indicate that all information in the service or on any related
          website has been modified or updated. The website shall not be responsible for any interaction between you and
          the other users of the website under no circumstances will the website be liable for any goods, services,
          resources or content available through such third party dealings or communications, or for any harm related
          thereto. The website is under no obligation to become involved in any dispute between you and other users of
          the website or between you and any other third parties. You agree to release the website from any and all
          claims, demands, and damages arising out of or in connection with such dispute. You agree and understand that
          while the website has made reasonable effort to safeguard the website, it cannot and does not ensure or make
          any representations that the website or any of the information provided by you cannot be hacked by any
          unauthorised third parties. You specifically agree that the website shall not be responsible for any
          unauthorised access to or alteration of your transmissions or data, any material or data centre are received
          or not sent or received, or any transaction entered into through the website. You agree and confirm that the
          website shall not be held liable or responsible in any manner whatsoever for such hacking or any loss or
          damage suffered by you due to unauthorised access of the website by third parties or for any such use of the
          information provided by you or any spam messages or information that you may receive from any such another
          third party (including those which are all those sent representing the name of the website but have not been
          authorised by the website) which is in violation of content and of this terms of service or the privacy
          policy. You specifically agree that the website is not responsible and liable for any threatening, defamatory,
          obscene, offensive or illegal content or conduct of any other party or any infringement of another’s right,
          including intellectual property rights. You specifically agree that the website is not responsible for any
          content sent using and/or included in the website by any third party. The website has no liability and will
          make no refund in the event of any delay, cancellation, strike, forced majeure or other causes beyond the
          direct control, and they have no responsibility for any additional expense, omissions, delays or acts of any
          government authority. You will be solely responsible for any damages to your computer system or loss of data
          that results from the town of any information and/or material. Some jurisdictions do not allow the exclusion
          of certain warranties, so some of the above exclusions may not apply to you. Excellent in no event shall the
          website be liable for any direct, indirect, punitive, incidental, special, consequential damages or any
          damages whatsoever including, without limitation, damages for loss of use, data or profits, arising out of or
          in any way connected with the use or performance of the site, with the delay or inability to use the site or
          related services, the provision of or failure to provide services, or to deliver the products or for any
          information, software, products, services and related graphics obtained through the site, or any interaction
          between you and other participants of the website or otherwise arising out of the use of website, damages
          resulting from use of or reliance on any information present, whether based on contract, tort, negligence,
          strict liability, or otherwise, even if the website or any of its affiliate/suppliers have been advised of the
          possibly of damages. If, despite the limitation above, the company is found liable for any loss or damage
          which arises out of or in any way connected with the use of the website and/or provision of services, then the
          liability of the company will in no event exceed, 0.1 percent of the amount you paid to the company in
          connection with such transaction (s) on this website. You accept all responsibility for, and hereby agree to
          indemnify and hold harmless the company from and against, any actions taken by you or by any person authorised
          to use your account, including without limitation, disclosure of passwords to third parties. By using the
          website, you agree to defend, indemnify and hold harmless the indemnified parties from any and all liability
          regarding your use of the site or participation in any site’s activities. If you are satisfied with the
          website, or the services or any portion thereof, or do not agree with these terms, your only records and use a
          remedy shall be to stop using the site.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 23:</span> LIMITATIONS ON LIABILITY
        </h2>
        <p className="roboto paraTerms">
          The owner is not liable for any damages that may occur to you as a result of your use of the website or
          services, to the fullest extent permitted by law. There shall be no guarantee or warranty on the information
          provided and the user shall satisfy themselves of the information provided except those that are material
          relating to the service provided.
        </p>
        <h2 className="oswald headingTerms">
          <span className="blue">SECTION 24:</span> GENERAL PROVISIONS
        </h2>
        <p className="roboto paraTerms">
          A) Language: all communications made or notices given pursuant to these terms of service shall be in the
          endless language. B) Jurisdiction, venue and governing law: through your use of the website or services, you
          agree that the laws of India shall govern any matter or dispute relating to or arising out of these terms of
          service, as well as any dispute of any kind that may arise between you and the owner, with the exception of
          its conflict of law provisions. To raise a dispute you may first contact Prajha Buildings Private Limited (the
          Owner) at your local city. Alternatively can send an email communication at the specified email id with the
          details of the dispute including a recording of the dispute. In the event of unresolved dispute, the parties
          agree to first attempt to settle amicably through mediation, private or Court annexed Mediation. If the
          dispute cannot be resolved through mediation within a period of 60 days from the date of reference of dispute
          to mediation, the parties may pursue final and binding arbitration proceedings. Arbitration shall be in
          accordance with Arbitration and Conciliation Act, 2015 (as amended from time to time) and the arbitral
          tribunal shall consist of Sole Arbitrator(s) to be appointed by the Prajha Buildings Private Limited (Owner).
          The seat of arbitration shall be Chennai. Courts in Chennai shall have exclusive jurisdiction. In the unlikely
          event of your dispute not resolved through the above mechanism, the parties agree to submit to the exclusive
          jurisdiction of the Courts of Chennai, Tamilnadu, India. C) Severability: if any part or sub-part of these
          terms of services in held invalid or unenforceable by a Court of law or competent arbitrator, the remaining
          parts or sub-parts will be enforced to the maximum extent possible. In such condition, the remainder of these
          terms of service shall continue in full force. D) No waiver: in the event that we failed to enforce any
          provision of these terms of service, they shall not constitute a waiver of any future enforcement of that
          provision or any other provision. Waiver of any part or subpart of these terms of service will not constitute
          a waiver of any other part or subpart. E) Headings for convenience only: headings of parts and subparts under
          these terms of service or for convenience and organisation, only. Headings shall not affect the meaning of any
          provisions of these terms of service. F) No agency, partnership or joint venture: no agency, partnership or
          joint venture has been created between the parties and as a result of these terms of service. No party has any
          authority to bind the other to third parties. G) Electronic communications permitted: electronic
          communications are permitted to both parties under these terms of service including email or fax. For any
          questions or concerns, please email us at the following address: hibroservices@gmail.com
        </p>
      </Container>
    </div>
  );
}

export default TermsBodyPage;
