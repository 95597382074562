import React from "react";
import TermsHeader from "./TermsHeader/TermsHeaders";
import TermsBodyPage from "./TermsBody/TermsBodyPage";
import Footer from "../HomePage/Footer/Footer";

function TermsAndConditionsPage() {
    return (
        <div>
            <TermsHeader />
            <TermsBodyPage />
            <Footer />
        </div>
    )
}

export default TermsAndConditionsPage;