import { Box, Button, Divider, Typography } from "@material-ui/core";
import classes from "./CartSingleProduct.module.css";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";

function CartSingleProduct(prop) {
  return (
    <div>
      <div className={classes.cartItem}>
        <Box display="flex" alignItems="center">
          <Box display="flex" flexGrow="1">
            <Typography>{prop.productName}</Typography>
          </Box>
          <span className={classes.tabCountButton}>
            <button
              disabled={prop.count > 1 ? false : true}
              variant="outlined"
              className={classes.countIncDecBtnStyle}
              onClick={() => prop.onDecrement(prop.el)}
            >
              -
            </button>
            <span className={classes.countLabelStyle}>{prop.count}</span>
            <button
              disabled={prop.count < 10 ? false : true}
              variant="outlined"
              className={classes.countIncDecBtnStyle}
              onClick={() => prop.onIncrement(prop.el)}
            >
              +
            </button>
          </span>
          <Typography>₹{prop.productPrice}</Typography>
          <Link onClick={() => prop.onDelete(prop.el)}>
            <DeleteIcon className={classes.deleteIcon} />
          </Link>
        </Box>
      </div>
      <Divider />
    </div>
  );
}

export default CartSingleProduct;
