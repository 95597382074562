import React from "react";
import { Container, Link } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  workHeading: {
    textAlign: "center",
    margin: "75px auto 60px",
    fontFamily: "oswald",
  },
  root: {
    minWidth: 200,
    textAlign: "center",
    paddingTop: "50px",
    paddingBottom: 50,
  },
});

function WorksCard(props) {
  return (
    <Card className="workCard">
      <Link color="inherit" underline="none" href="#Browse">
        <CardContent>
          <div className="icons50">
            <i className={props.icon}></i>
          </div>

          <Typography variant="h5" component="h2" gutterBottom>
            {props.heading}
          </Typography>
          <Typography variant="body2" component="p" color="textSecondary">
            {props.subtext}
          </Typography>
        </CardContent>
      </Link>
    </Card>
  );
}

function HowItWorks() {
  const classes = useStyles();
  return (
    <Container className="py-4">
      <h1 className={classes.workHeading}>
        <span style={{ color: "#0066ff" }}>HOW IT </span>WORKS
      </h1>
      <Grid container spacing={4} alignItems="stretch">
        <Grid item md={3} xs={12} style={{ paddingBottom: "30px" }}>
          <WorksCard
            icon="flaticon-search"
            heading="Browse Services"
            subtext="Browse from a list of more than 250 services under 12 major Home services category including Electical,Plumbing,Painting Etc. which HiBro’s Expert technicians can solve in a Jiffy."
          />
        </Grid>
        <Grid item md={3} xs={12} style={{ paddingBottom: "30px" }}>
          <WorksCard
            icon="flaticon-calendar"
            heading="Schedule Service"
            subtext="Schedule the service you need at your comfortable time from your home. An Expert Technician will be scheduled to visit your home to resolve your issue. And the next thing comes to your mind is can I reschedule? YES! You can."
          />
        </Grid>
        <Grid item md={3} xs={12} style={{ paddingBottom: "30px" }}>
          <WorksCard
            icon="flaticon-open-book"
            heading="Enjoy Service"
            subtext="Enjoy the Service you book at the comfort of your home. Let our Expert technician take care of the Issue. Services which require more than one visit are given a Estimate at first visit and then proceed only after your approval. And the same goes for spares and replacements too."
          />
        </Grid>
        <Grid item md={3} xs={12} style={{ paddingBottom: "30px" }}>
          <WorksCard
            icon="flaticon-order"
            heading="Pay for the Service"
            subtext="After the Service resolution by our expert technician up to your expectations, you are given a final Invoice. You can either pay it by cash or pay it online. If you have prepaid for the service and didn’t have any additional charges incurred for the service , Just Relax and give us a rating.."
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default HowItWorks;
