import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import Box from "@material-ui/core/Box";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import LoginHandle from "./LoginHandle";

function NavBar(props) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <nav className="navbar">
        <Box display="flex" justifyContent="center" alignItems="flex-end">
          <div className="nav-container">
            <Box display="flex" justifyContent="center" alignItems="flex-end">
              <ul className={click ? "nav-menu active" : "nav-menu"}>
                <li className="nav-item">
                  <NavLink exact to="/" activeClassName="active" className="nav-links" onClick={handleClick}>
                    Home
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/navbar/options/about-us"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    About Us
                  </NavLink>
                </li>
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/navbar/options/my-bookings"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    My Bookings
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    exact
                    to="/navbar/options/shop"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Shop
                  </NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink
                    exact
                    to="/navbar/options/vendor-login/service-vendor-registration"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Become a Partner
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    exact
                    to="/navbar/options/careers"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Careers
                  </NavLink>
                </li> */}
                {/* <li className="nav-item">
                  <NavLink
                    exact
                    to="/navbar/options/contact-us"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Contact Us
                  </NavLink>
                </li> */}
                <LoginHandle />
              </ul>
            </Box>
            <div className="nav-icon" onClick={handleClick}>
              {click ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
            </div>
          </div>
        </Box>
      </nav>
    </>
  );
}

export default NavBar;
