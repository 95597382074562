import React, { useState, useEffect } from "react";
import { Container, Link, Box } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import { CardContent } from "@material-ui/core";
import CatExplain from "./CatExplain";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  item2: {
    order: 3,
    [theme.breakpoints.up("md")]: {
      order: 2,
    },
  },
  item3: {
    order: 2,
    [theme.breakpoints.up("md")]: {
      order: 3,
    },
  },
}));

function CategoryPageBody() {
  const classes = useStyles();
  const [subCategoryList, setSubCategoryList] = useState([]);
  const { catSlug } = useParams();

  console.log(catSlug);

  useEffect(() => {
    axios
      .get("https://admin.hibroservices.com/api/services/getAllSubCategories?categorySlug=" + catSlug)
      .then((res) => {
        setSubCategoryList(res.data.result);
      });
  }, [catSlug]);

  return (
    <Container>
      <Grid container spacing={3} justify="flex-start">
        <Grid item md={7} className={classes.item2}>
          <CatExplain />
        </Grid>
        <Grid item md={5} style={{ width: "100%" }} className={classes.item3}>
          <Box display="flex" justifyContent="center">
            <div className="fixedSubCategoryBox">
              <Card className="fixedSubCategoryCard">
                <CardContent>
                  <Typography variant="h5" component="h1" className="subCategoryCardHeading">
                    Need a Service Person for:
                  </Typography>
                  <Typography variant="p" component="p" className="subCategoryCardSubHeading">
                    (Scroll Down to Show More)
                  </Typography>
                  {subCategoryList.map((subCategory) => (
                    <Link
                      color="inherit"
                      underline="none"
                      href={catSlug + "/" + subCategory.serviceSubCategorySlug}
                      key={subCategory.serviceSubCategoryId}
                    >
                      {/* <div class="arrow">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                      </div> */}
                      <Card className="subCategoryCard">
                        <Typography className="title" component="p">
                          {subCategory.serviceSubCategoryName}
                        </Typography>
                      </Card>
                    </Link>
                  ))}
                </CardContent>
              </Card>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default CategoryPageBody;
