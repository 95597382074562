import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import axios from "axios";
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginBottom: 50,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: "75%",
    flexShrink: 0,
    fontFamily: "roboto",
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

export default function ControlledAccordions(props) {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const [categoryInfo, setCategoryInfo] = useState({});
  const { catSlug } = useParams();

  useEffect(() => {
    axios.get("https://admin.hibroservices.com/api/services/categoryBySlug/" + catSlug).then((res) => {
      setCategoryInfo(res.data.result);
    });
  }, [catSlug]);

  if (!categoryInfo.faq) {
    return <span>Loading...</span>;
  }

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      <h1 style={{ paddingBottom: "20px" }} className="oswald">
        <span style={{ color: "#0066FF" }}>FREQUENTLY ASKED</span>
        <span style={{ color: "#000000" }}> QUESTIONS</span>
      </h1>
      <div>
        {categoryInfo.faq.map((info, i) => (
          <Accordion expanded={expanded === "panel" + i} onChange={handleChange("panel" + i)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1bh-content" id="panel1bh-header">
              <Typography className={classes.heading}>{info.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="roboto">{info.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
}
