import React, { useState, useEffect } from "react";
import Faq from "./Faq";
import { Box, Typography, CardContent, Card } from "@material-ui/core";
import axios from "axios";
import { useParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    marginBottom: 30,
  },
  title: {
    fontSize: 14,
  },
});

function CategoryExplain() {
  const classes = useStyles();
  const [categoryInfo, setCategoryInfo] = useState({});
  const { catSlug } = useParams();

  useEffect(() => {
    axios.get("https://admin.hibroservices.com/api/services/categoryBySlug/" + catSlug).then((res) => {
      setCategoryInfo(res.data.result);
    });
  }, [catSlug]);

  if (!categoryInfo.faq) {
    return <span>Loading...</span>;
  }

  return (
    <div>
      <div
        className="categoryDescription"
        dangerouslySetInnerHTML={{
          __html: categoryInfo.description,
        }}
      />
      <Faq />
      <div>
        <h1 style={{ paddingBottom: "30px" }} className="oswald">
          <span style={{ color: "#0066FF" }}>LATEST CUSTOMER</span>
          <span style={{ color: "#000000" }}> REVIEWS</span>
        </h1>
        {categoryInfo.reviews.map((info, i) => (
          <Card className={classes.root} variant="outlined">
            <CardContent>
              <Box display="flex" alignItems="center" className="custom-rev" key={info.reviewId}>
                <Box flexGrow={1} order={1}>
                  <Typography variant="h5" component="h2" gutterBottom>
                    {info.clientName}
                  </Typography>
                </Box>
                <Box pr={3} order={2}>
                  <Typography variant="body1" component="h2" gutterBottom>
                    4.7 <span>⭐</span>
                  </Typography>
                </Box>
                <Box order={3}>
                  <Typography variant="body1" component="h2" gutterBottom>
                    MAR 2021
                  </Typography>
                </Box>
              </Box>
              <Typography color="textSecondary">{info.reviewMessage}</Typography>
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
    //   <p style={{ color: "#666666", paddingBottom: "50px" }} className="roboto">
    //     'Top Reviews' lists the most relevant service reviews only.{" "}
    //     <span style={{ color: "#0066FF" }}>Show ALL </span>
    //     instead?
    //   </p>
    //   <CustomerReview />
    //   <CustomerReview />
    //   <CustomerReview />
    //   <CustomerReview />
    // </div>
  );
}

export default CategoryExplain;
