import React from "react";
import {
  Typography,
  Card,
  CardContent,
  Box,
  Divider,
  Container,
  Link,
  CssBaseline,
  Checkbox,
  FormControlLabel,
  CardHeader,
} from "@material-ui/core";
import { useState, useEffect } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import { useForm } from "react-hook-form";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import moment from "moment";
import LogoImg from "../../../Images/hii.png";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(3),
  },
  form: {
    // width: "100%", // Fix IE 11 issue.
    margin: theme.spacing(3),
  },
  submit: {
    // margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0066ff",
    color: "#fff",
    width: "25%",
  },
  submit2: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0066ff",
    color: "#fff",
  },
}));

function CartBox(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { register, handleSubmit } = useForm();
  const { register: register1, handleSubmit: handleSubmit1 } = useForm();
  const { register: register2, handleSubmit: handleSubmit2 } = useForm();
  const { register: register3, handleSubmit: handleSubmit3 } = useForm();
  const [tokenStatus, setTokenStatus] = useState(false);
  const [mapCoordinates, setMapCoordinates] = useState("");
  const [showExistingAddressDialog, setShowExistingAddressDialog] = useState("none");
  const [showAddressDialog, setShowAddressDialog] = useState("none");
  const [showDateDialog, setShowDateDialog] = useState("none");
  const [showPaymentDialog, setShowPaymentDialog] = useState("none");
  const [bookingTicketStatus, setBookingTicketStatus] = useState("none");
  const [bookingId, setBookingId] = useState("");
  const [cartLength, setCartLength] = useState("");
  const [showMobileNumberTab, setShowMobileNumberTab] = useState("block");
  const [showOtpTab, setShowOtpTab] = useState("none");
  const [showSignupTab, setShowSignupTab] = useState("none");
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");
  const [checked, setChecked] = React.useState(false);
  const [existingAddress, setExistingAddress] = useState([]);
  const [isNewAddress, setIsNewAddress] = useState(true);
  const [oldAddress, setOldAddress] = useState("");
  const [oldPincode, setOldPincode] = useState("");
  const [oldMapCoords, setOldMapCoords] = useState("");
  const [oldAddressError, setOldAddressError] = useState("");
  const [dateTimeError, setDateTimeError] = useState("");
  const [paymentMode, setPaymentMode] = useState(0);
  const [paymentPage, setPaymentPage] = useState("none");
  const [serviceRequestId, setServiceRequestId] = useState(0);
  const [bookings, setBookings] = useState([]);

  // const handleClickOpenLogin = () => {
  //   tokenStatusLogin ? cleartoken() : openInitialDialog();
  // };

  // function openInitialDialog() {
  //   setOpenLogin(true);
  //   setShowMobileNumberTab("block");
  //   setShowOtpTab("none");
  //   setShowSignupTab("none");
  //   setMessage("");
  // }

  // function cleartoken() {
  //   localStorage.clear();
  //   setTokenStatusLogin(null);
  //   setMessage("");
  // }

  const handleCloseLogin = () => {
    setOpenLogin(false);
  };

  // let name;
  // if (tokenStatusLogin) name = localStorage.getItem("name");

  useEffect(() => {
    setTokenStatus(localStorage.getItem("token"));
  }, [tokenStatus]);

  const handleClickOpen = () => {
    console.log("checkout clicked");
    if (localStorage.getItem("cartLength") > 0) {
      setCartLength("");
      if (localStorage.getItem("token")) {
        let headers = {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        };
        axios.get("https://admin.hibroservices.com/api/serviceRequest/getAllAddress", { headers }).then((res) => {
          console.log(res);
          setExistingAddress(res.data.result);
          if (res.data.result.length === 0) {
            setOpen(true);
            setShowExistingAddressDialog("none");
            setShowAddressDialog("block");
            setShowDateDialog("none");
            setShowPaymentDialog("none");
            setBookingTicketStatus("none");
            setPaymentPage("none");
          } else {
            setOpen(true);
            setShowExistingAddressDialog("block");
            setShowAddressDialog("none");
            setShowDateDialog("none");
            setShowPaymentDialog("none");
            setBookingTicketStatus("none");
            setPaymentPage("none");
          }
        });
      } else {
        setOpenLogin(true);
        setShowMobileNumberTab("block");
        setShowOtpTab("none");
        setShowSignupTab("none");
        setMessage("");
        localStorage.setItem("loginOpen", "true");
      }
    } else {
      setCartLength("Your cart is empty. Please select atleast one service to checkout");
    }
  };

  const handleClose = () => {
    setOpen(false);
    setShowDateDialog("none");
    setShowAddressDialog("none");
    setShowPaymentDialog("none");
    setBookingTicketStatus("block");
    setPaymentPage("none");
  };

  const handleDateAndTimePopupOpen1 = () => {
    setShowDateDialog("block");
    setShowExistingAddressDialog("none");
    setShowAddressDialog("none");
    setShowPaymentDialog("none");
    setBookingTicketStatus("none");
    setPaymentPage("none");
  };

  const handleDateAndTimePopupOpen = () => {
    if (oldAddress === "") {
      setOldAddressError("Select a Address or Click New Address button");
    } else {
      setShowDateDialog("block");
      setShowExistingAddressDialog("none");
      setShowAddressDialog("none");
      setShowPaymentDialog("none");
      setBookingTicketStatus("none");
      setPaymentPage("none");
    }
  };

  const [dateTime, setDateTime] = useState("");

  const handlePaymentPopupOpen = () => {
    if (selectedDay.value) {
      if (selectedTime.time) {
        let temp = selectedDay.value + " " + selectedTime.time;
        setDateTime(moment(temp, "DD-MM-YYYY hh:mm a").format());
        setShowDateDialog("none");
        setShowAddressDialog("none");
        setShowPaymentDialog("block");
        setBookingTicketStatus("none");
        setPaymentPage("none");
      } else {
        setDateTimeError("Please Select Time of Service");
      }
    } else {
      setDateTimeError("Please Select Date of Service");
    }
  };

  const [currentPosition, setCurrentPosition] = useState({});

  const success = (position) => {
    // console.log(position);
    const currentPosition = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    setCurrentPosition(currentPosition);
    // console.log(currentPosition);
    setMapCoordinates(currentPosition.lat + "," + currentPosition.lng);
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(success);
  });

  const mapStyles = {
    height: "350px",
    width: "100%",
  };

  const onMarkerDragEnd = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setCurrentPosition({ lat, lng });
    // console.log(currentPosition);
  };

  let listOfDays = [];
  for (let i = 0; i < 12; i++) {
    let curDate = moment().add(i, "days");
    listOfDays.push({
      date: curDate.format("DD"),
      day: curDate.format("ddd"),
      value: curDate.format("DD-MM-YYYY"),
    });
  }

  const [selectedDay, setSelectedDay] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [selectedTime, setSelectedTime] = useState({});
  const [listOfHours, setListOfHours] = useState([]);

  function timeSlots(date = null) {
    console.log(date);
    let startTime;
    if (date) {
      startTime = moment(date, "DD-MM-YYYY").hour(9).minute(0).second(0);
    } else {
      startTime = moment().hour(9).minute(0).second(0);
    }
    setListOfHours([]);
    let temp = [];
    for (let i = 0; i < 11; i++) {
      temp.push({
        display: startTime.format("h A"),
        time: startTime.format("hh:mm a"),
        isEnabled: startTime.isSameOrAfter(moment().add(3, "h")),
      });
      console.log(startTime.isSameOrAfter(moment().add(3, "h")));
      startTime.add(1, "h");
    }
    setListOfHours(temp);
  }

  useEffect(() => {
    timeSlots();
  }, []);

  const handleChangeForCheckBox = (event) => {
    setChecked(event.target.checked);
  };

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function paymentInitialize() {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const result = await axios.post(
      "https://admin.hibroservices.com/api/serviceRequest/initPayment",
      { requestId: serviceRequestId },
      { headers }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    console.log(result);

    const respond = result.data.result;

    if (result.data.success) {
      setPaymentPage("none");
      setOpen(false);
      const options = {
        key: respond.key,
        amount: respond.amount,
        currency: respond.currency,
        name: respond.name,
        description: respond.description,
        image: LogoImg,
        order_id: respond.id,
        handler: async function (response) {
          console.log(response);
          const data = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            requestId: serviceRequestId,
          };

          const resultSuccess = await axios.post(
            "https://admin.hibroservices.com/api/serviceRequest/updatePaymentDetails/success",
            data,
            { headers }
          );
          console.log(resultSuccess);
          setOpen(true);
          setShowExistingAddressDialog("none");
          setShowAddressDialog("none");
          setShowDateDialog("none");
          setShowPaymentDialog("none");
          setBookingTicketStatus("block");
          setPaymentPage("none");
        },
        prefill: {
          name: respond.userName,
          email: "example@gmail.com",
          contact: respond.phoneNumber,
        },
        notes: {
          address: "Hibro Corporate Office",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        console.log(response);
        axios
          .post(
            "https://admin.hibroservices.com/api/serviceRequest/updatePaymentDetails/failure",
            {
              requestId: serviceRequestId,
              razorpayOrderId: response.error.metadata.order_id,
              errorDetails: {
                code: response.error.code,
                description: response.error.description,
                source: response.error.source,
                step: response.error.step,
                reason: response.error.reason,
                metadata: {
                  payment_id: response.error.metadata.payment_id,
                  order_id: response.error.metadata.order_id,
                },
              },
            },
            { headers }
          )
          .then(
            (responseError) => {
              console.log(responseError);
              if (responseError.data.success === false) {
                setOpen(true);
                setShowExistingAddressDialog("none");
                setShowAddressDialog("none");
                setShowDateDialog("none");
                setShowPaymentDialog("none");
                setBookingTicketStatus("block");
                setPaymentPage("none");
              }
            },
            (error) => {
              console.log(error);
            }
          );
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });
      paymentObject.open();
    }
  }

  function cartForOnline() {
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    let token = localStorage.getItem("token");
    if (token) {
      axios.get("https://admin.hibroservices.com/api/serviceRequest/getMyBookings/upcoming", { headers }).then(
        (response) => {
          let resu = response.data.result;
          console.log(resu);
          for (let i = 0; i < resu.length; i++) {
            let sum = 19;
            console.log(resu[i].serviceOptin);
            for (let j = 0; j < resu[i].serviceOptin.length; j++) {
              sum += resu[i].serviceOptin[j]["serviceId"]["serviceChargeMin"];
            }
            resu[i]["totalAmount"] = sum;
            sum = 19;
          }
          console.log(resu);
          setBookings(resu);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  return (
    <div>
      <Card variant="contained" elevation={10} style={{ minWidth: "325px" }} className="CartSize">
        <CardContent>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h6" display="inline" justify="flex-end" style={{ marginBottom: "10px" }}>
              No. of Items: {props.totalNumberOfService}
            </Typography>
          </Box>
          <Divider />
          <div>{props.cartSingle}</div>

          <div style={{ height: "100%", padding: "10px 0px" }}>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} alignItems="center">
                <Typography display="inline">Booking Charge</Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <Typography display="inline" style={{ marginRight: "34px" }}>
                  ₹19
                </Typography>
              </Box>
            </Box>
          </div>
          <Divider />
          <Box display="flex" alignItems="center" style={{ marginTop: "20px" }}>
            <Box flexGrow={1}>
              <Typography variant="h5" component="h2" style={{ color: "#0066ff" }}>
                Total
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="h5" display="inline" style={{ marginLeft: "10px" }}>
                ₹{props.totalPrice}
              </Typography>
            </Box>
          </Box>
          <Typography style={{ color: "red" }}>{props.alert}</Typography>
          <Typography style={{ color: "red" }}>{cartLength}</Typography>
        </CardContent>
        <div>
          <div>
            <Box
              className={classes.paper}
              style={{ margin: "0px 16px 16px 16px" }}
              display="flex"
              justifyContent="flex-end"
            >
              <Button
                variant="contained"
                m={1}
                onClick={handleClickOpen}
                style={{ backgroundColor: "#0066ff", color: "#fff" }}
              >
                CHECKOUT
              </Button>
            </Box>
          </div>
        </div>
      </Card>

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
        onBackdropClick="false"
      >
        <form
          key={1}
          className={classes.form}
          noValidate
          onSubmit={handleSubmit((data) => {
            let headers = {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            };
            axios
              .post(
                "https://admin.hibroservices.com/api/serviceRequest/addNew",
                {
                  address: isNewAddress ? data.address : oldAddress,
                  mapCoords: isNewAddress ? mapCoordinates : oldMapCoords,
                  serviceDateTime: dateTime,
                  pincode: isNewAddress ? data.pincode : oldPincode,
                  serviceOptIn: JSON.parse(localStorage.getItem("servicesOptIn")),
                  paymentMode: paymentMode,
                  saveAddress: isNewAddress ? checked : false,
                  serviceCatId: localStorage.getItem("serviceCatId"),
                },
                { headers }
              )
              .then(
                (response) => {
                  console.log(response);
                  if (response.data.success) {
                    setServiceRequestId(response.data.result.serviceRequestId);
                    if (response.data.result.paymentMode === 1) {
                      setBookingId(response.data.result.requestOrderNumber);
                      setShowDateDialog("none");
                      setShowAddressDialog("none");
                      setShowPaymentDialog("none");
                      setBookingTicketStatus("block");
                      setPaymentPage("none");
                    } else {
                      setBookingId(response.data.result.requestOrderNumber);
                      setShowDateDialog("none");
                      setShowAddressDialog("none");
                      setShowPaymentDialog("none");
                      setBookingTicketStatus("none");
                      setPaymentPage("block");
                      cartForOnline();
                    }
                  } else {
                    alert(response.data.message);
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
          })}
        >
          <Box display={showExistingAddressDialog} className="width500px">
            <Grid container>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-start" m={1} p={1}>
                  <KeyboardBackspaceIcon onClick={handleClose} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box display="flex" justifyContent="center">
                  <DialogTitle id="form-dialog-title">Choose Address</DialogTitle>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                  <HighlightOffIcon onClick={handleClose} />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <div style={{ minHeight: "400px" }}>
              {existingAddress.map((address, index) => (
                <Card
                  className={selectedAddress.address === address.address ? "addressCardActive" : "addressCard"}
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    setSelectedAddress(address);
                    console.log(address.address + " " + address.pincode + " " + address.mapCoords);
                    setIsNewAddress(false);
                    setOldAddress(address.address);
                    setOldPincode(address.pincode);
                    setOldMapCoords(address.mapCoords);
                    setOldAddressError("");
                  }}
                >
                  <CardContent>
                    <Typography variant="body1">Address: {address.address}</Typography>
                    <Typography variant="body1">Pincode: {address.pincode}</Typography>
                  </CardContent>
                </Card>
              ))}
              <Typography variant="h6" align="center" style={{ margin: "20px" }}>
                Or
              </Typography>
              <Button
                variant="contained"
                fullWidth={true}
                style={{ backgroundColor: "#0066ff", color: "#fff", marginBottom: "30px" }}
                onClick={() => {
                  setShowExistingAddressDialog("none");
                  setShowAddressDialog("block");
                  setIsNewAddress(true);
                }}
              >
                Add New Address
              </Button>
            </div>
            <DialogActions>
              <Typography align="center" style={{ color: "red" }}>
                {oldAddressError}
              </Typography>
              <Button onClick={handleClose} style={{ color: "#0066ff" }}>
                Cancel
              </Button>
              <div>
                <Button
                  style={{ backgroundColor: "#0066FF", color: "white" }}
                  variant="contained"
                  onClick={handleDateAndTimePopupOpen}
                >
                  Continue
                </Button>
              </div>
            </DialogActions>
            {/* <DateAndTimePopup /> */}
          </Box>
          <Box display={showAddressDialog} className="width500px">
            <Grid container>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-start" m={1} p={1}>
                  <KeyboardBackspaceIcon onClick={handleClose} />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box display="flex" justifyContent="center">
                  <DialogTitle id="form-dialog-title">Enter Your Address</DialogTitle>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                  <HighlightOffIcon onClick={handleClose} />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <TextField
              variant="outlined"
              margin="normal"
              multiline
              inputRef={register}
              required
              fullWidth
              name="address"
              label="Enter Your Address"
              type="address"
              id="address"
              autoComplete="address"
            />
            <TextField
              variant="outlined"
              margin="normal"
              inputRef={register}
              required
              fullWidth
              name="pincode"
              label="Enter Your Pincode"
              type="number"
              id="pincode"
              autoComplete="postal code"
            />
            <FormControlLabel
              control={<Checkbox checked={checked} onChange={handleChangeForCheckBox} style={{ color: "#3a3939" }} />}
              label="Save this address for future purpose"
            />

            <h1>{props.serviceItemId}</h1>
            <LoadScript googleMapsApiKey="AIzaSyBJxvWdRx_sBEpDRogHNHw7kWgLb5wVP3M">
              <GoogleMap mapContainerStyle={mapStyles} zoom={13} center={currentPosition}>
                {currentPosition.lat ? (
                  <Marker position={currentPosition} onDragEnd={(e) => onMarkerDragEnd(e)} draggable={true} />
                ) : null}
              </GoogleMap>
            </LoadScript>

            <DialogActions>
              <Button onClick={handleClose} style={{ color: "#0066ff" }}>
                Cancel
              </Button>
              <div>
                <Button
                  style={{ backgroundColor: "#0066FF", color: "white" }}
                  variant="contained"
                  onClick={handleDateAndTimePopupOpen1}
                >
                  Continue
                </Button>
              </div>
            </DialogActions>
            {/* <DateAndTimePopup /> */}
          </Box>
          <Box display={showDateDialog} className="width500px">
            <Grid container>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-start" m={1} p={1}>
                  <KeyboardBackspaceIcon
                    onClick={() => {
                      if (isNewAddress) {
                        setShowExistingAddressDialog("none");
                        setShowAddressDialog("block");
                        setShowDateDialog("none");
                      } else {
                        setShowExistingAddressDialog("block");
                        setShowAddressDialog("none");
                        setShowDateDialog("none");
                      }
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box display="flex" justifyContent="center">
                  <DialogTitle id="form-dialog-title">Schedule of Service</DialogTitle>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                  <HighlightOffIcon onClick={handleClose} />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <DialogContent>
              <DialogContentText>Select Date of Service</DialogContentText>
              <div>
                <Box mb={3}>
                  <Grid container spacing={2}>
                    {listOfDays.map((day) => (
                      <Grid item md={2} sm={4} xs={4}>
                        <Card
                          className={selectedDay.date === day.date ? "cardActive" : "card"}
                          onClick={() => {
                            setSelectedDay(day);
                            timeSlots(day.value);
                            setDateTimeError("");
                          }}
                          style={{ height: "100%" }}
                        >
                          <CardContent>
                            <Typography align="center">{day.day}</Typography>
                            <Typography align="center">{day.date}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </div>
              <DialogContentText>Select Time of Service</DialogContentText>
              <div>
                <Box mb={3}>
                  <Grid container spacing={2}>
                    {listOfHours.map((hour) => (
                      <Grid item md={3} sm={4} xs={4}>
                        <Card
                          className={
                            hour.isEnabled ? (selectedTime.time === hour.time ? "cardActive" : "card") : "cardDisable"
                          }
                          style={{ height: "100%" }}
                          onClick={() => {
                            setSelectedTime(hour);
                            setDateTimeError("");
                          }}
                        >
                          <CardContent>
                            <Typography align="center">{hour.display}</Typography>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </div>
            </DialogContent>
            <DialogActions>
              <Typography align="center" style={{ color: "red" }}>
                {dateTimeError}
              </Typography>
              <Button autoFocus onClick={handleClose} style={{ color: "#0066FF" }}>
                Cancel
              </Button>
              <Button
                style={{ backgroundColor: "#0066FF", color: "white" }}
                variant="contained"
                onClick={handlePaymentPopupOpen}
              >
                Continue
              </Button>
            </DialogActions>
          </Box>
          <Box display={showPaymentDialog} className="width500px">
            <Grid container>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-start" m={1} p={1}>
                  <KeyboardBackspaceIcon
                    onClick={() => {
                      setShowPaymentDialog("none");
                      setShowDateDialog("block");
                    }}
                  />
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box display="flex" justifyContent="center">
                  <DialogTitle id="form-dialog-title">Payment Mode</DialogTitle>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                  <HighlightOffIcon onClick={handleClose} />
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <div style={{ margin: "150px 0px" }}>
              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  className="blueButton mb-4 p-4"
                  onClick={() => {
                    setPaymentMode(2);
                  }}
                >
                  Online Payment
                </Button>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  type="submit"
                  variant="contained"
                  className="blueButton p-4"
                  onClick={() => {
                    setPaymentMode(1);
                  }}
                >
                  Cash On Delivery
                </Button>
              </Box>
            </div>
            <DialogActions>
              <Button onClick={handleClose} style={{ color: "#0066ff" }}>
                Cancel
              </Button>
            </DialogActions>
          </Box>
          <Box display={paymentPage} className="width500px" style={{ textAlign: "center", minHeight: "400px" }}>
            {bookings.map((booking, index) => (
              <div key={booking.requestOrderNumber}>
                {booking.requestOrderNumber === bookingId && (
                  <Card
                    key={booking.requestOrderNumber}
                    id={booking.requestOrderNumber}
                    style={{ marginBottom: "20px" }}
                  >
                    <CardHeader
                      style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                      title={
                        <Typography gutterBottom variant="h5" component="h2" noWrap>
                          SERVICE CART
                        </Typography>
                      }
                    />
                    <CardContent>
                      {booking.serviceOptin.map((serviceOpt, index) => (
                        <div key={serviceOpt.serviceId.serviceId}>
                          <Box display="flex" alignItems="center">
                            <Box flexGrow={1} alignItems="center">
                              <Typography gutterBottom variant="h6" component="h2" display="inline">
                                {serviceOpt.serviceId.serviceName}
                              </Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                              <Typography
                                gutterBottom
                                variant="h6"
                                component="h2"
                                display="inline"
                                style={{ marginRight: "20px" }}
                              >
                                ₹{serviceOpt.serviceId.serviceChargeMin}
                              </Typography>
                            </Box>
                          </Box>
                          <Divider />
                        </div>
                      ))}
                      <Box display="flex" alignItems="center">
                        <Box flexGrow={1} alignItems="center">
                          <Typography gutterBottom variant="h6" component="h2" display="inline">
                            Booking Charge
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="h2"
                            display="inline"
                            style={{ marginRight: "20px" }}
                          >
                            ₹19
                          </Typography>
                        </Box>
                      </Box>
                      <Divider />
                      <Box display="flex" alignItems="center">
                        <Box flexGrow={1} alignItems="center">
                          <Typography gutterBottom variant="h6" component="h2" display="inline">
                            Total Amount
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Typography
                            gutterBottom
                            variant="h6"
                            component="h2"
                            display="inline"
                            style={{ marginRight: "20px" }}
                          >
                            ₹{booking.totalAmount}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </Card>
                )}
              </div>
            ))}
            <Button
              variant="contained"
              style={{ backgroundColor: "#0066ff", color: "#fff" }}
              onClick={() => {
                paymentInitialize();
              }}
            >
              Pay Via Online
            </Button>
          </Box>
          <Box display={bookingTicketStatus} className="width500px">
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <DialogTitle id="form-dialog-title">Booking Confirmation</DialogTitle>
                </Box>
              </Grid>
            </Grid>
            <Divider />
            <div style={{ margin: "150px 0px" }}>
              <Box display="flex" justifyContent="center">
                <h3 style={{ fontFamily: "roboto" }} className="mb-4">
                  Your Booking was Confirmed. Booking ID is {bookingId}
                </h3>
              </Box>
              <Box display="flex" justifyContent="center">
                <Button
                  className="blueButton"
                  variant="contained"
                  style={{ fontFamily: "roboto" }}
                  href={"/navbar/options/my-bookings/upcoming/" + bookingId}
                >
                  Go to Booking Ticket
                </Button>
              </Box>
            </div>
          </Box>
        </form>

        {/* <Dialog
                fullScreen={fullScreen}
                open={openDate}
                onClose={handleDateAndTimeClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth
              >
                */}
      </Dialog>

      <div className="Roboto">
        {/* <li
        className="nav-item"
        onClick={handleClickOpen}
        style={{
          color: "#fff",
          fontFamily: "Roboto",
          fontWeight: "400",
          cursor: "pointer",
          width: "100%",
          textAlign: "center",
        }}
      >
        {tokenStatus ? "Logout - " + name : "Login/Signup"}
      </li> */}
        <Dialog open={openLogin} onClose={handleCloseLogin} aria-labelledby="form-dialog-title" onBackdropClick="false">
          <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
              <Grid container>
                <Grid item xs={2}></Grid>
                <Grid item xs={8}>
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <div className={classes.avatar}>
                      <img src={LogoImg} alt="Hibro Logo" style={{ width: "200px" }} />
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={2}>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ height: "100%", marginLeft: "50px" }}
                    m={1}
                    p={1}
                  >
                    <HighlightOffIcon onClick={handleCloseLogin} />
                  </Box>
                </Grid>
              </Grid>
              <Typography component="h1" variant="h5" style={{ color: "#0066ff", fontWeight: 500, marginTop: "25px" }}>
                LOG IN
              </Typography>
              <Box display={showMobileNumberTab}>
                <form
                  key={1}
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit1((data) => {
                    axios
                      .post("https://admin.hibroservices.com/api/auth/customer/signIn", {
                        phoneNumber: data.tel,
                      })
                      .then(
                        (response) => {
                          console.log(response);
                          setMessage(response.data.result.otp);
                          setShowMobileNumberTab(response.data.success ? "none" : "none");
                          setShowOtpTab(response.data.success ? "block" : "none");
                          setShowSignupTab(response.data.success ? "none" : "block");
                          setMobileNumber(response.data.success ? response.data.result.phoneNumber : data.tel);
                        },
                        (error) => {
                          console.log(error);
                        }
                      );
                  })}
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    inputRef={register1}
                    required
                    fullWidth
                    name="tel"
                    label="Mobile Number"
                    type="tel"
                    id="tel"
                  />
                  <Button type="submit" fullWidth variant="contained" className={classes.submit2}>
                    GET OTP
                  </Button>
                  <Typography>{message}</Typography>
                </form>
              </Box>
              <Box display={showOtpTab}>
                <form
                  key={2}
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit2((data) => {
                    axios
                      .post("https://admin.hibroservices.com/api/auth/customer/signInVerification", {
                        phoneNumber: data.tel,
                        otp: data.otp,
                      })
                      .then(
                        (response) => {
                          console.log(response);

                          if (response.data.success) {
                            setMessage(response.data.message);
                            localStorage.setItem("token", response.data.result.token);
                            localStorage.setItem("name", response.data.result.user.userName);
                            localStorage.setItem("phoneNumber", response.data.result.user.phoneNumber);
                            setOpenLogin(false);
                            let headers = {
                              Authorization: `Bearer ${localStorage.getItem("token")}`,
                            };
                            axios
                              .get("https://admin.hibroservices.com/api/serviceRequest/getAllAddress", {
                                headers,
                              })
                              .then((res) => {
                                console.log(res);
                                setExistingAddress(res.data.result);
                                if (res.data.result.length === 0) {
                                  setOpen(true);
                                  setShowExistingAddressDialog("none");
                                  setShowAddressDialog("block");
                                  setShowDateDialog("none");
                                  setShowPaymentDialog("none");
                                  setBookingTicketStatus("none");
                                } else {
                                  setOpen(true);
                                  setShowExistingAddressDialog("block");
                                  setShowAddressDialog("none");
                                  setShowDateDialog("none");
                                  setShowPaymentDialog("none");
                                  setBookingTicketStatus("none");
                                }
                              });
                            // setHideMobile(response.data.success ? "none" : "block");
                            // setHideOtp(response.data.success ? "block" : "none");
                          } else {
                            setMessage("OTP doesn't match. Try Again!");
                          }
                        },
                        (error) => {
                          console.log(error);
                        }
                      );
                  })}
                >
                  <Box display="none">
                    <TextField
                      variant="outlined"
                      margin="normal"
                      inputRef={register2}
                      required
                      fullWidth
                      name="tel"
                      label="Mobile Number"
                      type="tel"
                      id="tel"
                      autoComplete="tel"
                      value={mobileNumber}
                    />
                  </Box>
                  <h3 style={{ textAlign: "center", margin: "20px 0px", color: "#0066ff" }}>
                    OTP has been sent to {mobileNumber}
                  </h3>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    inputRef={register2}
                    required={true}
                    fullWidth
                    id="otp"
                    label="One Time Password"
                    type="otp"
                    name="otp"
                    autoFocus
                    autoComplete="none"
                  />
                  <Button type="submit" fullWidth variant="contained" className={classes.submit2}>
                    SUBMIT OTP
                  </Button>
                  <Typography>{message}</Typography>
                  <Link href="#" variant="body2" onClick={handleClickOpen}>
                    Resend OTP?
                  </Link>
                </form>
              </Box>
              <Box display={showSignupTab}>
                <form
                  key={3}
                  className={classes.form}
                  noValidate
                  onSubmit={handleSubmit3((data) => {
                    axios
                      .post("https://admin.hibroservices.com/api/auth/customer/signUp", {
                        phoneNumber: data.tel,
                        userName: data.name,
                      })
                      .then(
                        (response) => {
                          console.log(response);
                          setMessage(response.data.message);
                          setShowMobileNumberTab(response.data.success ? "none" : "none");
                          setShowOtpTab(response.data.success ? "block" : "none");
                          setShowSignupTab(response.data.success ? "none" : "block");
                        },
                        (error) => {
                          console.log(error);
                        }
                      );
                  })}
                >
                  <h3 style={{ textAlign: "center", margin: "20px 0px", color: "#0066ff" }}>
                    Your name not exist in HiBro. Please enter your name and continue.
                  </h3>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    inputRef={register3}
                    required
                    fullWidth
                    id="name"
                    label="Enter Your Name"
                    name="name"
                    type="name"
                    autoComplete="name"
                    autoFocus
                  />
                  <TextField
                    style={{ display: "none" }}
                    variant="outlined"
                    margin="normal"
                    inputRef={register3}
                    required
                    fullWidth
                    name="tel"
                    label="Mobile Number"
                    type="tel"
                    id="tel"
                    autoComplete="tel"
                    value={mobileNumber}
                  />
                  <Button type="submit" fullWidth variant="contained" className={classes.submit2}>
                    REGISTER NOW
                  </Button>
                  <Typography>{message}</Typography>
                  <Link href="#" variant="body2" onClick={handleClickOpen}>
                    Already have an account
                  </Link>
                </form>
              </Box>
            </div>
          </Container>
        </Dialog>
      </div>
    </div>
  );
}

export default CartBox;
