import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Container, Link, Box, Grid } from "@material-ui/core";
import { useForm } from "react-hook-form";
import LogoImg from "../../Images/hii.png";
import axios from "axios";
import Dialog from "@material-ui/core/Dialog";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    backgroundColor: "#0066ff",
    color: "#fff",
  },
}));

export default function LoginHandle(props) {
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const { register: register2, handleSubmit: handleSubmit2 } = useForm();
  const { register: register3, handleSubmit: handleSubmit3 } = useForm();
  const [open, setOpen] = useState(false);
  const [showMobileNumberTab, setShowMobileNumberTab] = useState("block");
  const [showOtpTab, setShowOtpTab] = useState("none");
  const [showSignupTab, setShowSignupTab] = useState("none");
  const [mobileNumber, setMobileNumber] = useState("");
  const [message, setMessage] = useState("");
  const [tokenStatus, setTokenStatus] = useState(null);

  const handleClickOpen = () => {
    tokenStatus ? cleartoken() : openInitialDialog();
  };

  function openInitialDialog() {
    setOpen(true);
    setShowMobileNumberTab("block");
    setShowOtpTab("none");
    setShowSignupTab("none");
    setMessage("");
  }

  function cleartoken() {
    localStorage.removeItem("token");
    setTokenStatus(null);
    setMessage("");
  }

  function setToken() {
    setTokenStatus(localStorage.getItem("token"));
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setTokenStatus(localStorage.getItem("token"));
  }, []);

  let name;
  if (tokenStatus) name = localStorage.getItem("name");

  return (
    <div className="Roboto">
      <li
        className="nav-item"
        onClick={handleClickOpen}
        style={{
          color: "#fff",
          fontFamily: "Roboto",
          fontWeight: "400",
          cursor: "pointer",
          width: "100%",
          textAlign: "center",
        }}
      >
        {tokenStatus ? "Logout - " + name : "Login/Signup"}
      </li>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" disableBackdropClick>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <div className={classes.paper}>
            <Grid container>
              <Grid item xs={2}></Grid>
              <Grid item xs={8}>
                <Box display="flex" justifyContent="center" alignItems="center">
                  <div className={classes.avatar}>
                    <img src={LogoImg} alt="Hibro Logo" style={{ width: "200px" }} />
                  </div>
                </Box>
              </Grid>
              <Grid item xs={2}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ height: "100%", marginLeft: "50px" }}
                  m={1}
                  p={1}
                >
                  <HighlightOffIcon onClick={handleClose} />
                </Box>
              </Grid>
            </Grid>
            <Typography component="h1" variant="h5" style={{ color: "#0066ff", fontWeight: 500, marginTop: "25px" }}>
              LOG IN
            </Typography>
            <Box display={showMobileNumberTab}>
              <form
                key={1}
                className={classes.form}
                noValidate
                onSubmit={handleSubmit((data) => {
                  axios
                    .post("https://admin.hibroservices.com/api/auth/customer/signIn", {
                      phoneNumber: data.tel,
                    })
                    .then(
                      (response) => {
                        console.log(response);
                        setMessage(response.data.result.otp);
                        setShowMobileNumberTab(response.data.success ? "none" : "none");
                        setShowOtpTab(response.data.success ? "block" : "none");
                        setShowSignupTab(response.data.success ? "none" : "block");
                        setMobileNumber(response.data.success ? response.data.result.phoneNumber : data.tel);
                      },
                      (error) => {
                        console.log(error);
                      }
                    );
                })}
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  inputRef={register}
                  required
                  fullWidth
                  name="tel"
                  label="Mobile Number"
                  type="tel"
                  id="tel"
                />
                <Button type="submit" fullWidth variant="contained" className={classes.submit}>
                  GET OTP
                </Button>
                <Typography>{message}</Typography>
              </form>
            </Box>
            <Box display={showOtpTab}>
              <form
                key={2}
                className={classes.form}
                noValidate
                onSubmit={handleSubmit2((data) => {
                  axios
                    .post("https://admin.hibroservices.com/api/auth/customer/signInVerification", {
                      phoneNumber: data.tel,
                      otp: data.otp,
                    })
                    .then(
                      (response) => {
                        console.log(response);

                        if (response.data.success) {
                          setMessage(response.data.message);
                          localStorage.setItem("token", response.data.result.token);
                          localStorage.setItem("name", response.data.result.user.userName);
                          localStorage.setItem("phoneNumber", response.data.result.user.phoneNumber);
                          setOpen(false);
                          setToken();
                          // setHideMobile(response.data.success ? "none" : "block");
                          // setHideOtp(response.data.success ? "block" : "none");
                        } else {
                          setMessage("OTP doesn't match. Try Again!");
                        }
                      },
                      (error) => {
                        console.log(error);
                      }
                    );
                })}
              >
                <Box display="none">
                  <TextField
                    variant="outlined"
                    margin="normal"
                    inputRef={register2}
                    required
                    fullWidth
                    name="tel"
                    label="Mobile Number"
                    type="tel"
                    id="tel"
                    autoComplete="tel"
                    value={mobileNumber}
                  />
                </Box>
                <h3 style={{ textAlign: "center", margin: "20px 0px", color: "#0066ff" }}>
                  OTP has been sent to {mobileNumber}
                </h3>
                <TextField
                  variant="outlined"
                  margin="normal"
                  inputRef={register2}
                  required={true}
                  fullWidth
                  id="otp"
                  label="One Time Password"
                  type="otp"
                  name="otp"
                  autoFocus
                  autoComplete="none"
                />
                <Button type="submit" fullWidth variant="contained" className={classes.submit}>
                  SUBMIT OTP
                </Button>
                <Typography>{message}</Typography>
                <Link href="#" variant="body2" onClick={handleClickOpen}>
                  Resend OTP?
                </Link>
              </form>
            </Box>
            <Box display={showSignupTab}>
              <form
                key={3}
                className={classes.form}
                noValidate
                onSubmit={handleSubmit3((data) => {
                  axios
                    .post("https://admin.hibroservices.com/api/auth/customer/signUp", {
                      phoneNumber: data.tel,
                      userName: data.name,
                    })
                    .then(
                      (response) => {
                        console.log(response);
                        setMessage(response.data.message);
                        setShowMobileNumberTab(response.data.success ? "none" : "none");
                        setShowOtpTab(response.data.success ? "block" : "none");
                        setShowSignupTab(response.data.success ? "none" : "block");
                      },
                      (error) => {
                        console.log(error);
                      }
                    );
                })}
              >
                <h3 style={{ textAlign: "center", margin: "20px 0px", color: "#0066ff" }}>
                  {mobileNumber}. Your Name not exist in HiBro. Please enter your name and continue.
                </h3>
                <TextField
                  variant="outlined"
                  margin="normal"
                  inputRef={register3}
                  required
                  fullWidth
                  id="name"
                  label="Enter Your Name"
                  name="name"
                  type="name"
                  autoComplete="name"
                  autoFocus
                />
                <TextField
                  style={{ display: "none" }}
                  variant="outlined"
                  margin="normal"
                  inputRef={register3}
                  required
                  fullWidth
                  name="tel"
                  label="Mobile Number"
                  type="tel"
                  id="tel"
                  autoComplete="tel"
                  value={mobileNumber}
                />
                <Button type="submit" fullWidth variant="contained" className={classes.submit}>
                  REGISTER NOW
                </Button>
                <Typography>{message}</Typography>
                <Link href="#" variant="body2" onClick={handleClickOpen}>
                  Already have an account
                </Link>
              </form>
            </Box>
          </div>
        </Container>
      </Dialog>
    </div>
  );
}
