import React from "react";
import { Grid, Box } from "@material-ui/core";

function OurPlans() {
  return (
    <Box style={{ marginBottom: "100px" }}>
      <div>
        <h1
          style={{ marginBottom: "30px", textAlign: "center" }}
          className="oswald"
        >
          <span style={{ color: "#0066FF" }}>OUR</span>
          <span style={{ color: "#000000" }}> PLANS</span>
        </h1>
        <div className="blueAndYellow">
          <Grid container>
            <Grid
              item
              md={6}
              className="blueBack whiteText"
              style={{ width: "100%" }}
            >
              <Grid container>
                <Grid item xs={7}>
                  <h1 style={{ padding: "20px" }} className="roboto">
                    <Grid container>
                      <Grid xs={4}>
                        <Box display="flex" justifyContent="flex-end">
                          <i className="flaticon-round-info-button icons75"></i>
                        </Box>
                      </Grid>
                      <Grid xs={8}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{ height: "100%", marginLeft: 20 }}
                          className="OurPlanText"
                        >
                          GENERAL
                        </Box>
                      </Grid>
                    </Grid>
                  </h1>
                </Grid>
                <Grid item xs={5} className="roboto">
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <div style={{ marginRight: "20px" }}>
                      <h4>RS.500/2-4HRS</h4> <h5> Free support</h5>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} className="yellowBack" style={{ width: "100%" }}>
              <Grid container>
                <Grid item xs={7}>
                  <h1 style={{ padding: "20px" }} className="roboto">
                    <Grid container>
                      <Grid xs={4}>
                        <Box display="flex" justifyContent="flex-end">
                          <i className="flaticon-badge icons75" />
                        </Box>
                      </Grid>
                      <Grid xs={8}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{ height: "100%", marginLeft: 20 }}
                          className="OurPlanText"
                        >
                          PREMIUM
                        </Box>
                      </Grid>
                    </Grid>
                  </h1>
                </Grid>
                <Grid item xs={5} className="roboto">
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <div style={{ marginRight: "20px" }}>
                      <h4>RS.700/5-9HRS</h4> <h5> Free upgrades</h5>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  );
}

export default OurPlans;
