import React from "react";
import Home from "./components/HomePage/Home";
import About from "./components/NavigationBarPages/About/AboutUs";
import MyBookings from "./components/NavigationBarPages/MyBookings/MyBookings";
import ServiceVendorLogin from "./components/NavigationBarPages/VendorRegistration/ServiceVendorLogin";
import ServiceVendor from "./components/NavigationBarPages/VendorRegistration/VendorMainPage/ServiceVendorPage/ServiceVendor";
import ProductVendor from "./components/NavigationBarPages/VendorRegistration/VendorMainPage/ProductVendorPage/ProductVendor";
import Blog from "./components/NavigationBarPages/Blog/Blog";
import Careers from "./components/NavigationBarPages/Careers/Careers";
import ContactUs from "./components/NavigationBarPages/ContactUs/ContactUs";
import Category from "./components/CategoryPage/Category";
import Service from "./components/ServicePage/Service";
import TermsAndConditionsPage from "./components/TermsAndConditions/TermsAndConditionsPage";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicyPage";
import PricingPolicy from "./components/PricingPolicy/PricingPolicyPage";
import RefundPolicy from "./components/RefundAndCancellationPolicy/RefundAndCancellationPolicyPage";
import BookingTicket from "./components/NavigationBarPages/MyBookings/BookingTicket";
import BookingTicketHistory from "./components/NavigationBarPages/MyBookings/BookingTicketHistory";
import Shop from "./components/Shop/Shop";
import MyOrders from "./components/MyOrders/MyOrders";
import MyOrdersById from "./components/MyOrders/MyOrdersById";
import ShopIndividual from "./components/Shop/ShopIndividual";
import { Route, Switch } from "react-router-dom";
import Wa from "./components/Whatsapp/Wa";

function App() {
  return (
    <div>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/navbar/options/about-us" component={About} />
        <Route exact path="/navbar/options/my-bookings" component={MyBookings} />
        {/* <Route exact path="/navbar/options/vendor-login" component={ServiceVendorLogin} /> */}
        <Route exact path="/navbar/options/vendor-login/service-vendor-registration" component={ServiceVendor} />
        {/* <Route exact path="/navbar/options/vendor-login/product-vendor-registration" component={ProductVendor} /> */}
        <Route exact path="/navbar/options/blog" component={Blog} />
        <Route exact path="/navbar/options/careers" component={Careers} />
        <Route exact path="/navbar/options/contact-us" component={ContactUs} />
        <Route exact path="/navbar/options/terms-and-conditions" component={TermsAndConditionsPage} />
        <Route exact path="/navbar/options/privacy-policy" component={PrivacyPolicy} />
        <Route exact path="/navbar/options/pricing-policy" component={PricingPolicy} />
        <Route exact path="/navbar/options/refund-and-cancellation-policy" component={RefundPolicy} />
        {/* <Route exact path="/navbar/options/shop" component={Shop} /> */}
        <Route exact path="/navbar/options/shop/orders/my-orders" component={MyOrders} />
        <Route exact path="/navbar/options/shop/orders/my-orders/:orderId" component={MyOrdersById} />
        <Route exact path="/navbar/options/shop/productCategory" component={ShopIndividual} />
        <Route exact path="/:catSlug" component={Category} />
        <Route exact path="/:catSlug/:subCatSlug" component={Service} />
        <Route exact path="/navbar/options/my-bookings/upcoming/:ticketId" component={BookingTicket} />
        <Route exact path="/navbar/options/my-bookings/history/:ticketId" component={BookingTicketHistory} />
      </Switch>
      <Wa />
    </div>
  );
}

export default App;
