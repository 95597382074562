import { List, ListItem, Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";

function Rules(props) {
  return (
    <div style={{ marginBottom: "30px" }}>
      <Container style={{ fontFamily: "Roboto, sans-serif", wordSpacing: "2px" }}>
        <Typography
          variant="h5"
          component="h2"
          style={{
            color: "#0066ff",
            marginTop: "50px",
            marginBottom: "20px",
            marginRight: "50px",
            fontFamily: "Oswald, sans-serif",
            fontWeight: "bold",
            fontSize: "2em",
          }}
        >
          {props.heading} <span style={{ color: "#000" }}>{props.heading1}</span>
        </Typography>
        {props.rulesList.map((rule, index) => (
          <List key={index}>
            <ListItem style={{ fontSize: "20px" }}>{rule}</ListItem>
          </List>
        ))}
      </Container>
    </div>
  );
}

export default Rules;
