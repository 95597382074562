import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import classes from "./MyOrdersByIDTables3.module.css";

export default function MyOrderByIDTables3(props) {
  function createData(key, value) {
    return { key, value };
  }

  const rows = [
    createData("Order ID", props.orderId),
    createData("Ordered Date", props.orderedDate),
    createData("Total Price", props.totalPrice),
    createData("Overall Status", props.overallStatus),
  ];

  return (
    <TableContainer component={Paper} className={classes.MyOrdersByIDTablesContainer}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        {/* <TableHead>
          <TableRow>
            <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell>
          </TableRow>
        </TableHead> */}
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.key} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row" style={{ fontWeight: 500, fontSize: "15px" }}>
                {row.key}
              </TableCell>
              <TableCell style={{ fontWeight: 600, fontSize: "15px" }}>{row.value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
