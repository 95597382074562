import ShopHeader from "../Shop/ShopModules/ShopHeader/ShopHeader";
import MyWorkingByIdBody from "./MyOrdersByIdModules/MyOrdersByIdBody";
import Footer from "../HomePage/Footer/Footer";

function MyOrdersById() {
  return (
    <div>
      <ShopHeader />
      <MyWorkingByIdBody />
      <Footer />
    </div>
  );
}

export default MyOrdersById;
