import React from "react";
import ServiceHeader from "./ServiceHeader/ServiceHeader";
import ServicePageBody from "./ServicePageBody/ServicePageBody";
import Footer from "../HomePage/Footer/Footer";
import "./ServiceHeader/styless.css";

function Service() {
  return (
    <div>
      <ServiceHeader />
      <ServicePageBody />
      <Footer />
    </div>
  );
}

export default Service;
