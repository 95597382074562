import React from "react";
import Header from "./Header/Header";
// import Header1 from "./Header/Header1";
import CategoryBox from "./CategoryBox/CategoryBox";
import OnlineSupport from "./OtherBox/OnlineSupport";
import SuccessRate from "./OtherBox/SuccessRate";
import HowItWorks from "./OtherBox/HowItWorks";
import HappyCustomers from "./OtherBox/HappyCustomers";
import OurPlans from "./OtherBox/OurPlans";
import Footer from "./Footer/Footer";
import { Helmet } from "react-helmet";

function Home() {
  return (
    <div id="Browse">
      <Helmet>
        <meta charset="UTF-8" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="description"
          content="Hibro Online is a One stop solution for all your home maintenance needs. We provide professional service for
    Electrical,Plumbing,Painting,Carpentry requirements and also Kitchen Bathroom,Building Renovations at affordable Budget.Have a problem,Call Hibro.
    "
        />
        <meta
          name="keywords"
          content="electrician in chennai, plumber in chennai, AC technician in chennai, Home Appliances service in chennai, Civil works in chennai, Carpenter in chennai, Renovation in chennai, Cleaning services in chennai, Pest control in chennai, Gardening in chennai, Painting in chennai, AMC Services in chennai"
        />
        <meta name="author" content="Magic Menporul" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>HiBRO - Professional Home Maintanence Company</title>
      </Helmet>
      <Header />
      <CategoryBox />
      <OnlineSupport />
      <SuccessRate />
      <HowItWorks />
      <HappyCustomers />
      <OurPlans />
      <Footer />
    </div>
  );
}

export default Home;
