import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Box, Container, Typography, Button, Card, CardHeader, CardContent, Grid, Divider } from "@material-ui/core";
import moment from "moment";
import Footer from "../../HomePage/Footer/Footer";

let headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

let token = localStorage.getItem("token");

function BookingTicket() {
  const { ticketId } = useParams();
  const [bookings, setBookings] = useState([]);

  useEffect(() => {
    if (token) {
      axios.get("https://admin.hibroservices.com/api/serviceRequest/getMyBookings/history", { headers }).then(
        (response) => {
          let resu = response.data.result;
          console.log(resu);
          for (let i = 0; i < resu.length; i++) {
            let sum = 19;
            console.log(resu[i].serviceOptin);
            for (let j = 0; j < resu[i].serviceOptin.length; j++) {
              sum += resu[i].serviceOptin[j]["serviceId"]["serviceChargeMin"];
            }
            resu[i]["totalAmount"] = sum;
            sum = 19;
          }
          console.log(resu);
          setBookings(resu);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, []);

  return (
    <div>
      {bookings.map((booking, index) => (
        <div key={booking.requestOrderNumber}>
          {booking.requestOrderNumber === ticketId && (
            <div>
              <div className="bookingTicketHeading">
                <Container>
                  <Typography variant="h4" component="h2" className="bookingTicketHeadingText oswald">
                    <span className="blue">SERVICE BOOKING </span> TICKET - HISTORY
                  </Typography>
                </Container>
              </div>
              <Divider style={{ borderBottom: "2px solid #aaa" }} />
              <Container>
                {/* <Button
                  variant="contained"
                  style={{ backgroundColor: "#0066ff", color: "#fff", margin: "30px 20px 50px 0px" }}
                  onClick={() => {
                    if (token) {
                      axios
                        .post(
                          "https://admin.hibroservices.com/api/serviceRequest/updateTicketStatus/" +
                          booking.serviceRequestId,
                          {
                            ticketStatusId: 3,
                            reason: "Canceled due to unavailability",
                            rescheduledDateTime: "Sun May 18 2021 13:15:55 GMT+0530 (India Standard Time)",
                          },
                          { headers }
                        )
                        .then(
                          (response) => {
                            console.log(response);
                          },
                          (error) => {
                            console.log(error);
                          }
                        );
                    }
                  }}
                >
                  Reschedule
                </Button> */}
                <Button
                  variant="contained"
                  style={{ backgroundColor: "#fff212", margin: "30px 20px 50px 0px" }}
                  href="tel:9962562562"
                >
                  Call Us
                </Button>
                <Grid container spacing={5} justify="center">
                  <Grid item md={6}>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            SERVICE DETAILS
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                          Service ID: {booking.requestOrderNumber}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          Service Status: {booking.ticketStatus}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          {booking.ticketStatus === "Completed"
                            ? "Booking Completed by HiBro Team!!!"
                            : "Booking Cancelled"}
                        </Typography>
                      </CardContent>
                    </Card>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            SERVICE LOCATION
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                          Address: {booking.address}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          Pincode: {booking.pincode}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          Date and Time: {moment(booking.serviceDateTime).format("lll")}
                        </Typography>
                      </CardContent>
                    </Card>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            CUSTOMER DETAILS
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                          Customer Name: {booking.customerName}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          Contact Number: {booking.phoneNumber}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            SERVICE CART
                          </Typography>
                        }
                      />
                      <CardContent>
                        {booking.serviceOptin.map((serviceOpt, index) => (
                          <div key={serviceOpt.serviceId.serviceId}>
                            <Box display="flex" alignItems="center">
                              <Box flexGrow={1} alignItems="center">
                                <Typography gutterBottom variant="h6" component="h2" display="inline">
                                  {serviceOpt.serviceId.serviceName}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems="center">
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="h2"
                                  display="inline"
                                  style={{ marginRight: "20px" }}
                                >
                                  ₹{serviceOpt.serviceId.serviceChargeMin}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                          </div>
                        ))}
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Booking Charge
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              ₹19
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Total Amount
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              ₹{booking.totalAmount}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            HARDWARE CART
                          </Typography>
                        }
                      />
                      <CardContent>
                        {booking.billableItems.map((billableItem, index) => (
                          <div key={billableItem.billableItemId}>
                            <Box display="flex" alignItems="center">
                              <Box flexGrow={1} alignItems="center">
                                <Typography gutterBottom variant="h6" component="h2" display="inline">
                                  {billableItem.particular}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems="center">
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="h2"
                                  display="inline"
                                  style={{ marginRight: "20px" }}
                                >
                                  ₹{billableItem.price}
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                          </div>
                        ))}
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Total Amount
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              ₹{booking.totalBillableItem}
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "50px", minWidth: "325px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            SERVICE + HARDWARE CART
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Grand Total
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              ₹{booking.totalBillableItem + booking.totalAmount}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Payment Received
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              ₹{booking.totalPaymentMade}
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Payment Due
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              ₹{booking.totalBillableItem + booking.totalAmount - booking.totalPaymentMade}
                            </Typography>
                          </Box>
                        </Box>
                        {/* <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Payment Mode
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              Online or COD
                            </Typography>
                          </Box>
                        </Box> */}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
              {/* <h1>Address: {booking.address}</h1>
              <h1>AreaName: {booking.areaName}</h1>
              <h1>BranchName: {booking.branchName}</h1>
              <h1>OrderCreatedAt: {booking.createdAt}</h1>
              <h1>CustomerName: {booking.customerName}</h1>
              <h1>MapCoords: {booking.mapCoords}</h1>
              <h1>PhoneNumber: {booking.phoneNumber}</h1>
              <h1>Pincode: {booking.pincode}</h1>
              <h1>RequestOrderNumber: {booking.requestOrderNumber}</h1>
              <h1>ServiceDateTime: {booking.serviceDateTime}</h1>
              <h1>ServiceRequestId: {booking.serviceRequestId}</h1>
              <h1>TicketStatus: {booking.ticketStatus}</h1>
              <h1>TotalAmount: {booking.totalAmount}</h1>
              <h1>TotalBillableItem: {booking.totalBillableItem}</h1>
              <h1>TotalPaymentMode: {booking.totalPaymentMade}</h1>
              <h1>
                {booking.serviceOptin.map((serviceOpt, index) => (
                  <div key={serviceOpt.serviceId.serviceId}>
                    <div>ServiceId: {serviceOpt.serviceId.serviceId}</div>
                    <div>ServiceName: {serviceOpt.serviceId.serviceName}</div>
                    <div>BookingCharges: {serviceOpt.serviceId.bookingCharges}</div>
                    <div>ServieChargeMin: {serviceOpt.serviceId.serviceChargeMin}</div>
                    <div>ServiceChargeMax: {serviceOpt.serviceId.serviceChargeMax}</div>
                  </div>
                ))}
              </h1>
              <h1>
                {booking.billableItems.map((billableItem, index) => (
                  <div key={billableItem.billableItemId}>
                    <div>BillableItemId: {billableItem.billableItemId}</div>
                    <div>BillableItemName: {billableItem.particular}</div>
                    <div>BillableItemPrice: {billableItem.price}</div>
                  </div>
                ))}
              </h1> */}
            </div>
          )}
        </div>
      ))}
      <Footer />
    </div>
  );
}

export default BookingTicket;
