import React from "react";
import CategoryHeader from "./CategoryHeader/CategoryHeader";
import CategoryPageBody from "./CategoryBody/CategoryPageBody";
import Footer from "../HomePage/Footer/Footer";

function Category() {
  return (
    <div>
      <CategoryHeader />
      <CategoryPageBody />
      <Footer />
    </div>
  );
}

export default Category;
