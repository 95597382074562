import { Container, Typography, Box, Grid, Button, Card, Link } from "@material-ui/core";
import { useState } from "react";
import classes from "./RegisterProductVendor.module.css";
import axios from "axios";
import HibroButton from "../../../../../UI/Button/Button";

let headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

let token = localStorage.getItem("token");
console.log(token);

function AddProperty() {
  const [data, setData] = useState({
    userName: "",
    phoneNumber: "",
    email: "",
    shopName: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    landmark: "",
    password: "",
    confirmPassword: "",
  });

  const [message, setmessage] = useState("");

  const { userName, phoneNumber, email, shopName, address, city, state, pincode, landmark, password, confirmPassword } =
    data;

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setmessage("");
    console.log(data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    axios
      .post(
        "https://admin.hibroservices.com/api/vendor/addVendor",
        {
          userName: userName,
          phoneNumber: phoneNumber,
          email: email,
          shopName: shopName,
          address: address,
          city: city,
          state: state,
          pincode: pincode,
          landmark: landmark,
          password: password,
        },
        { headers }
      )
      .then(
        (res) => {
          console.log(res.data);
          if (res.data.success) {
            setmessage(
              "Your Product Vendor Registration was successfully submitted. Our Executive will call you soon. Thanks for the Registration."
            );
            setData({
              ...data,
              userName: "",
              phoneNumber: "",
              email: "",
              shopName: "",
              address: "",
              city: "",
              state: "",
              pincode: "",
              landmark: "",
              password: "",
              confirmPassword: "",
            });
          } else {
            setmessage(`${res.data.message}. Try to Login`);
          }
        },
        (error) => {
          console.log(error);
          console.log(error.message);
        }
      );
  };

  function InputLabel(props) {
    return (
      <div>
        <Typography className={classes.labelText}>
          {props.labelName}
          <span className={classes.asterisk}>{props.star}</span>
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Container maxWidth="sm">
        <Card className={classes.cardLogin}>
          <Typography
            variant="h4"
            style={{
              color: "#0066ff",
              textAlign: "center",
              marginTop: "50px",
              marginBottom: "50px",
              fontFamily: "Oswald, sans-serif",
              fontWeight: "bold",
              fontSize: "2em",
            }}
          >
            LOGIN AS A <span style={{ color: "#000" }}>PRODUCT VENDOR</span>
          </Typography>
          <div style={{ textAlign: "center" }}>
            <Link href="https://admin.hibroservices.com" style={{ textDecoration: "none" }}>
              <Button style={{ width: "200px" }} type="submit" variant="contained" className={classes.button1}>
                Go to Login Page
              </Button>
            </Link>
          </div>
        </Card>
        <Typography style={{ fontSize: "40px", fontWeight: "bold", textAlign: "center" }}>OR</Typography>
      </Container>

      <Box display="flex" alignItems="center">
        <Container maxWidth="md">
          <Typography
            variant="h4"
            style={{
              color: "#0066ff",
              textAlign: "center",
              marginTop: "50px",
              marginBottom: "50px",
              fontFamily: "Oswald, sans-serif",
              fontWeight: "bold",
              fontSize: "2em",
            }}
          >
            REGISTER AS A <span style={{ color: "#000" }}>PRODUCT VENDOR</span>
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <InputLabel labelName="Vendor Name" star="*" />
                <input
                  className={classes.formInput}
                  name="userName"
                  value={userName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <InputLabel labelName="Contact Number" star="*" />
                <input
                  className={classes.formInput}
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputLabel labelName="Email ID" />
                <input className={classes.formInput} name="email" value={email} onChange={handleChange} />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputLabel labelName="Shop Name" star="*" />
                <input
                  className={classes.formInput}
                  name="shopName"
                  value={shopName}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <InputLabel labelName="Shop Address" star="*" />
                <textarea
                  className={classes.formInput}
                  name="address"
                  value={address}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <InputLabel labelName="City" star="*" />
                <input className={classes.formInput} name="city" value={city} onChange={handleChange} required />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <InputLabel labelName="State" star="*" />
                <input className={classes.formInput} name="state" value={state} onChange={handleChange} required />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <InputLabel labelName="Pincode" star="*" />
                <input className={classes.formInput} name="pincode" value={pincode} onChange={handleChange} required />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <InputLabel labelName="Land Mark" star="*" />
                <input
                  className={classes.formInput}
                  name="landmark"
                  value={landmark}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <InputLabel labelName="Password" star="*" />
                <input
                  className={classes.formInput}
                  name="password"
                  value={password}
                  onChange={handleChange}
                  required
                />
              </Grid>
              <Grid item lg={6} md={12} sm={12} xs={12}>
                <InputLabel labelName="Confirm Password" star="*" />
                <input
                  className={classes.formInput}
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleChange}
                  required
                />
              </Grid>
            </Grid>
            <div className={classes.btnContainer}>
              <Typography className={classes.message}>{message}</Typography>
              <HibroButton btnName="REGISTER" type="submit" />
            </div>
            {/* <button type="submit">Register</button> */}
          </form>
        </Container>
      </Box>
    </div>
  );
}

export default AddProperty;
