import React from "react";
import { Grid, Box, Link } from "@material-ui/core";

function OnlineSupport() {
  return (
    <Box>
      <div className="firstHeading">
        <h1 style={{ marginBottom: "30px" }} className="oswald">
          <span style={{ color: "#0066FF" }}>LARGEST HOME</span>
          <span style={{ color: "#000000" }}> SERVICE PROVIDER</span>
        </h1>
        <div className="blueAndYellow">
          <Grid container>
            <Grid
              item
              md={6}
              className="blueBack whiteText"
              style={{ width: "100%" }}
            >
              <h1 style={{ padding: "20px" }} className="roboto">
                <Grid container>
                  <Grid xs={4}>
                    <Box display="flex" justifyContent="flex-end">
                      <i className="flaticon-24-hours-delivery icons75" />
                    </Box>
                  </Grid>
                  <Grid xs={8}>
                    <Box
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      style={{ height: "100%", marginLeft: 20 }}
                    >
                      ONLINE SUPPORT
                    </Box>
                  </Grid>
                </Grid>
              </h1>
            </Grid>

            <Grid item md={6} className="yellowBack" style={{ width: "100%" }}>
              <Link color="inherit" underline="none" href="tel:9962562562">
                <h1 style={{ padding: "20px" }} className="roboto">
                  <Grid container>
                    <Grid xs={4}>
                      <Box display="flex" justifyContent="flex-end">
                        <i className="flaticon-user-at-phone icons75" />
                      </Box>
                    </Grid>
                    <Grid xs={8}>
                      <Box
                        display="flex"
                        justifyContent="flex-start"
                        alignItems="center"
                        style={{ height: "100%", marginLeft: 20 }}
                      >
                        9962562562
                      </Box>
                    </Grid>
                  </Grid>
                </h1>
              </Link>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  );
}

export default OnlineSupport;
