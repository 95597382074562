import React from "react";
import Logo from "../../HomePage/Header/Logo";
import { Container, Box } from "@material-ui/core";
import Navbar from "../../NavigationBarPages/Navbar";

function Header() {
  return (
    <div
      className="categoryBanner"
      style={{
        backgroundImage: `url(https://hibroservices.s3.ap-south-1.amazonaws.com/images/home.png)`,
      }}
    >
      <Container>
        <Box display="flex">
          <Box flexGrow={1}>
            <Logo />
          </Box>
          <Box display="flex" alignItems="center">
            <Navbar />
          </Box>
        </Box>
      </Container>
    </div>
  );
}

export default Header;
