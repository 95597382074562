import VendorHeader from "../../VendorHeader/VendorHeader";
import RegisterProductVendor from "./RegisterProductVendor";
import VendorFooter from "../../../../HomePage/Footer/Footer";
import { AppBar } from "@material-ui/core";

function ProductVendor() {
  return (
    <div>
      <AppBar position="static">
        <VendorHeader />
      </AppBar>
      <RegisterProductVendor />
      <VendorFooter />
    </div>
  );
}

export default ProductVendor;
