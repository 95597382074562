import React from "react";
import Logo from "../../HomePage/Header/Logo";
import { Container, Box } from "@material-ui/core";
import Navbar from "../Navbar";
import AboutUsContent from "./AboutUsContent";
import AboutUsOurProcess from "./AboutUsOurProcess";
import Footer from "../../HomePage/Footer/Footer";
import SuccessRate from "../../HomePage/OtherBox/SuccessRate";

function AboutUs() {
  return (
    <div>
      <Box mb={10}>
        <div
          className="serviceBanner"
          style={{
            backgroundImage: `url(https://hibroservices.s3.ap-south-1.amazonaws.com/images/home.png)`,
          }}
        >
          <Container>
            {/* <Grid container spacing={2}>
              <Grid item xs={5}>
                <Logo />
              </Grid>
              <Grid item xs={7}>
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  style={{ height: "100%", width: "100%" }}
                >
                  <Navbar />
                </Box>
              </Grid>
            </Grid> */}
            <Box display="flex">
              <Box flexGrow={1}>
                <Logo />
              </Box>
              <Box display="flex" alignItems="center">
                <Navbar />
              </Box>
            </Box>
          </Container>
        </div>
      </Box>
      <Box mb={10}>
        <div>
          <AboutUsContent />
          <AboutUsOurProcess />
          <SuccessRate />
        </div>
      </Box>
      <Footer />
    </div>
  );
}

export default AboutUs;
