import { useState } from "react";
import ShopHeader from "./ShopModules/ShopHeader/ShopHeader";
import ShopBody from "./ShopModules/ShopBody/ShopBody";
import Footer from "../HomePage/Footer/Footer";
import { Card, CardContent, Dialog, Typography } from "@material-ui/core";
import classes from "./Shop.module.css";

function Shop() {
  const [shopPincode, setShopPincode] = useState("");
  const [modalOpen, setModalOpen] = useState(true);
  const [pinCodeErrorMessage, setPinCodeErrorMessage] = useState(false);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setModalOpen(true);
  //   }, 100);
  // }, []);

  function handleClose() {}

  function handleChange(e) {
    setShopPincode(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    if (shopPincode.length === 6) {
      localStorage.setItem("shopPincode", shopPincode);
      setModalOpen(false);
      setPinCodeErrorMessage(false);
      window.open("shop/productCategory", "_self");
    } else {
      setPinCodeErrorMessage(true);
    }
  }

  return (
    <div>
      <Dialog open={modalOpen} onClose={handleClose}>
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <label className={classes.pinLabel}>Enter your pin code here</label>
              <input
                value={shopPincode}
                type="text"
                pattern="[0-9]+"
                name="pincode"
                onChange={handleChange}
                className={classes.pinInput}
              />
              {pinCodeErrorMessage && (
                <Typography variant="body1" className={classes.pincodeErrorMessage}>
                  Pin code should be 6 digit number
                </Typography>
              )}
              <button className={classes.pinButton}>Submit Pincode</button>
            </form>
          </CardContent>
        </Card>
      </Dialog>
      <ShopHeader />
      <ShopBody />
      <Footer />
    </div>
  );
}

export default Shop;
