import React from "react";
import { Container, Box, AppBar } from "@material-ui/core";
import Logo from "../../../HomePage/Header/Logo";

import Navbar from "../../../NavigationBarPages/NavbarForShopPage";

function Header() {
  return (
    <AppBar position="static">
      <div className="bannerForVendorHeader">
        <Container>
          <Box display="flex">
            <Box flexGrow={1}>
              <Logo />
            </Box>
            <Box display="flex" alignItems="center">
              <Navbar />
            </Box>
          </Box>
        </Container>
      </div>
    </AppBar>
  );
}

export default Header;
