import React from "react";
import { Box } from "@material-ui/core";

function AboutUsOurProcess() {
  return (
    <div>
      <Box mb={10}>
        <div
          className="aboutUsOurProcessBanner"
          style={{
            backgroundImage: `url(https://hibroservices.s3.ap-south-1.amazonaws.com/images/aboutUsOurProcess.png)`,
          }}
        ></div>
      </Box>
    </div>
  );
}

export default AboutUsOurProcess;
