import { Box, Button, Card, CardContent, Divider, Typography } from "@material-ui/core";
import classes from "./ProductCart.module.css";

function ProductCart(prop) {
  return (
    <div>
      <Card>
        <CardContent>
          <Box display="flex" justifyContent="flex-end">
            <Typography variant="h6" className={classes.noOfItems}>
              No.of Products: {prop.totalNumberOfProducts}
            </Typography>
          </Box>
          <Divider />
          <div>{prop.cartProductList}</div>
          <div className={classes.totalItems}>
            <Box display="flex" alignItems="center">
              <Box display="flex" flexGrow="1">
                <Typography variant="h6">Total</Typography>
              </Box>
              <Typography variant="h6">₹{prop.totalPrice}</Typography>
            </Box>
            {prop.totalNumberOfProducts > 0 && (
              <Box>
                <Box display="flex" alignItems="center">
                  <Box display="flex" flexGrow="1">
                    <Typography variant="h6">GST (18%)</Typography>
                  </Box>
                  <Typography variant="h6">₹{(prop.totalPrice * 18) / 100}</Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Box display="flex" flexGrow="1">
                    <Typography variant="h6" className={classes.total}>
                      Grand Total (including GST)
                    </Typography>
                  </Box>
                  <Typography variant="h6">₹{prop.totalPrice + (prop.totalPrice * 18) / 100}</Typography>
                </Box>
              </Box>
            )}
          </div>
          <Typography className={classes.productAlert}>{prop.productAlert}</Typography>
          <Box display="flex" justifyContent="center">
            <Button variant="contained" className={classes.checkOutButton} onClick={prop.onClickEvent}>
              CHECKOUT
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default ProductCart;
