import { AppBar } from "@material-ui/core";
import React from "react";
import VendorHeader from "./VendorHeader/VendorHeader";
// import VendorBody from "./VendorBody/VendorBody";
import VendorMainPage from "./VendorMainPage/VendorMainPage";
import VendorFooter from "../../HomePage/Footer/Footer";

function ServiceVendorLogin() {
  return (
    <div>
      <AppBar position="static">
        <VendorHeader />
      </AppBar>
      <VendorMainPage />
      {/* <VendorBody /> */}
      <VendorFooter />
    </div>
  );
}

export default ServiceVendorLogin;
