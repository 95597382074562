import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import Card from "@material-ui/core/Card";
import { Box, Link } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";

const useStyles = makeStyles((theme) => ({
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

// const country = [
//   {
//     keywords: ["ac fiting", "fan repair"],
//     label: "Electrical",
//     slug: "electrical",
//   },
//   {
//     keywords: ["Cleaning", "Deep Cleaning"],
//     label: "Cleaning Service",
//     slug: "cleaning",
//   },
// ];

export default function SearchComponent() {
  const classes = useStyles();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [filteredCountries, setFilteredCountries] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`https://admin.hibroservices.com/api/services/getSearchList`)
      .then((res) => {
        console.log(res.data.result);
        setCountries(res.data.result);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // useEffect(() => {
  //   setCountries(country);
  // }, []);

  useEffect(() => {
    setFilteredCountries(
      countries.filter((country) => country.keywords.join().toLowerCase().includes(search.toLowerCase()))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  if (loading) {
    return <p>Loading Categories...</p>;
  }

  return (
    <div className="App">
      <Box display="flex" justifyContent="center" style={{ padding: "25px 0px 35px" }}>
        <Paper
          component="form"
          className="search"
          elevation={3}
          variant="outlined"
          style={{ border: "2px solid #d2ccc4" }}
        >
          <IconButton type="submit" className={classes.iconButton} aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            className={classes.input}
            placeholder="Search for a Service"
            inputProps={{ "aria-label": "search google maps" }}
            onChange={(e) => {
              setSearch(e.target.value);
              var textAreaCount = e.target.value.length;
              if (textAreaCount === 0) {
                return setCountries([]);
              }

              axios
                .get(`https://admin.hibroservices.com/api/services/getSearchList`)
                .then((res) => {
                  console.log(res.data.result[0]["Slug"]);
                  setCountries(res.data.result);
                  console.log(res.data.result);
                  setLoading(false);
                })
                .catch((err) => {
                  console.log(err);
                });
            }}
          />
        </Paper>
      </Box>
      <Box display="flex" justifyContent="center">
        <Card className="searchResultCard">
          {filteredCountries.map((country, idx) => (
            <Link
              color="inherit"
              underline="none"
              href={country.Slug}
              onClick={() => {
                console.log(country);
              }}
            >
              <CountryDetail key={idx} {...country} />
            </Link>
          ))}
        </Card>
      </Box>
    </div>
  );
}

const CountryDetail = (props) => {
  const { label } = props;

  return <p className="roboto p-3 searchResultEach">{label}</p>;
};
