import React from "react";
import Navbar from "../Navbar";

function ContactUs() {
  return (
    <div>
      <Navbar />
      <h1>ContactUs Page</h1>
    </div>
  );
}

export default ContactUs;
