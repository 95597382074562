import React from "react";
import Logo from "../../HomePage/Header/Logo";
import { Container, Box } from "@material-ui/core";
import Navbar from "../../NavigationBarPages/Navbar";

function ServiceHeader() {
  return (
    <div
      className="serviceBanner"
      style={{
        backgroundImage: `url(https://hibroservices.s3.ap-south-1.amazonaws.com/images/home.png)`,
      }}
    >
      <Container>
        <Box display="flex">
          <Box flexGrow={1}>
            <Logo />
          </Box>
          <Box display="flex" alignItems="center">
            <Navbar />
          </Box>
        </Box>
        <h1
          className="oswald"
          style={{
            textAlign: "center",
            color: "#fff",
            position: "relative",
            bottom: "-50px",
          }}
        >
          SERVICES PAGE
        </h1>
      </Container>
    </div>
  );
}

export default ServiceHeader;
