import React, { useState, useEffect } from "react";
import { Container, Grid, Box, Link } from "@material-ui/core";
import axios from "axios";
import SearchComponent from "./SearchComponent";
import abcd from "../../../Images/svg/abcd.svg";
import businessMan from "../../../Images/svg/businessMan.svg";
import classes from "./CategoryBox.module.css";

function CategoryBox() {
  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    axios.get("https://admin.hibroservices.com/api/services/getAllCategories").then((res) => {
      setCategoryList(res.data.result);
      console.log(res.data.result);
    });
  }, []);

  return (
    <Container className="categoryBox">
      <SearchComponent />
      <Box className="allCategoryCard">
        <Grid container spacing={3} style={{ textAlign: "center" }}>
          {categoryList.map((ctItem) => (
            <Grid item xs={4} sm={3} md={2} className={classes.categoryCard} key={ctItem.serviceCategoryId}>
              <Link
                color="inherit"
                underline="none"
                href={ctItem.serviceCatSlug}
                onClick={() => {
                  localStorage.setItem("serviceCatId", ctItem.serviceCategoryId);
                }}
              >
                <Box className={classes.eachCategoryCard}>
                  <Box className="categoryIcon">
                    {ctItem.serviceCategoryName == "Facility Management " ? (
                      <img className="newcategoryIcon" width="32px" src={abcd} alt="Service Illustrator" />
                    ) : ctItem.serviceCategoryName == "Business Administration" ? (
                      <img
                        className="newcategoryIcon"
                        width="32px"
                        style={{ color: "red" }}
                        src={businessMan}
                        alt="Service Illustrator"
                      />
                    ) : (
                      <i className={ctItem.categoryIcon} />
                    )}
                  </Box>
                  <p>{ctItem.serviceCategoryName}</p>
                </Box>
              </Link>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
}

export default CategoryBox;
