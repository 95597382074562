import React from "react";
import LogoImg from "../../../Images/hii.png";
import { Link } from "@material-ui/core";

function Logo() {
  return (
    <Link color="inherit" underline="none" href="/">
      <img
        src={LogoImg}
        alt="Hibro Logo"
        className="logoStyle"
        // style={{ marginTop: "20px", height: "100px", width: "auto" }}
      />
    </Link>
  );
}

export default Logo;
