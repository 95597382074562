

const CardBlog = [
  {
    
    imgURL:
      "https://i.ibb.co/g3mjpTJ/b1.png",
      date: "14 Jun, 2021",
    name: "Sed vitae sapien ultrices lmperdiet vel ligula.",
    content: "Proin nec elit quis nisl venenatis consectetur ut quis felis. Phasellus ornare dignissim ante, nec egest purus. Donec por lib rhoncus viverra. Quisque consectetur a diam vitae feugiat. Maecenas tincidunt element condimentum. Etiam ipsum neq eleifend sed eget, consequat elementum metus. Vivamus non nulla iaculis, molestie neque gravida, semper nulla. Maecenas vestibulum aliquet dui, ac faucibus ex suscipit et mattis urna congue.",
    
    
  },
  {
    
    imgURL:
    "https://i.ibb.co/g3mjpTJ/b1.png",
    date: "14 Jun, 2021",
  name: "Sed vitae sapien ultrices lmperdiet vel ligula.",
  content: "Proin nec elit quis nisl venenatis consectetur ut quis felis. Phasellus ornare dignissim ante, nec egest purus. Donec por lib rhoncus viverra. Quisque consectetur a diam vitae feugiat. Maecenas tincidunt element condimentum. Etiam ipsum neq eleifend sed eget, consequat elementum metus. Vivamus non nulla iaculis, molestie neque gravida, semper nulla. Maecenas vestibulum aliquet dui, ac faucibus ex suscipit et mattis urna congue.",
 
  },
  {
    
    imgURL:
      "https://i.ibb.co/g3mjpTJ/b1.png",
      date: "14 Jun, 2021",
    name: "Sed vitae sapien ultrices lmperdiet vel ligula.",
    content: "Proin nec elit quis nisl venenatis consectetur ut quis felis. Phasellus ornare dignissim ante, nec egest purus. Donec por lib rhoncus viverra. Quisque consectetur a diam vitae feugiat. Maecenas tincidunt element condimentum. Etiam ipsum neq eleifend sed eget, consequat elementum metus. Vivamus non nulla iaculis, molestie neque gravida, semper nulla. Maecenas vestibulum aliquet dui, ac faucibus ex suscipit et mattis urna congue.",
    
  }
];


  export default CardBlog;
  

  
    