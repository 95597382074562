import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import classes from "./NavbarForVendorPage.module.css";
import Box from "@material-ui/core/Box";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

function NavBar(props) {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <nav className={classes.navbar}>
        <Box display="flex" justifyContent="center" alignItems="flex-end">
          <div className={classes.navContainer}>
            <Box display="flex" justifyContent="center" alignItems="flex-end">
              <ul className={click ? `${classes.navMenu} ${classes.active}` : classes.navMenu}>
                <li className={classes.navItem}>
                  <NavLink
                    exact
                    to="/"
                    activeClassName={classes.active}
                    className={classes.navLinks}
                    onClick={handleClick}
                  >
                    Home
                  </NavLink>
                </li>
                <li className={classes.navItem}>
                  <NavLink
                    exact
                    to="/navbar/options/about-us"
                    activeClassName={classes.active}
                    className={classes.navLinks}
                    onClick={handleClick}
                  >
                    About Us
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink
                    exact
                    to="/navbar/options/blog"
                    activeClassName="active"
                    className="nav-links"
                    onClick={handleClick}
                  >
                    Blog
                  </NavLink>
                </li> */}

                {/* <li className={classes.navItem}>
                  <NavLink
                    exact
                    to="/navbar/options/vendor-login"
                    activeClassName={classes.active}
                    className={classes.navLinks}
                    onClick={handleClick}
                  >
                    Vendor Register
                  </NavLink>
                </li> */}
              </ul>
            </Box>
            <div className={classes.navIcon} onClick={handleClick}>
              {click ? <CloseIcon fontSize="large" /> : <MenuIcon fontSize="large" />}
            </div>
          </div>
        </Box>
      </nav>
    </>
  );
}

export default NavBar;
