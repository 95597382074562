import React from "react";
import Container from "@material-ui/core/Container";
import { Grid, Link, Button } from "@material-ui/core";
import Logo from "../../../Images/hii.png";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  blueBack: {
    color: "#0066FF",
    fontFamily: "roboto",
  },
});

function FooterLinks() {
  const classes = useStyles();
  return (
    <Container>
      <Grid container style={{ marginTop: "10px" }} spacing={6} className="footerCenter">
        <Grid item className="mt-3 pb-5 footerWidth100" md={4} display="flex">
          <img src={Logo} alt="HiBRO Logo" />
          <h3 className="roboto">
            <LocationOnIcon />
            <span className={classes.blueBack}> Address:</span> P.No.18, 1st Avenue, Pallava Garden, Old Pallavaram,
            Chennai - 600 117.
          </h3>
          <Link color="inherit" underline="none" href="tel:9962562562">
            <h3 className="roboto">
              <PhoneIcon />
              <span className={classes.blueBack}> Mobile:</span> 9962562562
            </h3>
          </Link>
          <Link color="inherit" underline="none" href="mailto:hibroonline@gmail.com">
            <h3 className="roboto">
              <EmailIcon />
              <span className={classes.blueBack}> Email:</span> hibroservices@gmail.com
            </h3>
          </Link>
          <div style={{ textAlign: "center" }}>
            <Link color="inherit" underline="none" href="tel:9962562562">
              <Button variant="contained" style={{ backgroundColor: "#0066ff", color: "#fff", marginTop: "10px" }}>
                Call Us
              </Button>
            </Link>
          </div>
        </Grid>
        <Grid item className="roboto footerMT-6 footerWidth100" md={3}>
          <h1>LINKS</h1>
          <ul style={{ listStyleType: "none" }}>
            <li>
              <Link color="inherit" underline="none" href="/">
                Home
              </Link>
            </li>
            <li>
              <Link color="inherit" underline="none" href="/navbar/options/about-us">
                About Us
              </Link>
            </li>
            {/* <li>
              <Link color="inherit" underline="none" href="/">
                Blog
              </Link>
            </li> */}
            <li>
              <Link color="inherit" underline="none" href="/navbar/options/my-bookings">
                My Bookings
              </Link>
            </li>
            {/* <li>
              <Link color="inherit" underline="none" href="/">
                Careers
              </Link>
            </li>
            <li>
              <Link color="inherit" underline="none" href="/">
                Contact Us
              </Link>
            </li> */}
            <li>
              <Link color="inherit" underline="none" href="/navbar/options/terms-and-conditions">
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link color="inherit" underline="none" href="/navbar/options/privacy-policy">
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link color="inherit" underline="none" href="/navbar/options/pricing-policy">
                Pricing Policy
              </Link>
            </li>
            <li>
              <Link color="inherit" underline="none" href="/navbar/options/refund-and-cancellation-policy">
                Refund and Cancellation Policy
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid item className="roboto footerMT-6 footerMB-6 footerWidth100" md={5}>
          <h1>BRANCH WORKING HOURS</h1>
          <h3>
            <span className={classes.blueBack}>Opened: </span>9.00 am to 6.00pm (Monday to Saturday)
          </h3>
          <h3>
            <span className={classes.blueBack}>Closed: </span>Sunday and National Holidays
          </h3>
          <h3>
            <span className={classes.blueBack}>Bookings and Orders Accepted: </span>
            24/7
          </h3>
        </Grid>
      </Grid>
    </Container>
  );
}

export default FooterLinks;
