import { Box, Card, CardContent, Container, Grid, Typography } from "@material-ui/core";
import classes from "./MyOrdersBody.module.css";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment";

function MyOrdersBody() {
  const [ordersListFromDB, setOrdersListFromDB] = useState([]);

  let headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  };

  useEffect(() => {
    if (headers) {
      axios.get("https://admin.hibroservices.com/api/orders/getOrdersList", { headers }).then((response) => {
        if (response.data.success) {
          setOrdersListFromDB(response.data.result);
          console.log(response.data.result);
        } else {
          alert(response.data.message);
        }
      });
    } else {
      alert("Signin as a admin user");
    }
  }, []);

  return (
    <div>
      <Container>
        <div className={classes.myOrderDivContainer}>
          <Typography variant="h4" className={classes.myOrderHeading}>
            <span className={classes.blueColorText}>My</span> Orders
          </Typography>
          {ordersListFromDB.map((num) => (
            <Link to={`/navbar/options/shop/orders/my-orders/${num.orderId}`} className={classes.myOrderLink}>
              <Card className={classes.myOrderListCard}>
                <CardContent>
                  <Box display="flex" alignItems="center">
                    <Grid container spacing={2}>
                      <Grid lg={5} md={4} sm={12} xs={12}>
                        <Typography variant="body1" className={classes.myBookingCustomerName}>
                          {num.userName}
                        </Typography>
                        <Typography variant="body1" className={classes.myBookingCreatedDate}>
                          Created Date: {moment(num.orderDate).format("MMM Do YYYY, h:mm a")}
                        </Typography>
                      </Grid>
                      <Grid lg={4} md={4} sm={12} xs={12}>
                        <Typography variant="body1" className={classes.myBookingServiceRequestNo}>
                          Order ID: {num.orderId}
                        </Typography>
                      </Grid>
                      <Grid lg={3} md={4} sm={12} xs={12}>
                        <Typography variant="body1" className={classes.myBookingCustomerName}>
                          Order Status: {num.orderStatus}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Link>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default MyOrdersBody;
