import { AppBar } from "@material-ui/core";
import VendorHeader from "../../VendorHeader/VendorHeader";
import VendorBody from "../../VendorBody/VendorBody";
import VendorFooter from "../../../../HomePage/Footer/Footer";

function ServiceVendor() {
  return (
    <div>
      <AppBar position="static">
        <VendorHeader />
      </AppBar>
      <VendorBody />
      <VendorFooter />
    </div>
  );
}

export default ServiceVendor;
