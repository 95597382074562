import React, { useEffect, useState } from "react";
import ShopHeader from "./ShopModules/ShopHeader/ShopHeader";
import ShopProductList from "./ShopModules/ShopBody/ShopProductList/ShopProductList";
import Footer from "../HomePage/Footer/Footer";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import axios from "axios";
import {
  AppBar,
  Box,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  TableCell,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import classes from "./ShopIndividual.module.css";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
// import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import LogoImg from "../../Images/hii.png";
import LoginHandleShop2 from "../NavigationBarPages/LoginHandleShop2";
// import electrical from "../../Images/ImagesForShop/electrical.jpg";
// import plumbing from "../../Images/ImagesForShop/plumbing.jpg";
// import ac from "../../Images/ImagesForShop/ac.jpg";
// import homeAppliances from "../../Images/ImagesForShop/home-appliances.jpg";
// import civil from "../../Images/ImagesForShop/civil-products.jpg";
// import carpentry from "../../Images/ImagesForShop/carpentry.jpg";
// import renovation from "../../Images/ImagesForShop/renovation.jpg";
// import cleaning from "../../Images/ImagesForShop/cleaning.jpg";
// import pest from "../../Images/ImagesForShop/pest-control.jpg";
// import gardening from "../../Images/ImagesForShop/gardening.jpg";
// import painting from "../../Images/ImagesForShop/paint.jpg";
// import amc from "../../Images/ImagesForShop/amc.jpg";
// import { Fragment } from "react";
// import { useParams } from "react-router-dom";

// const options = ["option1", "option2", "option3"];

// const productCategoryList = [
//   {
//     id: 23,
//     categoryName: "Electrical Products",
//     categoryLink: "electrical-products",
//     categoryImage: electrical,
//   },
//   {
//     id: 24,
//     categoryName: "Plumbing Products",
//     categoryLink: "plumbing-products",
//     categoryImage: plumbing,
//   },
//   {
//     id: 25,
//     categoryName: "AC Products",
//     categoryLink: "ac-products",
//     categoryImage: ac,
//   },
//   {
//     id: 26,
//     categoryName: "Home Appliances",
//     categoryLink: "home-appliances-products",
//     categoryImage: homeAppliances,
//   },
//   {
//     id: 27,
//     categoryName: "Civil Products",
//     categoryLink: "civil-products",
//     categoryImage: civil,
//   },
//   {
//     id: 28,
//     categoryName: "Carpentry Products",
//     categoryLink: "carpentry-products",
//     categoryImage: carpentry,
//   },
//   {
//     id: 29,
//     categoryName: "Home Renovation Products",
//     categoryLink: "home-renovation-products",
//     categoryImage: renovation,
//   },
//   {
//     id: 30,
//     categoryName: "Cleaning Products",
//     categoryLink: "cleaning-products",
//     categoryImage: cleaning,
//   },
//   {
//     id: 31,
//     categoryName: "Pest Control Products",
//     categoryLink: "pest-control-products",
//     categoryImage: pest,
//   },
//   {
//     id: 32,
//     categoryName: "Gardening Products",
//     categoryLink: "gardening-products",
//     categoryImage: gardening,
//   },
//   {
//     id: 33,
//     categoryName: "Painting Products",
//     categoryLink: "painting-products",
//     categoryImage: painting,
//   },
//   {
//     id: 34,
//     categoryName: "AMC Products",
//     categoryLink: "amc-products",
//     categoryImage: amc,
//   },
// ];

function Shop() {
  // const [options, setOptions] = React.useState([]);
  // const responsive = {
  //   superLargeDesktop: {
  //     // the naming can be any, depends on you.
  //     breakpoint: { max: 4000, min: 3000 },
  //     items: 5,
  //   },
  //   desktop: {
  //     breakpoint: { max: 3000, min: 1024 },
  //     items: 3,
  //   },
  //   tablet: {
  //     breakpoint: { max: 1024, min: 720 },
  //     items: 2,
  //   },
  //   mobile: {
  //     breakpoint: { max: 720, min: 0 },
  //     items: 1,
  //   },
  // };

  // React.useEffect(() => {
  //   axios.get("https://admin.hibroservices.com/api/masters/getAllTags").then((res) => {
  //     setOptions(res.data.result);
  //   });
  // }, []);

  const [orderId, setOrderId] = useState();

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function paymentInitialize() {
    setPaymentDialogOpen(false);
    console.log("Payment Initialized");
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };

    const result = await axios.post(
      "https://admin.hibroservices.com/api/orders/initPaymentOrder",
      { orderId: orderId },
      { headers }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    console.log(result);

    const respond = result.data.result;

    if (result.data.success) {
      console.log("Payment Initialization Success");
      const options = {
        key: respond.key,
        amount: respond.amount,
        currency: respond.currency,
        name: respond.name,
        description: respond.description,
        image: LogoImg,
        order_id: respond.id,
        handler: async function (response) {
          console.log(response);
          const data = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            orderId: orderId,
            errorDetails: "",
          };

          const resultSuccess = await axios.post(
            "https://admin.hibroservices.com/api/orders/updatePaymentDetails/success",
            data,
            { headers }
          );
          console.log(resultSuccess);
          if (resultSuccess.data.success) {
            console.log("Result Success Initiated");
          } else {
            console.log(resultSuccess.data.success);
          }
        },
        prefill: {
          name: respond.userName,
          email: "",
          contact: respond.phoneNumber,
        },
        notes: {
          address: "Hibro Corporate Office",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        console.log(response);
        axios
          .post(
            "https://admin.hibroservices.com/api/orders/updatePaymentDetails/failure",
            {
              orderId: orderId,
              razorpayOrderId: response.error.metadata.order_id,
              errorDetails: {
                code: response.error.code,
                description: response.error.description,
                source: response.error.source,
                step: response.error.step,
                reason: response.error.reason,
                metadata: {
                  payment_id: response.error.metadata.payment_id,
                  order_id: response.error.metadata.order_id,
                },
              },
            },
            { headers }
          )
          .then(
            (responseError) => {
              console.log(responseError);
              if (responseError.data.success) {
                alert("Payment Success");
              }
            },
            (error) => {
              console.log(error);
            }
          );
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });
      paymentObject.open();
    }
  }

  const [data, setData] = React.useState({
    phone: "",
    address1: "",
    address2: "",
    city: "",
    pincode: "",
    landmark: "",
    payment: "",
  });

  const { phone, address1, address2, city, pincode, landmark, payment } = data;
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const [value, setValue] = React.useState(options[0]);
  const [addressDialogOpen, setAddressDialogOpen] = React.useState(false);
  const [paymentDialogOpen, setPaymentDialogOpen] = React.useState(false);
  const [paymentModeForButton, setPaymentModeForButton] = React.useState(0);
  const [catId, setCatId] = React.useState("");
  const [catPrimaryId, setCatPrimaryId] = React.useState("");

  const [openLoginDialogState, setOpenLoginDialogState] = useState(false);

  // const [addressBoxDisplay, setAddressBoxDisplay] = React.useState("block");

  // const { productCategory } = useParams();

  const [tags, setTags] = useState([]);
  const [tagValue, setTagValue] = useState("");
  const [searchValue, setSearchValue] = useState("");

  const [tagName, setTagName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  const [productOrderResFromDB, setProductOrderResFromDB] = useState(null);

  useEffect(() => {
    axios.get("https://admin.hibroservices.com/api/masters/getAllTags").then((res) => {
      setTags(res.data.result);
    });
  }, []);

  function handleCategoryChange(e) {
    setCatId(e.target.value);
  }

  function handleSelectTagChange(e) {
    setTagValue(e.target.value);
  }

  function handleSearchBoxChange(e) {
    setSearchValue(e.target.value);
  }

  function handleSearchButtonClick() {
    setSearchTerm(searchValue);
    setTagName(tagValue);
    setCatPrimaryId(catId);
  }

  function handleProductOrderForm(e) {
    e.preventDefault();
    console.log("button clicked");
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    let formData = {
      phoneNumber: phone,
      addressLine1: address1,
      addressLine2: address2,
      city: city,
      pincode: pincode,
      landmark: landmark,
      orderItems: JSON.parse(localStorage.getItem("productCartIds")),
      paymentMode: payment,
    };
    axios.post("https://admin.hibroservices.com/api/orders/createOrder", formData, { headers }).then((res) => {
      if (res.data.success) {
        console.log(res);
        console.log(res.data.result);
        setProductOrderResFromDB(res.data.result);
        setOrderId(res.data.result.orderId);
        setAddressDialogOpen(false);
        setPaymentDialogOpen(true);
        if (res.data.result.paymentMode == "1") {
          setPaymentModeForButton(1);
        } else if (res.data.result.paymentMode == "2") {
          setPaymentModeForButton(2);
        }
      } else {
        console.log(res);
        console.log(formData);
      }
    });
  }

  function handleClose() {
    setAddressDialogOpen(false);
  }

  function handleProductOrderFormChange(e) {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  // const localStorageData = localStorage.getItem("test");
  // console.log(options);

  const [categoryList, setCategoryList] = useState([]);

  useEffect(() => {
    axios.get("https://admin.hibroservices.com/api/services/getAllCategories").then((res) => {
      console.log(res.data);
      if (res.data.result) {
        setCategoryList(res.data.result);
      }
    });
  }, []);

  return (
    <div>
      <ShopHeader />
      <AppBar position="static" className={classes.searchAppBar}>
        <Toolbar>
          <Container>
            <Grid container spacing={2}>
              <Grid lg={3} md={6} sm={6} xs={6}>
                <div style={{ position: "relative" }}>
                  <select className={classes.tagSelectStyle} onChange={handleCategoryChange} value={catId}>
                    <option value="">All Category</option>
                    {categoryList.map((category) => (
                      <option key={category.serviceCategoryId} value={category.serviceCategoryId}>
                        {category.serviceCategoryName}
                      </option>
                    ))}
                  </select>
                  <label className={classes.tagLabel}>Product Category</label>
                </div>
              </Grid>
              <Grid lg={3} md={6} sm={6} xs={6}>
                <div style={{ position: "relative" }}>
                  <select className={classes.tagSelectStyle} onChange={handleSelectTagChange} value={tagValue}>
                    <option value="">All Products</option>
                    {tags.map((tag) => (
                      <option key={tag.tagId} value={tag.tagId}>
                        {tag.tagName}
                      </option>
                    ))}
                  </select>
                  <label className={classes.tagLabel}>Products related to</label>
                </div>
              </Grid>
              <Grid lg={3} md={6} sm={6} xs={6}>
                <div style={{ position: "relative" }}>
                  <input className={classes.tagSelectStyle} onChange={handleSearchBoxChange} value={searchValue} />
                  <label className={classes.tagLabel}>Search for a Product</label>
                </div>
              </Grid>
              <Grid lg={3} md={6} sm={6} xs={6}>
                <button className={classes.searchButtonStyle} onClick={handleSearchButtonClick}>
                  Search
                </button>
              </Grid>
            </Grid>
          </Container>
        </Toolbar>
      </AppBar>
      <Container>
        {/* <Typography variant="h4" className={classes.productHeading}>
          PRODUCT <span className={classes.textYellow}>CATEGORY</span>
        </Typography> */}
        {/* <Carousel
          responsive={responsive}
          autoPlay={true}
          autoPlaySpeed={4000}
          infinite={true}
          removeArrowOnDeviceType={["tablet", "mobile"]}
        >
          {productCategoryList.map((list) => (
            <div
              className={classes.container}
              key={list.id}
              onClick={() => {
                localStorage.setItem("productCategoryId", list.id);
                setCatId(list.id);
                setTagName("");
                setTagValue("");
                setSearchValue("");
                setSearchTerm("");
                // window.location.href = window.location.href;
              }}
            >
              <img src={list.categoryImage} alt={list.categoryName} className={classes.imageProductCategory} />
              <div class={classes.centered}>{list.categoryName}</div>
            </div>
          ))}
        </Carousel> */}
        <ShopProductList
          productCategoryId={catPrimaryId}
          tagName={tagName}
          searchTerm={searchTerm}
          onClickEvent={() => {
            if (localStorage.getItem("token")) {
              setAddressDialogOpen(true);
            } else {
              setOpenLoginDialogState(true);
            }
          }}
        />
      </Container>
      <Footer />
      <LoginHandleShop2
        openLoginDialog={openLoginDialogState}
        onClick={() => {
          setOpenLoginDialogState(false);
        }}
      />
      <Dialog fullScreen={fullScreen} open={addressDialogOpen} onBackdropClick="false">
        <Box display="flex" alignItems="center">
          <Box display="flex" justifyContent="flex-start" m={1} p={1}>
            <KeyboardBackspaceIcon onClick={handleClose} />
          </Box>
          <Box display="flex" justifyContent="center">
            <DialogTitle id="form-dialog-title">Enter Your Address and Select Payment Mode</DialogTitle>
          </Box>
          <Box display="flex" justifyContent="flex-end" m={1} p={1}>
            <HighlightOffIcon onClick={handleClose} />
          </Box>
        </Box>
        <Divider />
        <form onSubmit={handleProductOrderForm}>
          <div className={classes.inputSetStyle}>
            <Typography className={classes.labelStyle}>
              Mobile Number<span className={classes.star}>*</span>
            </Typography>
            <input
              type="text"
              name="phone"
              required
              className={classes.inputStyle}
              onChange={handleProductOrderFormChange}
            />
            <Typography className={classes.labelStyle}>
              Address Line 1<span className={classes.star}>*</span>
            </Typography>
            <input
              type="text"
              name="address1"
              required
              className={classes.inputStyle}
              onChange={handleProductOrderFormChange}
            />
            <Typography className={classes.labelStyle}>Address Line 2</Typography>
            <input type="text" name="address2" className={classes.inputStyle} onChange={handleProductOrderFormChange} />
            <Typography className={classes.labelStyle}>
              City<span className={classes.star}>*</span>
            </Typography>
            <input
              type="text"
              name="city"
              required
              className={classes.inputStyle}
              onChange={handleProductOrderFormChange}
            />
            <Typography className={classes.labelStyle}>
              Pincode<span className={classes.star}>*</span>
            </Typography>
            <input
              type="text"
              name="pincode"
              value={localStorage.getItem("shopPincode")}
              required
              className={classes.inputStyle}
              onChange={handleProductOrderFormChange}
            />
            <Typography className={classes.labelStyle}>
              Landmark<span className={classes.star}>*</span>
            </Typography>
            <input
              type="text"
              name="landmark"
              required
              className={classes.inputStyle}
              onChange={handleProductOrderFormChange}
            />
            <Typography className={classes.labelStyle}>
              Payment Mode<span className={classes.star}>*</span>
            </Typography>
            <select name="payment" className={classes.inputStyle} onChange={handleProductOrderFormChange} required>
              <option value="">Select your Payment Mode</option>
              <option value="1">Online Payment</option>
              <option value="2">Cash on Delivery</option>
            </select>
            <br></br>
          </div>
          <DialogActions>
            <button type="submit" variant="contained" className={classes.formButtonStyle}>
              Place Order
            </button>
          </DialogActions>
        </form>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={paymentDialogOpen} onBackdropClick="false">
        <Card>
          <CardContent>
            <Typography className={classes.paymentDialogTitle} variant="h6" id="tableTitle" component="div">
              {productOrderResFromDB && productOrderResFromDB.paymentMode == 1
                ? "Proceed to Payment"
                : "Your Order Placed Successfully"}
            </Typography>
            <TableRow style={{ width: "100%" }}>
              <TableCell component="th" scope="row">
                Order ID:
              </TableCell>
              <TableCell align="left">{productOrderResFromDB && productOrderResFromDB.orderId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Cost:
              </TableCell>
              <TableCell align="left">{productOrderResFromDB && productOrderResFromDB.totalOrderPrice}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Payment Mode:
              </TableCell>
              <TableCell align="left">
                {productOrderResFromDB && productOrderResFromDB.paymentMode == 1
                  ? "Online Payment"
                  : "Cash on Delivery"}
              </TableCell>
            </TableRow>
            <Box display="flex" justifyContent="flex-end">
              {paymentModeForButton === 1 ? (
                <button onClick={paymentInitialize} className={classes.formButtonStyle2}>
                  Proceed Payment
                </button>
              ) : paymentModeForButton === 2 ? (
                <button
                  onClick={() => {
                    setPaymentDialogOpen(false);
                    window.open(`orders/my-orders/${productOrderResFromDB && productOrderResFromDB.orderId}`, "_self");
                  }}
                  className={classes.formButtonStyle2}
                >
                  View Booking
                </button>
              ) : null}
            </Box>
          </CardContent>
        </Card>
      </Dialog>
      <Dialog fullScreen={fullScreen} open={paymentDialogOpen} onBackdropClick="false">
        <Card>
          <CardContent>
            <Typography className={classes.paymentDialogTitle} variant="h6" id="tableTitle" component="div">
              Payment Successful
            </Typography>
            <TableRow style={{ width: "100%" }}>
              <TableCell component="th" scope="row">
                Order ID:
              </TableCell>
              <TableCell align="left">{productOrderResFromDB && productOrderResFromDB.orderId}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Total Cost:
              </TableCell>
              <TableCell align="left">{productOrderResFromDB && productOrderResFromDB.totalOrderPrice}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                Payment Mode:
              </TableCell>
              <TableCell align="left">
                {productOrderResFromDB && productOrderResFromDB.paymentMode == 1
                  ? "Online Payment"
                  : "Cash on Delivery"}
              </TableCell>
            </TableRow>
            <Box display="flex" justifyContent="flex-end">
              {paymentModeForButton === 1 ? (
                <button onClick={paymentInitialize} className={classes.formButtonStyle2}>
                  Proceed Payment
                </button>
              ) : paymentModeForButton === 2 ? (
                <button
                  onClick={() => {
                    setPaymentDialogOpen(false);
                    window.open(`orders/my-orders/${productOrderResFromDB && productOrderResFromDB.orderId}`, "_self");
                  }}
                  className={classes.formButtonStyle2}
                >
                  View Booking
                </button>
              ) : null}
            </Box>
          </CardContent>
        </Card>
      </Dialog>
    </div>
  );
}

export default Shop;
