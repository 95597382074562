import React from "react";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import BlogImg from "./../../../Images/b2.png";
// import Image from 'material-ui-image';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));
function BlogLeft() {
  const classes = useStyles();


  return (
    <div className="b-left roboto py-4">
        <h2>Categories</h2>

        <List component="nav" aria-label="main mailbox folders">
        <ListItem button>Engine Repair</ListItem>
        <ListItem button>Electrical</ListItem>
        <ListItem button>Plumbing</ListItem>
        <ListItem button>Wall Paint</ListItem>
        <ListItem button>Installation</ListItem>
        <ListItem button>Garden Repair</ListItem>
        <ListItem button>Home Maintenance</ListItem>
            </List>

            <h2>Recent News </h2>

            <List className={classes.root}>
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={BlogImg} />
        </ListItemAvatar>
        <ListItemText
          primary="Lorem lacus the semper."
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
               14 Jun, 2021 
              </Typography>
             
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={BlogImg} />
        </ListItemAvatar>
        <ListItemText
          primary="Curabitur in rutrum leo"
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
               14 Jun, 2021 
              </Typography>
             
            </React.Fragment>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
      <ListItem alignItems="flex-start">
        <ListItemAvatar>
          <Avatar alt="Remy Sharp" src={BlogImg} />
        </ListItemAvatar>
        <ListItemText
          primary="Donec porta libeo rhoncus."
          secondary={
            <React.Fragment>
              <Typography
                component="span"
                variant="body2"
                className={classes.inline}
                color="textPrimary"
              >
               14 Jun, 2021 
              </Typography>
             
            </React.Fragment>
          }
        />
      </ListItem>
    </List>

    



    </div>
  );
}

export default BlogLeft;
