import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Container,
  Typography,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  useMediaQuery,
  TextField,
  Chip,
} from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import moment from "moment";
import Footer from "../../HomePage/Footer/Footer";
import LogoImg from "../../../Images/hii.png";

let headers = {
  Authorization: `Bearer ${localStorage.getItem("token")}`,
};

let token = localStorage.getItem("token");

function BookingTicket() {
  const { ticketId } = useParams();
  const [bookings, setBookings] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { register, handleSubmit } = useForm();
  const { register: register1, handleSubmit: handleSubmit1 } = useForm();
  const [dateTimeError, setDateTimeError] = useState("");
  const [openPayment, setOpenPayment] = useState(false);
  const [currentRequestId, setCurrentRequestId] = useState("");

  useEffect(() => {
    if (token) {
      axios.get("https://admin.hibroservices.com/api/serviceRequest/getMyBookings/upcoming", { headers }).then(
        (response) => {
          let resu = response.data.result;
          console.log(resu);
          for (let i = 0; i < resu.length; i++) {
            let sum = 19;
            console.log(resu[i].serviceOptin);
            for (let j = 0; j < resu[i].serviceOptin.length; j++) {
              sum += resu[i].serviceOptin[j]["serviceId"]["serviceChargeMin"];
            }
            resu[i]["totalAmount"] = sum;
            sum = 19;
          }
          console.log(resu);
          setBookings(resu);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, []);

  function handleClickOpen() {
    setOpen(true);
    setDateTimeError("");
  }

  function handleClickOpenCancel() {
    setOpenCancel(true);
    setDateTimeError("");
  }

  function handleClose() {
    setOpen(false);
  }

  function handleCloseCancel() {
    setOpenCancel(false);
  }

  function handlePaymentOpen() {
    setOpenPayment(true);
  }

  function handlePaymentClose() {
    setOpenPayment(false);
  }

  let listOfDays = [];
  for (let i = 0; i < 12; i++) {
    let curDate = moment().add(i, "days");
    listOfDays.push({
      date: curDate.format("DD"),
      day: curDate.format("ddd"),
      value: curDate.format("DD-MM-YYYY"),
    });
  }

  const [selectedDay, setSelectedDay] = useState({});
  const [selectedTime, setSelectedTime] = useState({});
  const [listOfHours, setListOfHours] = useState([]);

  function timeSlots(date = null) {
    console.log(date);
    let startTime;
    if (date) {
      startTime = moment(date, "DD-MM-YYYY").hour(9).minute(0).second(0);
    } else {
      startTime = moment().hour(9).minute(0).second(0);
    }
    setListOfHours([]);
    let temp = [];
    for (let i = 0; i < 11; i++) {
      temp.push({
        display: startTime.format("h A"),
        time: startTime.format("hh:mm a"),
        isEnabled: startTime.isSameOrAfter(moment().add(3, "h")),
      });
      console.log(startTime.isSameOrAfter(moment().add(3, "h")));
      startTime.add(1, "h");
    }
    setListOfHours(temp);
  }

  useEffect(() => {
    timeSlots();
  }, []);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function paymentInitialize() {
    const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }
    let headers = {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    };
    const result = await axios.post(
      "https://admin.hibroservices.com/api/serviceRequest/initBalancePayment",
      { requestId: currentRequestId },
      { headers }
    );

    if (!result) {
      alert("Server error. Are you online?");
      return;
    }

    console.log(result);

    const respond = result.data.result;

    if (result.data.success) {
      // setPaymentPage("none");
      setOpenPayment(false);
      const options = {
        key: respond.key,
        amount: respond.amount,
        currency: respond.currency,
        name: respond.name,
        description: respond.description,
        image: LogoImg,
        order_id: respond.id,
        handler: async function (response) {
          console.log(response);
          const data = {
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
            requestId: currentRequestId,
          };

          const resultSuccess = await axios.post(
            "https://admin.hibroservices.com/api/serviceRequest/updatePaymentDetails/success",
            data,
            { headers }
          );
          console.log(resultSuccess);
          setOpen(true);
          // setShowExistingAddressDialog("none");
          // setShowAddressDialog("none");
          // setShowDateDialog("none");
          // setShowPaymentDialog("none");
          // setBookingTicketStatus("block");
          // setPaymentPage("none");
        },
        prefill: {
          name: respond.userName,
          email: "example@gmail.com",
          contact: respond.phoneNumber,
        },
        notes: {
          address: "Hibro Corporate Office",
        },
        theme: {
          color: "#61dafb",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.on("payment.failed", function (response) {
        console.log(response);
        axios
          .post(
            "https://admin.hibroservices.com/api/serviceRequest/updatePaymentDetails/failure",
            {
              requestId: currentRequestId,
              razorpayOrderId: response.error.metadata.order_id,
              errorDetails: {
                code: response.error.code,
                description: response.error.description,
                source: response.error.source,
                step: response.error.step,
                reason: response.error.reason,
                metadata: {
                  payment_id: response.error.metadata.payment_id,
                  order_id: response.error.metadata.order_id,
                },
              },
            },
            { headers }
          )
          .then(
            (responseError) => {
              console.log(responseError);
              if (responseError.data.success === false) {
                setOpenPayment(true);
                // setShowExistingAddressDialog("none");
                // setShowAddressDialog("none");
                // setShowDateDialog("none");
                // setShowPaymentDialog("none");
                // setBookingTicketStatus("block");
                // setPaymentPage("none");
              }
            },
            (error) => {
              console.log(error);
            }
          );
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });
      paymentObject.open();
    }
  }

  return (
    <div>
      {bookings.map((booking, index) => (
        <div key={booking.requestOrderNumber}>
          {booking.requestOrderNumber === ticketId && (
            <div>
              <div className="bookingTicketHeading">
                <Container>
                  <Typography variant="h4" component="h2" className="bookingTicketHeadingText oswald">
                    <span className="blue">UPCOMING SERVICE </span>BOOKING TICKET
                  </Typography>
                </Container>
              </div>
              <Divider style={{ borderBottom: "2px solid #aaa" }} />
              <Container>
                <Grid container spacing={2} style={{ margin: "30px 0px 30px" }}>
                  <Grid item md={6}>
                    <div style={{ width: "100%" }}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#0066ff", color: "#fff", marginRight: "20px", width: "90%" }}
                            onClick={handleClickOpen}
                          >
                            Reschedule
                          </Button>
                        </Box>
                        <Box flexGrow={1}>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#fff212", marginRight: "20px", width: "90%" }}
                            onClick={handleClickOpenCancel}
                          >
                            Cancel
                          </Button>
                        </Box>
                        <Box flexGrow={1}>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#0066ff", color: "#fff", marginRight: "20px", width: "90%" }}
                            href="tel:9962562562"
                          >
                            Call Us
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </Grid>
                  <Grid item md={6}>
                    <div style={{ width: "100%" }}>
                      <Box display="flex">
                        <Box flexGrow={1}>
                          <Chip
                            label={
                              <Typography>
                                Payment Due: ₹{" "}
                                {booking.totalBillableItem + booking.totalAmount - booking.totalPaymentMade}
                              </Typography>
                            }
                            style={{ backgroundColor: "#ccc", color: "#000", marginRight: "20px", width: "95%" }}
                          />
                        </Box>
                        <Box flexGrow={1}>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#0066ff", color: "#fff", marginRight: "20px", width: "90%" }}
                            onClick={() => {
                              handlePaymentOpen();
                              setCurrentRequestId(booking.serviceRequestId);
                            }}
                          >
                            Make Payment
                          </Button>
                        </Box>
                      </Box>
                    </div>
                  </Grid>
                </Grid>
                <Dialog
                  fullScreen={fullScreen}
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                  onBackdropClick="false"
                >
                  <form
                    key={1}
                    noValidate
                    onSubmit={handleSubmit((data) => {
                      if (data.reason) {
                        if (selectedDay.value) {
                          if (selectedTime.time) {
                            let temp = selectedDay.value + " " + selectedTime.time;
                            // setDateTime(moment(temp, "DD-MM-YYYY hh:mm a").format());
                            axios
                              .post(
                                "https://admin.hibroservices.com/api/serviceRequest/updateTicketStatus/" +
                                  booking.serviceRequestId,
                                {
                                  ticketStatusId: 3,
                                  reason: data.reason,
                                  rescheduledDateTime: moment(temp, "DD-MM-YYYY hh:mm a").format(),
                                },
                                { headers }
                              )
                              .then(
                                (response) => {
                                  console.log(response);
                                  setOpen(false);
                                },
                                (error) => {
                                  console.log(error);
                                }
                              );
                          } else {
                            setDateTimeError("Please Select Time of Service");
                          }
                        } else {
                          setDateTimeError("Please Select Date of Service");
                        }
                      } else {
                        setDateTimeError("Need Reason for Reschedule");
                      }
                    })}
                  >
                    <Box className="width500px">
                      <Grid container>
                        <Grid item xs={2}>
                          <Box display="flex" justifyContent="flex-start" m={1} p={1}>
                            <KeyboardBackspaceIcon onClick={handleClose} />
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Box display="flex" justifyContent="center">
                            <DialogTitle id="form-dialog-title">Reschedule Your Service</DialogTitle>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                            <HighlightOffIcon onClick={handleClose} />
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider />
                      <DialogContent>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          multiline
                          inputRef={register}
                          required
                          fullWidth
                          name="reason"
                          label="Reason for Reschedule"
                          type="text"
                          id="text"
                          style={{ marginBottom: "20px" }}
                        />
                        <DialogContentText>Select Date of Service</DialogContentText>
                        <div>
                          <Box mb={3}>
                            <Grid container spacing={2}>
                              {listOfDays.map((day) => (
                                <Grid item md={2} sm={4} xs={4}>
                                  <Card
                                    className={selectedDay.date === day.date ? "cardActive" : "card"}
                                    onClick={() => {
                                      setSelectedDay(day);
                                      timeSlots(day.value);
                                      setDateTimeError("");
                                    }}
                                  >
                                    <CardContent>
                                      <Typography align="center">{day.day}</Typography>
                                      <Typography align="center">{day.date}</Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </div>
                        <DialogContentText>Select Time of Service</DialogContentText>
                        <div>
                          <Box mb={3}>
                            <Grid container spacing={2}>
                              {listOfHours.map((hour) => (
                                <Grid item md={3} sm={4} xs={4}>
                                  <Card
                                    className={
                                      hour.isEnabled
                                        ? selectedTime.time === hour.time
                                          ? "cardActive"
                                          : "card"
                                        : "cardDisable"
                                    }
                                    onClick={() => {
                                      setSelectedTime(hour);
                                      setDateTimeError("");
                                    }}
                                  >
                                    <CardContent>
                                      <Typography align="center">{hour.display}</Typography>
                                    </CardContent>
                                  </Card>
                                </Grid>
                              ))}
                            </Grid>
                          </Box>
                        </div>
                      </DialogContent>
                      <DialogActions>
                        <Typography align="center" style={{ color: "red" }}>
                          {dateTimeError}
                        </Typography>
                        <Button autoFocus onClick={handleClose} style={{ color: "#0066FF" }}>
                          Cancel
                        </Button>
                        <Button
                          type="submit"
                          autoFocus
                          variant="contained"
                          style={{ backgroundColor: "#0066FF", color: "#fff" }}
                        >
                          Reschedule
                        </Button>
                      </DialogActions>
                    </Box>
                  </form>
                </Dialog>
                <Dialog
                  fullScreen={fullScreen}
                  open={openCancel}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                  onBackdropClick="false"
                >
                  <form
                    key={1}
                    noValidate
                    onSubmit={handleSubmit1((data) => {
                      if (data.reasonForCancel) {
                        axios
                          .post(
                            "https://admin.hibroservices.com/api/serviceRequest/updateTicketStatus/" +
                              booking.serviceRequestId,
                            {
                              ticketStatusId: 5,
                              reason: data.reasonForCancel,
                            },
                            { headers }
                          )
                          .then(
                            (response) => {
                              console.log(response);
                              setOpenCancel(false);
                              window.location.replace(
                                "http://hibroservices.com/navbar/options/my-bookings/history/" +
                                  booking.requestOrderNumber
                              );
                            },
                            (error) => {
                              console.log(error);
                            }
                          );
                      } else {
                        setDateTimeError("Need Reason for Cancellation");
                      }
                    })}
                  >
                    <Box className="width500px">
                      <Grid container>
                        <Grid item xs={2}>
                          <Box display="flex" justifyContent="flex-start" m={1} p={1}>
                            <KeyboardBackspaceIcon onClick={handleCloseCancel} />
                          </Box>
                        </Grid>
                        <Grid item xs={8}>
                          <Box display="flex" justifyContent="center">
                            <DialogTitle id="form-dialog-title">Cancel Service</DialogTitle>
                          </Box>
                        </Grid>
                        <Grid item xs={2}>
                          <Box display="flex" justifyContent="flex-end" m={1} p={1}>
                            <HighlightOffIcon onClick={handleCloseCancel} />
                          </Box>
                        </Grid>
                      </Grid>
                      <Divider />
                      <DialogContent>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          multiline
                          inputRef={register1}
                          required
                          fullWidth
                          name="reasonForCancel"
                          label="Reason for Cancel"
                          type="text"
                          id="text"
                        />
                      </DialogContent>
                      <DialogActions>
                        <Typography align="center" style={{ color: "red" }}>
                          {dateTimeError}
                        </Typography>
                        <Button
                          type="submit"
                          autoFocus
                          variant="contained"
                          style={{ backgroundColor: "#0066FF", color: "#fff" }}
                        >
                          Cancel Order
                        </Button>
                      </DialogActions>
                    </Box>
                  </form>
                </Dialog>
                <Dialog
                  fullScreen={fullScreen}
                  open={openPayment}
                  onClose={handleClose}
                  aria-labelledby="form-dialog-title"
                  onBackdropClick="false"
                >
                  <Box className="width500px" style={{ textAlign: "center", minHeight: "400px" }}>
                    <Grid container>
                      <Grid item xs={2}>
                        <Box
                          display="flex"
                          justifyContent="flex-start"
                          alignItems="center"
                          style={{ height: "100%", marginLeft: "20px" }}
                        ></Box>
                      </Grid>
                      <Grid item xs={8}>
                        <Box display="flex" justifyContent="center">
                          <DialogTitle id="form-dialog-title">Online Payment</DialogTitle>
                        </Box>
                      </Grid>
                      <Grid item xs={2}>
                        <Box
                          display="flex"
                          justifyContent="flex-end"
                          alignItems="center"
                          style={{ height: "100%", marginRight: "20px" }}
                        >
                          <HighlightOffIcon onClick={handlePaymentClose} />
                        </Box>
                      </Grid>
                    </Grid>
                    <Divider />
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ margin: "20px 20px 20px 20px", minWidth: "335px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            Payment Details
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="body1" component="h2" display="inline">
                              Total Amount for Service
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="body1"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">₹{booking.totalAmount}</span>
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="body1" component="h2" display="inline">
                              Total Amount for Hardware
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="body1"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">₹{booking.totalBillableItem}</span>
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="body1" component="h2" display="inline">
                              Payment Received
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="body1"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">₹{booking.totalPaymentMade}</span>
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="body1" component="h2" display="inline">
                              Balance to be Paid
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="body1"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">
                                ₹{booking.totalBillableItem + booking.totalAmount - booking.totalPaymentMade}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        <Divider style={{ marginBottom: "50px" }} />
                        <Box flexGrow={1}>
                          <Button
                            variant="contained"
                            style={{ backgroundColor: "#0066ff", color: "#fff", width: "90%" }}
                            onClick={() => {
                              paymentInitialize();
                            }}
                          >
                            Payment Via Online ₹
                            {booking.totalBillableItem + booking.totalAmount - booking.totalPaymentMade}
                          </Button>
                        </Box>
                      </CardContent>
                    </Card>
                  </Box>
                </Dialog>
                <Grid container spacing={5} justify="center">
                  <Grid item md={6}>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            SERVICE DETAILS
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                          Service ID: {<span className="blue">{booking.requestOrderNumber}</span>}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          Service Status: {<span className="blue">{booking.ticketStatus}</span>}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          Message: <span className="blue">Booking Confirmed by HiBro Team!!!</span>
                        </Typography>
                      </CardContent>
                    </Card>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            SERVICE LOCATION
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                          Address: {<span className="blue">{booking.address}</span>}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          Pincode: {<span className="blue">{booking.pincode}</span>}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          Date and Time: {<span className="blue">{moment(booking.serviceDateTime).format("lll")}</span>}
                        </Typography>
                        {booking.rescheduledDateTime ? (
                          <Typography gutterBottom variant="h6" component="h2">
                            Rescheduled Date and Time:{" "}
                            {<span className="blue">{moment(booking.rescheduledDateTime).format("lll")}</span>}
                          </Typography>
                        ) : null}
                      </CardContent>
                    </Card>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            CUSTOMER DETAILS
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h6" component="h2">
                          Customer Name: {<span className="blue">{booking.customerName}</span>}
                        </Typography>
                        <Typography gutterBottom variant="h6" component="h2">
                          Contact Number: {<span className="blue">{booking.phoneNumber}</span>}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item md={6}>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px", minWidth: "325px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            SERVICE + HARDWARE CART
                          </Typography>
                        }
                      />
                      <CardContent>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Grand Total
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">₹{booking.totalBillableItem + booking.totalAmount}</span>
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Payment Received
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">₹{booking.totalPaymentMade}</span>
                            </Typography>
                          </Box>
                        </Box>
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Payment Due
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">
                                ₹{booking.totalBillableItem + booking.totalAmount - booking.totalPaymentMade}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                        {/* <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Payment Mode
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              Online or COD
                            </Typography>
                          </Box>
                        </Box> */}
                      </CardContent>
                    </Card>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px", minWidth: "335px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            SERVICE CART
                          </Typography>
                        }
                      />
                      <CardContent>
                        {booking.serviceOptin.map((serviceOpt, index) => (
                          <div key={serviceOpt.serviceId.serviceId}>
                            <Box display="flex" alignItems="center">
                              <Box flexGrow={1} alignItems="center">
                                <Typography gutterBottom variant="h6" component="h2" display="inline">
                                  {serviceOpt.serviceId.serviceName}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems="center">
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="h2"
                                  display="inline"
                                  style={{ marginRight: "20px" }}
                                >
                                  <span className="blue">₹{serviceOpt.serviceId.serviceChargeMin}</span>
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                          </div>
                        ))}
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Booking Charge
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">₹19</span>
                            </Typography>
                          </Box>
                        </Box>
                        <Divider />
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Total Amount
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">₹{booking.totalAmount}</span>
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                    <Card
                      key={booking.requestOrderNumber}
                      id={booking.requestOrderNumber}
                      style={{ marginBottom: "20px" }}
                    >
                      <CardHeader
                        style={{ backgroundColor: "#0066FF", color: "#fff", textAlign: "center" }}
                        title={
                          <Typography gutterBottom variant="h5" component="h2" noWrap>
                            HARDWARE CART
                          </Typography>
                        }
                      />
                      <CardContent>
                        {booking.billableItems.map((billableItem, index) => (
                          <div key={billableItem.billableItemId}>
                            <Box display="flex" alignItems="center">
                              <Box flexGrow={1} alignItems="center">
                                <Typography gutterBottom variant="h6" component="h2" display="inline">
                                  {billableItem.particular}
                                </Typography>
                              </Box>
                              <Box display="flex" alignItems="center">
                                <Typography
                                  gutterBottom
                                  variant="h6"
                                  component="h2"
                                  display="inline"
                                  style={{ marginRight: "20px" }}
                                >
                                  <span className="blue">₹{billableItem.price}</span>
                                </Typography>
                              </Box>
                            </Box>
                            <Divider />
                          </div>
                        ))}
                        <Box display="flex" alignItems="center">
                          <Box flexGrow={1} alignItems="center">
                            <Typography gutterBottom variant="h6" component="h2" display="inline">
                              Total Amount
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center">
                            <Typography
                              gutterBottom
                              variant="h6"
                              component="h2"
                              display="inline"
                              style={{ marginRight: "20px" }}
                            >
                              <span className="blue">₹{booking.totalBillableItem}</span>
                            </Typography>
                          </Box>
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
              {/* <h1>Address: {booking.address}</h1>
              <h1>AreaName: {booking.areaName}</h1>
              <h1>BranchName: {booking.branchName}</h1>
              <h1>OrderCreatedAt: {booking.createdAt}</h1>
              <h1>CustomerName: {booking.customerName}</h1>
              <h1>MapCoords: {booking.mapCoords}</h1>
              <h1>PhoneNumber: {booking.phoneNumber}</h1>
              <h1>Pincode: {booking.pincode}</h1>
              <h1>RequestOrderNumber: {booking.requestOrderNumber}</h1>
              <h1>ServiceDateTime: {booking.serviceDateTime}</h1>
              <h1>ServiceRequestId: {booking.serviceRequestId}</h1>
              <h1>TicketStatus: {booking.ticketStatus}</h1>
              <h1>TotalAmount: {booking.totalAmount}</h1>
              <h1>TotalBillableItem: {booking.totalBillableItem}</h1>
              <h1>TotalPaymentMode: {booking.totalPaymentMade}</h1>
              <h1>
                {booking.serviceOptin.map((serviceOpt, index) => (
                  <div key={serviceOpt.serviceId.serviceId}>
                    <div>ServiceId: {serviceOpt.serviceId.serviceId}</div>
                    <div>ServiceName: {serviceOpt.serviceId.serviceName}</div>
                    <div>BookingCharges: {serviceOpt.serviceId.bookingCharges}</div>
                    <div>ServieChargeMin: {serviceOpt.serviceId.serviceChargeMin}</div>
                    <div>ServiceChargeMax: {serviceOpt.serviceId.serviceChargeMax}</div>
                  </div>
                ))}
              </h1>
              <h1>
                {booking.billableItems.map((billableItem, index) => (
                  <div key={billableItem.billableItemId}>
                    <div>BillableItemId: {billableItem.billableItemId}</div>
                    <div>BillableItemName: {billableItem.particular}</div>
                    <div>BillableItemPrice: {billableItem.price}</div>
                  </div>
                ))}
              </h1> */}
            </div>
          )}
        </div>
      ))}
      <Footer />
    </div>
  );
}

export default BookingTicket;
