import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import SingleBookingCardHistory from "./SingleBookingCardHistory"; 

export default function MyBookingsUpcomingTicketCard() {
  return (
    <Container style={{ marginBottom: "50px" }}>
      <Box display="flex" justifyContent="center">
      <h1 className="oswald headingTerms">
          <span className="blue">BOOKING</span> HISTORY
        </h1>
      </Box>
      <SingleBookingCardHistory />
    </Container>
  );
}
