import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import SingleBookingCard from "./SingleBookingCard";

export default function MyBookingsUpcomingTicketCard() {
  return (
    <Container style={{ marginBottom: "50px" }} className="oswald">
      <Box display="flex" justifyContent="center">
        <h1 className="oswald headingTerms">
          <span className="blue">UPCOMING</span> BOOKINGS
        </h1>
      </Box>

      <SingleBookingCard />
    </Container>
  );
}
