import { Box, Button, Card, CardContent, Divider, Typography } from "@material-ui/core";
import classes from "./SingleProductCard.module.css";
// import StarIcon from "@material-ui/icons/Star";

function SingleProductCard(prop) {
  return (
    <div className={classes.root}>
      <Card>
        <CardContent>
          <Box display="flex">
            <Box display="flex" alignItems="center" flexGrow={1}>
              <img className={classes.productImage} src={prop.productImage} alt="productImage" />
              <div>
                <Typography className={classes.productTitle}>{prop.productName}</Typography>
                {/* <Typography className={classes.productStarRating}>
                  <Box display="flex" alignItems="center">
                    <StarIcon /> {prop.starRating}
                  </Box>
                </Typography> */}
                {/* <Typography className={classes.productBrand}>
                  Brand Name: <span className={classes.productBrandName}>{prop.brandName}</span>
                </Typography> */}
                <Typography className={classes.productPrice}>
                  <span className="cross">₹{prop.productOriginalPrice}</span>
                  <span className={classes.productPriceStyle}>₹{prop.productPrice}</span>
                </Typography>
              </div>
            </Box>
            <Box>
              <Button variant="contained" className={classes.productAddButton} onClick={() => prop.onAdd(prop.el)}>
                Add
              </Button>
            </Box>
          </Box>
        </CardContent>
        <Divider />
        <CardContent>
          <Typography className={classes.aboutProductHeading}>About Product</Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {prop.productDescription}
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
}

export default SingleProductCard;
