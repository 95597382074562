import React from "react";
import { Typography, Divider, Box, Link } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";

function CartSingleService(props) {
  return (
    <div>
      <div style={{ height: "100%", padding: "10px 0px" }}>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1} alignItems="center">
            <Typography display="inline">{props.serviceName}</Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography display="inline" style={{ marginLeft: "10px" }}>
              ₹{props.price}
            </Typography>
            <Link color="inherit" underline="none" onClick={() => props.onDelete(props.el)}>
              <DeleteIcon style={{ marginLeft: "10px", cursor: "pointer" }} />
            </Link>
          </Box>
        </Box>
      </div>
      <Divider />
    </div>
  );
}

export default CartSingleService;
