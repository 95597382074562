import React from "react";
// import Logo from "./Logo";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ContactBanner from "../../../../Images/contact.png";
// import Navbar from "../Navbar/NavBar";

function Header() {
  return (
    <div className="con-banner" style={{ backgroundImage: `url(${ContactBanner})` }}>
      {/* <img src={HomeBanner} alt="Home Banner" /> */}
      <Container>

      <Grid container spacing={1}>
          <Grid item xs={6}>
            </Grid>
            </Grid>

        {/* <Logo /> */}
        {/* <Navbar /> */}
      </Container>
    </div>
  );
}

export default Header;
