import React from "react";
import Paper from "@material-ui/core/Paper";
import CardContent from "@material-ui/core/CardContent";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Typography from "@material-ui/core/Typography";
import StarIcon from "@material-ui/icons/Star";
import { Button, Grid, Box } from "@material-ui/core";

export default function SingleServiceTab(props) {
  return (
    <Paper elevation={3} style={{ marginBottom: "25px" }}>
      <ListItem>
        <ListItemAvatar>
          <Avatar
            variant="rounded"
            src={props.image}
            alt="Plumber"
            style={{ height: "70px", width: "80px", margin: "15px 0px 10px" }}
          />
        </ListItemAvatar>
        <ListItemText style={{ marginLeft: "20px" }}>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <Typography>{props.serviceName}</Typography>
              <Typography>
                <Box display="flex" alignItems="center">
                  <Box style={{ color: "#0066ff" }}>
                    <StarIcon />
                  </Box>
                  <Box style={{ color: "#0066ff" }}> {props.starRating}</Box>
                </Box>
              </Typography>
              {/* {props.priceBeforeDiscount ? (
                <Typography className="cross">{props.priceBeforeDiscount}</Typography>
              ) : null} */}
              <Typography>
                {/* <span className="cross">{props.priceBeforeDiscount}</span> */}₹{props.minPrice} - {props.maxPrice}{" "}
                <span style={{ color: "#0066ff" }}>{props.inspectionCharge}</span>
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Box display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#0066ff",
                    color: "#fff",
                    top: "8px",
                    left: "0px",
                  }}
                  onClick={() => props.onAdd(props.el)}
                >
                  ADD
                </Button>
              </Box>
            </Grid>
          </Grid>
        </ListItemText>
      </ListItem>
      <Divider />
      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {props.description}
        </Typography>
        <br />
        <Typography variant="body2" color="textSecondary" component="p">
          {props.note}
        </Typography>
      </CardContent>
    </Paper>
  );
}
