import { Box, Grid, Typography } from "@material-ui/core";
import SingleProductCard from "./SingleProductCard";
import ProductCart from "./ProductCart";
import classes from "./ShopProductList.module.css";
import { useEffect, useState } from "react";
import axios from "axios";
import image from "../../../../../Images/b1.png";
import CartSingleProduct from "./CartSingleProduct";
import { Pagination } from "@material-ui/lab";

function ShopProductList(props) {
  const [productList, setProductList] = useState([]);
  const [productCount, setProductCount] = useState(0);
  // const [cartItems, setCartItems] = useState([]);
  const [cartItems2, setCartItems2] = useState([]);
  const [alert, setAlert] = useState("");
  const [cartTotal, setCartTotal] = useState(0);
  const [productId, setProductId] = useState([]);
  const [pageValue, setPageValue] = useState(0);
  const [prodCnt, setProdCnt] = useState(1);

  // let productCategoryId = localStorage.getItem("productCategoryId");

  useEffect(() => {
    const getProductsFilters = {
      pageNo: pageValue,
      limit: 20,
      pincode: localStorage.getItem("shopPincode"),
      // serviceCategory: localStorage.getItem("productCategoryId"),
      serviceCategory: props.productCategoryId !== "" ? props.productCategoryId : null,
      tag: props.tagName !== "" ? props.tagName : null,
      searchTerm: props.searchTerm !== "" ? props.searchTerm : null,
    };
    axios
      .post("https://admin.hibroservices.com/api/vendor/getAllProductsWithFilter", getProductsFilters)
      .then((res) => {
        if (res.data.result.productCount % 20 > 10 || res.data.result.productCount % 20 === 0) {
          setProductCount(Math.round(res.data.result.productCount / 20));
        } else {
          setProductCount(Math.round(res.data.result.productCount / 20) + 1);
        }
        if (res.data.result) {
          setProductList(res.data.result.productsList);
          console.log(res.data.result.productsList);
          console.log(res);
        }
      });
  }, [pageValue, props.productCategoryId, props.tagName, props.searchTerm]);

  function getProductList() {
    const getProductsFilters = {
      pageNo: pageValue,
      limit: 20,
      pincode: localStorage.getItem("shopPincode"),
      // serviceCategory: localStorage.getItem("productCategoryId"),
      serviceCategory: null,
      tag: props.tagName !== "" ? props.tagName : null,
      searchTerm: props.searchTerm !== "" ? props.searchTerm : null,
    };
    axios
      .post("https://admin.hibroservices.com/api/vendor/getAllProductsWithFilter", getProductsFilters)
      .then((res) => {
        if (res.data.result.productCount % 20 > 10 || res.data.result.productCount % 20 === 0) {
          setProductCount(Math.round(res.data.result.productCount / 20));
        } else {
          setProductCount(Math.round(res.data.result.productCount / 20) + 1);
        }
        if (res.data.result) {
          setProductList(res.data.result.productsList);
          console.log(res.data.result.productsList);
        }
      });
  }

  // useEffect(() => {
  //   const total = () => {
  //     let totalVal = 0;
  //     for (let i = 0; i < cartItems.length; i++) {
  //       totalVal += cartItems[i].price;
  //     }
  //     setCartTotal(totalVal);
  //   };
  //   total();
  // }, [cartItems]);

  const [domUpdate, setDomUpdate] = useState();
  useEffect(() => {
    const total = () => {
      let totalVal = 0;
      for (let i = 0; i < cartItems2.length; i++) {
        totalVal += cartItems2[i].price;
      }
      setCartTotal(totalVal);
    };
    total();
  }, [cartItems2, domUpdate]);

  const addToCart = (el) => {
    let addIt = true;
    for (let i = 0; i < cartItems2.length; i++) {
      if (cartItems2[i].data.productId === el.productId) addIt = false;
    }
    // for (let i = 0; i < cartItems2.length; i++) {
    //   if (cartItems2[i].data.productId === el.productId) {
    //     cartItems2[i].qty = cartItems2[i].qty + 1;
    //     cartItems2[i].price = cartItems2[i].price + el.price;
    //     setDomUpdate(cartItems2[i].price + el.price);
    //     console.log(cartItems2[i].qty);
    //     console.log(cartItems2);
    //     addIt = false;
    //   }
    // }
    if (addIt) {
      // setCartItems([...cartItems, el]);
      setCartItems2([...cartItems2, { qty: 1, data: el, price: el.price }]);
      setAlert("");
      setProductId([...productId, { qty: 1, productId: el.productId }]);
      localStorage.setItem("productCartIds", JSON.stringify([...productId, { qty: 1, productId: el.productId }]));
    } else {
      setAlert(`*${el.productName} is already in your cart`);
    }
  };

  const removeFromCart = (el) => {
    // let hardCopy = [...cartItems];
    let hardCopy = [...cartItems2];
    let hardCopyId = [...productId];
    hardCopy = hardCopy.filter((cartItem) => cartItem.data.productId !== el.productId);
    // setCartItems(hardCopy);
    setCartItems2(hardCopy);

    hardCopyId = hardCopyId.filter((cartItemId) => cartItemId.productId !== el.productId);
    setProductId(hardCopyId);
    setAlert("");
    localStorage.setItem("productCartIds", JSON.stringify(hardCopyId));
  };

  const increaseProductCount = (el) => {
    for (let i = 0; i < cartItems2.length; i++) {
      if (cartItems2[i].data.productId === el.data.productId) {
        cartItems2[i].qty = cartItems2[i].qty + 1;
        cartItems2[i].price = cartItems2[i].price + el.data.price;
        productId[i].qty = productId[i].qty + 1;
        localStorage.setItem("productCartIds", JSON.stringify(productId));
        setDomUpdate(cartItems2[i].price + el.data.price);
        console.log(cartItems2[i].qty);
        console.log(cartItems2);
      }
    }
  };

  const decreaseProductCount = (el) => {
    for (let i = 0; i < cartItems2.length; i++) {
      if (cartItems2[i].data.productId === el.data.productId) {
        cartItems2[i].qty = cartItems2[i].qty - 1;
        cartItems2[i].price = cartItems2[i].price - el.data.price;
        productId[i].qty = productId[i].qty - 1;
        localStorage.setItem("productCartIds", JSON.stringify(productId));
        setDomUpdate(cartItems2[i].price - el.data.price);
        console.log(cartItems2[i].qty);
        console.log(cartItems2);
      }
    }
  };

  function onClickEventFunction() {
    setAlert("Select atleast one product to checkout");
  }

  // let filteredCartItems = [];

  // for (var i = 0; i < cartItems2.length; i++) {
  //   filteredCartItems.push(cartItems2[i].data);
  // }

  // const cartProductList = cartItems.map((item) => (
  //   <CartSingleProduct
  //     key={item.productId}
  //     productName={item.productName}
  //     count={prodCnt}
  //     productPrice={item.price}
  //     onDelete={() => removeFromCart(item)}
  //     onDecrement={() => decreaseProdCnt(item)}
  //     onIncrement={() => increaseProdCnt(item)}
  //   />
  // ));

  const cartProductList = cartItems2.map((item) => (
    <CartSingleProduct
      key={item.data.productId}
      productName={item.data.productName}
      count={item.qty}
      productPrice={item.price}
      onDelete={() => removeFromCart(item.data)}
      onIncrement={() => increaseProductCount(item)}
      onDecrement={() => decreaseProductCount(item)}
    />
  ));

  const onPageChange = (event, value) => {
    setPageValue(value - 1);
  };

  let description =
    "We, The HIBRO-Home Services gladly comes up with abundant number of quality hygiene and friendly services to make your life uncomplicated.";

  return (
    <div className={classes.root}>
      <Grid container spacing={2}>
        <Grid md={6}>
          <Typography variant="h4" className={classes.productHeading}>
            PRODUCT LIST
          </Typography>
          {productList
            ? productList.map((el) => (
                <SingleProductCard
                  key={el.productId}
                  productName={el.productName}
                  // starRating="4.7"
                  productImage={el.productImage ? el.productImage : image}
                  productPrice={el.price}
                  productOriginalPrice={el.originalPrice}
                  // productDescription=dangerouslySetInnerHTML={{
                  //   __html: categoryInfo.description,
                  // }}
                  productDescription={
                    el.productDescription ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: el.productDescription,
                        }}
                      />
                    ) : (
                      description
                    )
                  }
                  onAdd={() => addToCart(el)}
                />
              ))
            : getProductList()}
          {productCount >= 1 && (
            <Box display="flex" justifyContent="center">
              <Pagination count={productCount} variant="outlined" color="primary" className={classes.paginationStyle} />
            </Box>
          )}
        </Grid>
        <Grid md={1}></Grid>
        <Grid md={5}>
          <Typography variant="h4" className={classes.productHeading}>
            CART ITEMS
          </Typography>
          <ProductCart
            // onClickEvent={cartItems.length ? props.onClickEvent : onClickEventFunction}
            // totalNumberOfProducts={cartItems.length}
            onClickEvent={cartItems2.length ? props.onClickEvent : onClickEventFunction}
            totalNumberOfProducts={cartItems2.length}
            cartProductList={cartProductList}
            productAlert={alert}
            totalPrice={cartTotal}
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default ShopProductList;
