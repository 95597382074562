import React from "react";
import { Container } from "@material-ui/core";

function PrivacyBodyPage() {
  return (
    <Container>
      <h1 className="oswald headingTerms">
        <span className="blue">PRIVACY</span> POLICY
      </h1>
      <h2 className="oswald headingTerms">
        <span className="blue">PRIVACY</span> INFORMATION:
      </h2>
      <p className="roboto paraTerms">
        Through your use of the website and services, You may provide us with certain information. By using the website
        or the services, You authorise the Owner to use your information in India and any other country where we may
        operate.
      </p>
      <h2 className="oswald headingTerms">
        <span className="blue">SECTION A:</span> INFORMATION WE MAY COLLECT OR RECEIVE
      </h2>
      <p className="roboto paraTerms">
        when You register for an account, You provide us with a valid email address and a Reuters with additional
        information, such as your name or billing information. Depending on how You use of the website or services we
        may also receive information from external applications You use to access our website or we may receive
        information through various web technologies, such as cookies, log files, clear GIFs, web beacons or others.
      </p>
      <h2 className="oswald headingTerms">
        <span className="blue">SECTION B:</span> HOW WE USE INFORMATION
      </h2>
      <p className="roboto paraTerms">
        we use the information gathered from You to ensure Your continued good experience on our website, including
        through email communication. We may also track certain of the passive information received to improve our
        marketing and analytics, and for this we may work with third-party providers.
      </p>
      <h2 className="oswald headingTerms">
        <span className="blue">SECTION C:</span> HOW YOU CAN PROTECT YOUR INFORMATION
      </h2>
      <p className="roboto paraTerms">
        if You would like to disable our access to any passive information we receive from the use of various
        technologies, You may choose to disable cookies in your web browser. Please be aware that the Owner will still
        receive information about You that You have provided such as your email address if You choose to terminate Your
        account the Owner will store information about You follow a number of days 3 after that time it will be deleted.
      </p>
    </Container>
  );
}

export default PrivacyBodyPage;
